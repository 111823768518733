import React from 'react';
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import TaxFlowActionButton from '@app/src/Components/TaxFlow/Common/TaxFlowActionButton';
import MailIcon from '@app/src/assets/mail.svg?react';
import { extractDrakePdf } from '@app/src/services/taxFlowService';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';

const TaxFlowPdfButton = ({ text, className, extractDrakePdf, year }) => {
  const selectedYear = useSelector(yearSelector);
  const onClick = async () => {
    await extractDrakePdf(year ?? selectedYear);
  };

  return (
    <TaxFlowActionButton className={className} onClick={onClick}>
      <MailIcon fontSize='small' />
      {_.defaultTo(text, 'Email me a copy of my return')}
    </TaxFlowActionButton>
  );
};

const mapDispatchToProps = {
  extractDrakePdf
};

const ConnectedTaxFlowPdfButton = connect(null, mapDispatchToProps)(TaxFlowPdfButton);

export default ConnectedTaxFlowPdfButton;
