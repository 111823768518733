import _ from 'lodash';
import {
  COLLECTION_TYPE__CREDIT_HEALTHCARE,
  ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_OPTIONS
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import {
  COLLECTION_TYPE__DEPENDENT,
  COLLECTION_TYPE__SELF,
  ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME,
  ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import {
  SLUG__CA_HEALTHCARE,
  SLUG__CA_HEALTHCARE_SELF_DURATION,
  SLUG__CA_HEALTHCARE_SPOUSE_DURATION,
  SLUG__CA_HEALTHCARE_DEPENDENT_DURATION,
  PATH_COMPONENT__CA_HEALTHCARE_SELF_DURATION,
  PATH_COMPONENT__CA_HEALTHCARE_SPOUSE_DURATION,
  PATH_COMPONENT__CA_HEALTHCARE_DEPENDENT_DURATION,
  PATH_COMPONENT__CA_DONE,
  SLUG__DC_HEALTHCARE,
  SLUG__DC_HEALTHCARE_SELF_DURATION,
  SLUG__DC_HEALTHCARE_SPOUSE_DURATION,
  SLUG__DC_HEALTHCARE_DEPENDENT_DURATION,
  PATH_COMPONENT__DC_HEALTHCARE_SELF_DURATION,
  PATH_COMPONENT__DC_HEALTHCARE_SPOUSE_DURATION,
  PATH_COMPONENT__DC_HEALTHCARE_DEPENDENT_DURATION,
  PATH_COMPONENT__DC_DONE,
  SLUG__NJ_HEALTHCARE,
  SLUG__NJ_HEALTHCARE_SELF_DURATION,
  SLUG__NJ_HEALTHCARE_SPOUSE_DURATION,
  SLUG__NJ_HEALTHCARE_DEPENDENT_DURATION,
  PATH_COMPONENT__NJ_HEALTHCARE_SELF_DURATION,
  PATH_COMPONENT__NJ_HEALTHCARE_SPOUSE_DURATION,
  PATH_COMPONENT__NJ_HEALTHCARE_DEPENDENT_DURATION,
  SLUG__RI_HEALTHCARE,
  SLUG__RI_HEALTHCARE_SELF_DURATION,
  SLUG__RI_HEALTHCARE_SPOUSE_DURATION,
  SLUG__RI_HEALTHCARE_DEPENDENT_DURATION,
  PATH_COMPONENT__RI_HEALTHCARE_SELF_DURATION,
  PATH_COMPONENT__RI_HEALTHCARE_SPOUSE_DURATION,
  PATH_COMPONENT__RI_HEALTHCARE_DEPENDENT_DURATION,
  PATH_COMPONENT__RI_DONE,
  PATH_COMPONENT__NJ_DONE
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import { DEFAULT_COLLECTION_ID } from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  getQueryResultByEndpointAttribute,
  getQueryResultsByEndpointAttribute
} from '@app/src/taxflow/shared/utils/sharedUtils';

export const getStateHealthcareNextQuery = ({ stateHealthcare, question, queryResults, collectionId }) => {
  if (question.slug === stateHealthcare.healthcareSelfDurationSlug) {
    const status = getQueryResultByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__SELF,
      collectionId: DEFAULT_COLLECTION_ID,
      slug: ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS
    });

    if (_.get(status, ['answer', 'value']) === 'married') {
      return {};
    } else {
      const dependents = getQueryResultsByEndpointAttribute({
        queryResults,
        collectionType: COLLECTION_TYPE__DEPENDENT,
        slug: ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME
      });

      if (!_.isEmpty(dependents)) {
        return { collectionId: dependents[0].coll_id };
      } else {
        return {};
      }
    }
  } else if (question.slug === stateHealthcare.healthcareSpouseDurationSlug) {
    const dependents = getQueryResultsByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__DEPENDENT,
      slug: ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME
    });

    if (!_.isEmpty(dependents)) {
      return { collectionId: dependents[0].coll_id };
    } else {
      return {};
    }
  } else if (question.slug === stateHealthcare.healthcareDependentDurationSlug) {
    const dependents = getQueryResultsByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__DEPENDENT,
      slug: ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME
    });

    const index = dependents.findIndex((d) => d.coll_id === collectionId);
    if (index !== -1 && index + 1 < dependents.length) {
      return { collectionId: dependents[index + 1].coll_id };
    } else {
      return {};
    }
  } else {
    return {};
  }
};

export const getStateHealthcareNextPathComponent = ({ stateHealthcare, question, queryResults, collectionId }) => {
  if (question.slug === stateHealthcare.healthcareSlug) {
    const healthcareOptions = getQueryResultByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__CREDIT_HEALTHCARE,
      collectionId: DEFAULT_COLLECTION_ID,
      slug: ENDPOINT_ATTRIBUTE__CREDIT_HEALTHCARE_OPTIONS
    });

    if (_.get(healthcareOptions, ['answer', 'value']) === '2') {
      return stateHealthcare.healthcareSelfDurationPathComponent;
    } else {
      return stateHealthcare.healthcareExitPathComponent;
    }
  } else if (question.slug === stateHealthcare.healthcareSelfDurationSlug) {
    const status = getQueryResultByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__SELF,
      collectionId: DEFAULT_COLLECTION_ID,
      slug: ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS
    });

    if (_.get(status, ['answer', 'value']) === 'married') {
      return stateHealthcare.healthcareSpouseDurationPathComponent;
    } else {
      const dependents = getQueryResultsByEndpointAttribute({
        queryResults,
        collectionType: COLLECTION_TYPE__DEPENDENT,
        slug: ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME
      });

      if (!_.isEmpty(dependents)) {
        return stateHealthcare.healthcareDependentDurationPathComponent;
      } else {
        return stateHealthcare.healthcareExitPathComponent;
      }
    }
  } else if (question.slug === stateHealthcare.healthcareSpouseDurationSlug) {
    const dependents = getQueryResultsByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__DEPENDENT,
      slug: ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME
    });

    if (!_.isEmpty(dependents)) {
      return stateHealthcare.healthcareDependentDurationPathComponent;
    } else {
      return stateHealthcare.healthcareExitPathComponent;
    }
  } else if (question.slug === stateHealthcare.healthcareDependentDurationSlug) {
    const dependents = getQueryResultsByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__DEPENDENT,
      slug: ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME
    });
    const index = dependents.findIndex((d) => d.coll_id === collectionId);
    if (index !== -1 && index + 1 < dependents.length) {
      return stateHealthcare.healthcareDependentDurationPathComponent;
    } else {
      return stateHealthcare.healthcareExitPathComponent;
    }
  } else {
    return null;
  }
};

export const isStateHealthcareSlug = ({ stateHealthcare, slug }) => {
  return (
    slug === stateHealthcare.healthcareSlug ||
    slug === stateHealthcare.healthcareSelfDurationSlug ||
    slug === stateHealthcare.healthcareSpouseDurationSlug ||
    slug === stateHealthcare.healthcareDependentDurationSlug
  );
};

export const CA_HEALTHCARE = {
  healthcareSlug: SLUG__CA_HEALTHCARE,
  healthcareSelfDurationSlug: SLUG__CA_HEALTHCARE_SELF_DURATION,
  healthcareSpouseDurationSlug: SLUG__CA_HEALTHCARE_SPOUSE_DURATION,
  healthcareDependentDurationSlug: SLUG__CA_HEALTHCARE_DEPENDENT_DURATION,
  healthcareSelfDurationPathComponent: PATH_COMPONENT__CA_HEALTHCARE_SELF_DURATION,
  healthcareSpouseDurationPathComponent: PATH_COMPONENT__CA_HEALTHCARE_SPOUSE_DURATION,
  healthcareDependentDurationPathComponent: PATH_COMPONENT__CA_HEALTHCARE_DEPENDENT_DURATION,
  healthcareExitPathComponent: PATH_COMPONENT__CA_DONE
};

export const DC_HEALTHCARE = {
  healthcareSlug: SLUG__DC_HEALTHCARE,
  healthcareSelfDurationSlug: SLUG__DC_HEALTHCARE_SELF_DURATION,
  healthcareSpouseDurationSlug: SLUG__DC_HEALTHCARE_SPOUSE_DURATION,
  healthcareDependentDurationSlug: SLUG__DC_HEALTHCARE_DEPENDENT_DURATION,
  healthcareSelfDurationPathComponent: PATH_COMPONENT__DC_HEALTHCARE_SELF_DURATION,
  healthcareSpouseDurationPathComponent: PATH_COMPONENT__DC_HEALTHCARE_SPOUSE_DURATION,
  healthcareDependentDurationPathComponent: PATH_COMPONENT__DC_HEALTHCARE_DEPENDENT_DURATION,
  healthcareExitPathComponent: PATH_COMPONENT__DC_DONE
};

export const NJ_HEALTHCARE = {
  healthcareSlug: SLUG__NJ_HEALTHCARE,
  healthcareSelfDurationSlug: SLUG__NJ_HEALTHCARE_SELF_DURATION,
  healthcareSpouseDurationSlug: SLUG__NJ_HEALTHCARE_SPOUSE_DURATION,
  healthcareDependentDurationSlug: SLUG__NJ_HEALTHCARE_DEPENDENT_DURATION,
  healthcareSelfDurationPathComponent: PATH_COMPONENT__NJ_HEALTHCARE_SELF_DURATION,
  healthcareSpouseDurationPathComponent: PATH_COMPONENT__NJ_HEALTHCARE_SPOUSE_DURATION,
  healthcareDependentDurationPathComponent: PATH_COMPONENT__NJ_HEALTHCARE_DEPENDENT_DURATION,
  healthcareExitPathComponent: PATH_COMPONENT__NJ_DONE
};

export const RI_HEALTHCARE = {
  healthcareSlug: SLUG__RI_HEALTHCARE,
  healthcareSelfDurationSlug: SLUG__RI_HEALTHCARE_SELF_DURATION,
  healthcareSpouseDurationSlug: SLUG__RI_HEALTHCARE_SPOUSE_DURATION,
  healthcareDependentDurationSlug: SLUG__RI_HEALTHCARE_DEPENDENT_DURATION,
  healthcareSelfDurationPathComponent: PATH_COMPONENT__RI_HEALTHCARE_SELF_DURATION,
  healthcareSpouseDurationPathComponent: PATH_COMPONENT__RI_HEALTHCARE_SPOUSE_DURATION,
  healthcareDependentDurationPathComponent: PATH_COMPONENT__RI_HEALTHCARE_DEPENDENT_DURATION,
  healthcareExitPathComponent: PATH_COMPONENT__RI_DONE
};
