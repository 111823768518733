import _ from 'lodash';
import { useSelector } from 'react-redux';
import { FIXED_CACHE_KEYS } from '@app/src/api/baseApi';
import {
  useGenerateQuestionnaireMutation,
  useGetBulkUploadPillsQuery,
  useGetQuestionnaireSummaryPillsQuery
} from '@app/src/api/taxDataApi';
import { dropzoneLoadingSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';

export const useQuestionnaireLoading = ({ taxYear } = {}) => {
  const selectedYear = useSelector(yearSelector);
  const year = _.defaultTo(taxYear, selectedYear);

  const dropzoneLoading = useSelector(dropzoneLoadingSelector);
  const { data: bulkUploadPills } = useGetBulkUploadPillsQuery({ year });
  const { isFetching: questionnaireSummaryPillsFetching } = useGetQuestionnaireSummaryPillsQuery({ year });
  const parseInProgress = _.some(bulkUploadPills, { loading: true });
  const [, { isLoading: questionnaireGenerationInProgress }] = useGenerateQuestionnaireMutation({
    fixedCacheKey: FIXED_CACHE_KEYS.GENERATE_QUESTIONNAIRE
  });
  const questionnaireLoading =
    parseInProgress || dropzoneLoading || questionnaireSummaryPillsFetching || questionnaireGenerationInProgress;

  return { questionnaireLoading };
};
