import { INCOME_COLLECTION_TYPES } from '@app/src/taxflow/sections/income/incomeConstants';

export const TAXFLOW__FORM_UPLOAD_PENDING_STATES = ['ocr_initiated', 'url_generated', 'in_cloud_storage'];
export const TAXFLOW__FORM_UPLOAD_FAILED_STATES = [
  'cloud_upload_failed',
  'rejected_by_ocr',
  'ocr_processing_failed',
  'stalled'
];
export const TAXFLOW__FORM_UPLOAD_DELETED_STATES = ['deleted', 'deleted_after_rejection', 'composite_prefilled'];
export const TAXFLOW__FORM_UPLOAD_VALID_STATES = ['ocr_initiated', 'prefilled'];
export const TAXFLOW__FORM_UPLOAD_FAILED_BODY =
  'Please delete this form and try again or enter the information manually.';
export const TAXFLOW__FORM_UPLOAD_OCR_FAILED_TEXT = 'The file that was uploaded may be password protected. ';
export const TAXFLOW__FORM_UPLOAD_IMAGE_QUALITY_TEXT = 'The file that was uploaded was poorly cropped or too blurry. ';
export const GET_TAXFLOW__FORM_UPLOAD_WRONG_YEAR_TEXT = (year) =>
  `The file that was uploaded was not for the year ${year}. `;
export const TAXFLOW__FORM_UPLOAD_DRAFT_TEXT = 'The file that was uploaded was a draft form. ';
export const TAXFLOW__FORM_UPLOAD_PASSWORD_TEXT = 'Please remove the password protection and try again.';
export const TAXFLOW__FORM_UPLOAD_WRONG_FILE_FORMAT_TEXT =
  "Keeper can't read heic, gif, html, zip, or csv file types. Please upload forms using file types pdf, jpg, png, or tiff.";
export const TAXFLOW__FORM_UPLOAD_COMPOSITE_SUMMARY_ITEM = {
  collectionType: INCOME_COLLECTION_TYPES.COMPOSITE,
  displayNameEndpointAttribute: 'income-invest-description',
  iconUrl:
    '//images.ctfassets.net/xauw5r0xpp02/0mCGPRqhAs1FPrHszVMbB/ac778f592a62de4e093d26cb249fe49c/presention-chart.svg',
  listName: 'Investment Income (1099-B)',
  name: 'Payer',
  namePreface: '1099-B',
  plural: true,
  slug: 'income-invest-started',
  startedEndpointAttribute: 'income-invest-started',
  type: INCOME_COLLECTION_TYPES.COMPOSITE
};

export const FORM_UPLOAD_VALID_FILE_FORMATS = ['pdf', 'png', 'jpg', 'jpeg', 'tif', 'tiff'];

export const FORM_UPLOAD_UI_EVENTS_DEBOUNCE_TIME_MS = 500;

export const FORM_UPLOAD_ERRORS = {
  INCORRECT_FORM: 'incorrect-form',
  INCORRECT_YEAR: 'incorrect-year',
  PREMIUM_REQUIRED: 'premium-required',
  OTHER: 'other'
};
