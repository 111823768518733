import React from 'react';
import CloseIcon from '@app/src/assets/close.svg?react';
import MessageQuestion from '@app/src/assets/message-question.svg?react';
import { SQUARE_PILL_ACTION_ICON_TYPES } from '@app/src/constants/constants';
import '@app/src/Components/Common/SquarePill/SquarePill.scss';

const SquarePill = ({ item, text, actionIconType = SQUARE_PILL_ACTION_ICON_TYPES.REMOVE, decorativeIcon, onClick }) => {
  const handleClick = (e) => {
    if (onClick) {
      e.stopPropagation();
      onClick(item);
    }
  };

  const renderActionIcon = () => {
    if (!actionIconType) return null;
    const IconComponent = actionIconType === SQUARE_PILL_ACTION_ICON_TYPES.REMOVE ? CloseIcon : MessageQuestion;
    return <IconComponent className='square-pill-icon' width={16} height={16} onClick={handleClick} />;
  };

  return (
    <button
      className='square-pill'
      onClick={handleClick}
      name={text}
      style={{ cursor: typeof onClick === 'function' ? 'pointer' : 'default' }}
    >
      {decorativeIcon && (
        <div className='square-pill-decorative-icon' style={{ width: 16, height: 16 }}>
          {decorativeIcon}
        </div>
      )}
      <div className='square-pill-text'>{text}</div>
      <div className='square-pill-action-icon'>{renderActionIcon()}</div>
    </button>
  );
};

export default SquarePill;
