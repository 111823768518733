import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import KeeperSlider from '@app/src/Components/Common/KeeperSlider/KeeperSlider';

const TaxProfileSlider = ({ label, id, value = 50, onChange, onBlur, style, disabled }) => (
  <FormControl fullWidth style={style}>
    <InputLabel
      htmlFor={id}
      id={`${id}Label`}
      sx={{
        maxWidth: '100%'
      }}
    >
      {label}
    </InputLabel>
    <KeeperSlider
      showInput
      id={id}
      value={value}
      onInputChange={onChange}
      onSliderChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    />
  </FormControl>
);

export default TaxProfileSlider;
