import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { useFormContext } from 'react-hook-form';
import CarExpenseSlider from '@app/src/Components/TaxProfile/FormInputs/CategoryUsage/CarExpenseSlider';
import HomeOfficeSlider from '@app/src/Components/TaxProfile/FormInputs/CategoryUsage/HomeOfficeSlider';
import PhoneSlider from '@app/src/Components/TaxProfile/FormInputs/CategoryUsage/PhoneSlider';
import DependentsInput from '@app/src/Components/TaxProfile/FormInputs/HouseholdDetails/DependentsInput';
import FilingStatusInput from '@app/src/Components/TaxProfile/FormInputs/HouseholdDetails/FilingStatusInput';
import StateInput from '@app/src/Components/TaxProfile/FormInputs/HouseholdDetails/StateInput';
import FreelanceIncomeInput from '@app/src/Components/TaxProfile/FormInputs/Income/FreelanceIncomeInput';
import SpouseFreelanceIncomeInput from '@app/src/Components/TaxProfile/FormInputs/Income/SpouseFreelanceIncomeInput';
import SpouseW2IncomeInput from '@app/src/Components/TaxProfile/FormInputs/Income/SpouseW2IncomeInput';
import W2IncomeInput from '@app/src/Components/TaxProfile/FormInputs/Income/W2IncomeInput';
import TaxProfileFormProvider from '@app/src/Components/TaxProfile/TaxProfileFormProvider';
import useTaxProfile from '@app/src/Components/TaxProfile/hooks/useTaxProfile';

const OnboardingTaxProfile = () => {
  const { formProps } = useTaxProfile({ year: 2023 });

  return (
    <TaxProfileFormProvider {...formProps}>
      <OnboardingTaxProfileBody />
    </TaxProfileFormProvider>
  );
};

const OnboardingTaxProfileBody = () => {
  const { watch } = useFormContext();

  const filingStatus = watch('filingStatus');

  const eligibleForDependents = useMemo(
    () => filingStatus === 'married' || filingStatus === 'head' || filingStatus === 'married_separate',
    [filingStatus]
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={eligibleForDependents ? 4 : 6}>
        <FilingStatusInput />
      </Grid>
      {eligibleForDependents && (
        <Grid item xs={12} md={2}>
          <DependentsInput
            label='Dependents'
            FormControlProps={{
              fullWidth: true
            }}
            InputLabelProps={{
              shrink: false
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <StateInput />
      </Grid>
      <Grid item xs={12} md={6}>
        <W2IncomeInput />
      </Grid>
      <Grid item xs={12} md={6}>
        <FreelanceIncomeInput />
      </Grid>
      {filingStatus === 'married' && (
        <>
          <Grid item xs={12} md={6}>
            <SpouseW2IncomeInput />
          </Grid>
          <Grid item xs={12} md={6}>
            <SpouseFreelanceIncomeInput />
          </Grid>
        </>
      )}
      <Grid item xs={12} md={6}>
        <CarExpenseSlider />
      </Grid>
      <Grid item xs={12} md={6}>
        <HomeOfficeSlider />
      </Grid>
      <Grid item xs={12} md={6}>
        <PhoneSlider />
      </Grid>
    </Grid>
  );
};

export default OnboardingTaxProfile;
