import React from 'react';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';
import { forcePlaidRefresh } from '@app/src/actions/bankActions';
import { setSettingsModalType } from '@app/src/actions/settingsActions';
import useExpenseUploadFiles from '@app/src/hooks/useExpenseUploadFiles';

const ExpenseUploadLinkPrompt = ({ forcePlaidRefresh, setSettingsModalType }) => {
  const { handleUploadClick } = useExpenseUploadFiles({ origin: 'expense-upload-link-prompt', setSettingsModalType });

  const onConfirm = async () => {
    forcePlaidRefresh();
    handleUploadClick();
  };

  const onCancel = () => {
    setSettingsModalType('');
  };

  return (
    <Stack direction='column' spacing={2}>
      <DialogTitle>Are you trying to connect an apple card?</DialogTitle>
      <DialogContentText>
        You can upload a spreadsheet or statement of your Apple Card expenses to add them to your account.
      </DialogContentText>
      <Stack direction={'row'} spacing={1}>
        <Button fullWidth color='secondary' onClick={onConfirm} variant='contained'>
          Upload expenses
        </Button>
      </Stack>
      <Stack direction={'row'} spacing={1}>
        <Button fullWidth color='secondary' onClick={onCancel} variant='text'>
          Dismiss
        </Button>
      </Stack>
    </Stack>
  );
};

const mapDispatchToProps = {
  setSettingsModalType,
  forcePlaidRefresh
};

const ConnectedExpenseUploadLinkPrompt = connect(null, mapDispatchToProps)(ExpenseUploadLinkPrompt);

export default ConnectedExpenseUploadLinkPrompt;
