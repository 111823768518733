import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';
import TaxProfileSelect from '@app/src/Components/TaxProfile/TaxProfileSelect';

const FilingStatusInput = () => {
  const { handleSubmit, setValue } = useFormContext();

  const { onSubmit, isLoading } = useTaxProfileFormContext();

  return (
    <Controller
      name='filingStatus'
      defaultValue=''
      rules={{
        required: 'Filing status is required'
      }}
      render={({ field, fieldState: { error } }) => (
        <TaxProfileSelect
          {...field}
          id='filingStatus'
          error={error}
          label='Filing status'
          placeholder='Select a status'
          disabled={isLoading}
          onChange={(event) => {
            field.onChange(event);

            if (event.target.value === 'single') {
              setValue('numberOfDependents', null);
            }

            handleSubmit(onSubmit(field.name))();
          }}
          options={[
            {
              value: 'single',
              label: 'Single'
            },
            {
              value: 'married',
              label: 'Married filing jointly'
            },
            {
              value: 'married_separate',
              label: 'Married filing separately'
            },
            {
              value: 'head',
              label: 'Head of household'
            }
          ]}
        />
      )}
    />
  );
};

export default FilingStatusInput;
