import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useGetFeaturesQuery } from '@app/src/api/profileApi';
import {
  useGetBulkUploadPillsQuery,
  useGetQuestionnaireProgressQuery,
  useGetReviewPillsQuery,
  useGetSubmitIssuesQuery,
  useGetSubmitWarningsQuery,
  useGetTaxDataQuery,
  useGetUIStageQuery
} from '@app/src/api/taxDataApi';
import CapitolBuildingBanner from '@app/src/assets/capitol-building-banner.svg?react';
import { useIsPremiumSubscriber } from '@app/src/hooks/pricingHooks';
import { useDeductionsCount } from '@app/src/hooks/useDeductionsCount';
import { useHasTransactions } from '@app/src/hooks/useHasDeductions';
import { useQuestionnaireLoading } from '@app/src/hooks/useQuestionnaireLoading';
import { TAX_YEAR_LIFECYCLE_STAGES, useTaxYearLifecycle } from '@app/src/hooks/useTaxYearLifecycle';
import { useTransactionsSyncInProgress } from '@app/src/hooks/useTransactionsSyncInProgress';
import { useUnlockedViaImpersonation } from '@app/src/hooks/useUnlockedViaImpersonation';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import {
  SECTION__KEEPER_REVIEW,
  SECTION__SHARE_DETAILS,
  SECTION__SIGN_AND_FILE,
  UI_STAGE__BLOCKED_PREFIX,
  UI_STAGE__CONFIRM_AMOUNTS,
  UI_STAGE__EMAIL_OUTBOX_PREFIX,
  UI_STAGE__NOT_STARTED,
  UI_STAGE_METADATA
} from '@app/src/taxflow/shared/constants/sharedConstants';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';

export const useUIState = ({ taxYear } = {}) => {
  const selectedYear = useSelector(yearSelector);
  const year = _.defaultTo(taxYear, selectedYear);

  const { data: taxData, isLoading: taxDataLoading } = useGetTaxDataQuery({
    year
  });
  const { data: bulkUploadPills, isLoading: bulkUploadPillsLoading } = useGetBulkUploadPillsQuery({ year });
  const { data: questionnaireProgress, isLoading: questionnaireProgressLoading } = useGetQuestionnaireProgressQuery({
    year
  });
  const { data: submitWarnings, isLoading: submitWarningsLoading } = useGetSubmitWarningsQuery({ year });
  const { data: submitIssues, isLoading: submitIssuesLoading } = useGetSubmitIssuesQuery({ year });
  const { data: reviewPills, isLoading: reviewPillsLoading } = useGetReviewPillsQuery({ year });
  const { data: uiStage, isLoading: uiStageLoading } = useGetUIStageQuery({ year });
  const { data: features, isLoading: featuresLoading } = useGetFeaturesQuery();
  const { syncInProgress: transactionSyncInProgress, isLoading: retroStatusLoading } = useTransactionsSyncInProgress({
    year
  });
  const { hasTransactions, isLoading: hasTransactionsLoading } = useHasTransactions({ year });
  const { deductionsCount, isLoading: deductionsCountLoading } = useDeductionsCount({ year });
  const { data: isPremiumSubscriber, isLoading: isIsPremiumSubscriberLoading } = useIsPremiumSubscriber();
  const { questionnaireLoading } = useQuestionnaireLoading({ taxYear: year });
  const { stage: taxLifecycleStage, isLoading: taxYearLifecycleLoading } = useTaxYearLifecycle({ taxYear: year });

  const isUserUnlockedViaImpersonation = useUnlockedViaImpersonation();

  const isLoading =
    taxDataLoading ||
    bulkUploadPillsLoading ||
    questionnaireProgressLoading ||
    reviewPillsLoading ||
    submitWarningsLoading ||
    submitIssuesLoading ||
    uiStageLoading ||
    featuresLoading ||
    retroStatusLoading ||
    hasTransactionsLoading ||
    deductionsCountLoading ||
    isIsPremiumSubscriberLoading ||
    taxYearLifecycleLoading;

  return {
    uiState: getUIState({
      taxData,
      bulkUploadPills,
      questionnaireComplete: questionnaireProgress?.questionnaireComplete,
      uiStage,
      submitWarnings,
      submitIssues,
      reviewPills,
      questionnaireLoading,
      features,
      transactionSyncInProgress,
      hasTransactions,
      deductionsCount,
      isPremiumSubscriber,
      isUserUnlockedViaImpersonation,
      taxLifecycleStage,
      year,
      isLoading
    }),
    isLoading
  };
};

/**
 * TODO - we could move out the bulk of this logic to contentful
 */
const getUIState = ({
  taxData,
  bulkUploadPills,
  questionnaireComplete,
  uiStage,
  submitWarnings,
  submitIssues,
  reviewPills,
  questionnaireLoading,
  features,
  transactionSyncInProgress,
  hasTransactions,
  deductionsCount,
  isPremiumSubscriber,
  isUserUnlockedViaImpersonation,
  taxLifecycleStage,
  year,
  isLoading
}) => {
  const userClaimsAllFormsUploaded = _.some(taxData, {
    coll_type: 'special',
    slug: 'all-forms-uploaded',
    value: '1'
  });
  const effectiveUIStage =
    _.isNil(uiStage) || _.isEmpty(uiStage) || isUserUnlockedViaImpersonation
      ? UI_STAGE__NOT_STARTED
      : _.last(_.split(uiStage, ' - '));
  const uiStageMetadata = _.find(UI_STAGE_METADATA, { stage: effectiveUIStage });

  if (_.isNil(uiStageMetadata)) {
    defaultCaptureMessage('[unexpected_edge_case] could not get metadata for ui stage', { uiStage: effectiveUIStage });
  }
  const emailOutbox = _.startsWith(uiStage, UI_STAGE__EMAIL_OUTBOX_PREFIX);
  const blocked = _.startsWith(uiStage, UI_STAGE__BLOCKED_PREFIX);
  const skipQuestionnaire = _.some(features, { name: 'skip-questionnaire', value: 1 });

  const isTaxFilingNotYetOpen = taxLifecycleStage === TAX_YEAR_LIFECYCLE_STAGES.NOT_OPEN;
  const isPartialFilingOpen = taxLifecycleStage === TAX_YEAR_LIFECYCLE_STAGES.PARTIAL;
  const isFullFilingOpen = taxLifecycleStage === TAX_YEAR_LIFECYCLE_STAGES.FULL;
  const isTaxFilingClosed = taxLifecycleStage === TAX_YEAR_LIFECYCLE_STAGES.CLOSED;

  const bannerText =
    !isFullFilingOpen && !isTaxFilingClosed && `Heads up! ${year} tax filing opens January 15th, ${Number(year) + 1}`;

  const HOME_SECTION___BULK_UPLOAD = {
    slug: 'bulk-upload',
    title: _.isEmpty(bulkUploadPills) ? 'Drop off tax forms' : `Your tax forms (${_.size(bulkUploadPills)})`,
    variant: userClaimsAllFormsUploaded ? 'collapsible' : 'default',
    collapsed: userClaimsAllFormsUploaded && !_.some(bulkUploadPills, { loading: true }),
    hidden: !uiStageMetadata?.preSubmit
  };
  const HOME_SECTION___DEDUCTIONS = {
    slug: 'deductions',
    title: transactionSyncInProgress
      ? 'Scanning for deductions...'
      : hasTransactions
        ? `Review your deductions (${deductionsCount})`
        : 'Scan for deductions',
    variant: transactionSyncInProgress ? 'loading' : 'collapsible',
    collapsed: questionnaireComplete || !userClaimsAllFormsUploaded,
    hidden: !uiStageMetadata?.preSubmit
  };
  const HOME_SECTION___QUESTIONNAIRE = {
    slug: 'questionnaire',
    title: 'Answer personalized questionnaire',
    variant:
      !userClaimsAllFormsUploaded || skipQuestionnaire || (!isPartialFilingOpen && !isFullFilingOpen)
        ? 'disabled'
        : questionnaireLoading
          ? 'loading'
          : 'default',
    disabledText:
      !isPartialFilingOpen && !isFullFilingOpen
        ? `Tax filing opens January 15th, ${Number(year) + 1}`
        : 'Finish tax forms to unlock',
    hidden: questionnaireComplete || !uiStageMetadata?.preSubmit || skipQuestionnaire
  };
  const HOME_SECTION___REVIEW = {
    slug: 'review',
    title: questionnaireComplete ? 'Your tax information' : 'Start Keeper review',
    variant:
      (skipQuestionnaire && userClaimsAllFormsUploaded) || questionnaireComplete || !uiStageMetadata?.preSubmit
        ? 'default'
        : 'disabled',
    disabledText: 'Complete questionnaire',
    hidden: uiStageMetadata?.showReviewPills === false || emailOutbox
  };

  const homeSections = [
    HOME_SECTION___BULK_UPLOAD,
    HOME_SECTION___DEDUCTIONS,
    HOME_SECTION___QUESTIONNAIRE,
    HOME_SECTION___REVIEW
  ];

  if (isLoading) {
    return {
      sections: [],
      showSubmitButton: false,
      submitButtonEnabled: false,
      showSubmitProgress: false,
      showTaxBill: false,
      showSubmitWarnings: false,
      homeSections
    };
  }

  if (isTaxFilingNotYetOpen) {
    return {
      slug: 'future-year',
      title: `${year} Tax filing isn’t open yet`,
      bannerText,
      bannerImage: <CapitolBuildingBanner style={{ maxWidth: '100%' }} />,
      sections: [
        { ...SECTION__SHARE_DETAILS, progress: 0 },
        { ...SECTION__KEEPER_REVIEW, progress: 0 },
        { ...SECTION__SIGN_AND_FILE, progress: 0 }
      ],
      showSubmitButton: false,
      showSubmitProgress: false,
      showTaxBill: false,
      showEmailPill: false,
      showSubmitWarnings: false,
      homeSections: _.map(homeSections, (section) => ({ ...section, hidden: true }))
    };
  }

  // Case: Tax filing not open for year
  if (isTaxFilingClosed && !uiStageMetadata?.supportedWhenTaxesClosed) {
    return {
      slug: 'prior-year',
      title: `You don’t have a ${year} tax return filed with Keeper`,
      maybeSubTitle: isPremiumSubscriber
        ? 'If you want to file a previous year return or amend an existing one, you can email your tax professional at [support@keepertax.com](mailto:support@keepertax.com)'
        : `Any returns you file with Keeper will be stored under that year if you need them!`,
      sections: [
        { ...SECTION__SHARE_DETAILS, progress: 0 },
        { ...SECTION__KEEPER_REVIEW, progress: 0 },
        { ...SECTION__SIGN_AND_FILE, progress: 0 }
      ],
      showSubmitButton: false,
      showSubmitProgress: false,
      showTaxBill: false,
      showEmailPill: false,
      showSubmitWarnings: false,
      homeSections: _.map(homeSections, (section) => ({ ...section, hidden: true })),
      showPriorYearTaxesPremiumUpsell: !isPremiumSubscriber
    };
  }

  if (emailOutbox) {
    return {
      slug: 'email-outbox',
      title: 'Please check your email',
      maybeSubTitle: `We need additional clarification about your tax return. To help us file your taxes, please be on the lookout for an email from support@keepertax.com!`,
      sections: uiStageMetadata.sections,
      showSubmitButton: false,
      showSubmitProgress: false,
      showTaxBill: false,
      showEmailPill: true,
      showSubmitWarnings: false,
      homeSections
    };
  }

  if (blocked) {
    return {
      slug: 'blocked',
      title: 'Your tax return is almost ready to be submitted',
      maybeSubTitle: `Some parts of your tax return are not available for filing yet. Once the IRS and your state are ready to accept your tax return, our professional tax reviewer will send you an email to let you know your return is ready to be filed.`,
      sections: uiStageMetadata.sections,
      showSubmitButton: false,
      showSubmitProgress: uiStageMetadata.showSubmitProgress,
      showTaxBill: uiStageMetadata.showTaxBill,
      showSubmitWarnings: true,
      homeSections
    };
  }

  if (uiStage === UI_STAGE__CONFIRM_AMOUNTS && !_.isEmpty(submitIssues)) {
    return {
      slug: 'confirm-amounts-submit-issue',
      title: 'We found some issues with your bank info',
      maybeSubTitle: uiStageMetadata.subTitle,
      sections: uiStageMetadata.sections,
      showSubmitButton: uiStageMetadata.showSubmitButton,
      submitButtonEnabled: uiStageMetadata.submitButtonEnabled,
      submitButtonText: 'Update bank info',
      showSubmitProgress: uiStageMetadata.showSubmitProgress,
      showTaxBill: uiStageMetadata.showTaxBill,
      showSubmitWarnings: true,
      homeSections
    };
  }

  const hasTaxDataError = _.some(reviewPills, ({ pills }) => _.some(pills, { warning: true }));
  const filingEnabled = _.isEmpty(submitWarnings) && _.isEmpty(submitIssues) && !hasTaxDataError && isFullFilingOpen;
  if (effectiveUIStage !== UI_STAGE__NOT_STARTED) {
    return {
      slug: effectiveUIStage,
      title: uiStageMetadata.title,
      maybeSubTitle: uiStageMetadata.subTitle,
      sections: uiStageMetadata.sections,
      showSubmitButton: uiStageMetadata.showSubmitButton,
      submitButtonEnabled: uiStageMetadata.submitButtonEnabled && (!uiStageMetadata?.preSubmit || filingEnabled),
      submitButtonText: uiStageMetadata.submitButtonText,
      showSubmitProgress: uiStageMetadata.showSubmitProgress,
      showTaxBill: uiStageMetadata.showTaxBill,
      showSubmitToIRSElement: uiStageMetadata.showSubmitToIRSElement,
      showFiledWithIRSElement: uiStageMetadata.showFiledWithIRSElement,
      showAcceptedByIRSElement: uiStageMetadata.showAcceptedByIRSElement,
      showSubmitWarnings: uiStageMetadata.showSubmitWarnings,
      homeSections
    };
  }

  const submitButtonText = isFullFilingOpen
    ? 'Continue to Keeper review'
    : `Tax filing opens January 15th, ${Number(year) + 1}`;

  if (questionnaireComplete || (userClaimsAllFormsUploaded && skipQuestionnaire)) {
    return {
      slug: 'review',
      bannerText,
      title: 'Anything else?',
      maybeSubTitle: 'You’ll have another chance to look over your return before e-filing with {irsAndState}',
      sections: [
        { ...SECTION__SHARE_DETAILS, progress: 100 },
        { ...SECTION__KEEPER_REVIEW, progress: 0 },
        { ...SECTION__SIGN_AND_FILE, progress: 0 }
      ],
      homeSections,
      showSubmitButton: true,
      submitButtonEnabled: filingEnabled,
      submitButtonText,
      showSubmitWarnings: true
    };
  }

  if (userClaimsAllFormsUploaded) {
    return {
      slug: 'questionnaire',
      bannerText,
      title: 'Answer personalized questions',
      sections: [
        { ...SECTION__SHARE_DETAILS, progress: 75 },
        { ...SECTION__KEEPER_REVIEW, progress: 0 },
        { ...SECTION__SIGN_AND_FILE, progress: 0 }
      ],
      homeSections,
      showSubmitButton: true,
      submitButtonEnabled: false,
      submitButtonText,
      showSubmitWarnings: false
    };
  }

  if (!_.isEmpty(bulkUploadPills)) {
    return {
      slug: 'bulk-started',
      bannerText,
      title: 'Add all your forms before you continue',
      sections: [
        { ...SECTION__SHARE_DETAILS, progress: 50 },
        { ...SECTION__KEEPER_REVIEW, progress: 0 },
        { ...SECTION__SIGN_AND_FILE, progress: 0 }
      ],
      homeSections,
      showSubmitButton: true,
      submitButtonEnabled: false,
      submitButtonText,
      showSubmitWarnings: false
    };
  }

  return {
    slug: 'bulk',
    bannerText,
    title: 'Let’s drop off your tax forms',
    sections: [
      { ...SECTION__SHARE_DETAILS, progress: 25 },
      { ...SECTION__KEEPER_REVIEW, progress: 0 },
      { ...SECTION__SIGN_AND_FILE, progress: 0 }
    ],
    homeSections,
    showSubmitButton: true,
    submitButtonEnabled: false,
    submitButtonText,
    showSubmitWarnings: false
  };
};
