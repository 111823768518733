import _ from 'lodash';
import { INCOME_COLLECTION_TYPES, INCOME_ENDPOINT_ATTRIBUTES } from '@app/src/taxflow/sections/income/incomeConstants';
import {
  COLLECTION_TYPE__SELF,
  ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS,
  COLLECTION_TYPE__DEPENDENT,
  ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME,
  ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_FILING_OPTION
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import {
  PATH_COMPONENT__STATE_DEFAULT,
  SLUG__STATE_DEFAULT,
  SLUG__STATE_NO_INCOME_TAX,
  PATH_COMPONENT__STATE_NO_INCOME_TAX,
  COLLECTION_TYPE__STATE_RETURN,
  PATH_COMPONENT__STATE_RETURN,
  SLUG__STATE_RETURN,
  PATH_COMPONENT__STATE_RESIDENCY,
  SLUG__STATE_RESIDENCY,
  PATH_COMPONENT__STATE_INCOME,
  SLUG__STATE_INCOME,
  PATH_COMPONENT__CA_USE_TAX_PURCHASES,
  PATH_COMPONENT__CA_USE_TAX_AMOUNT,
  SLUG__CA_USE_TAX_PURCHASES,
  SLUG__CA_USE_TAX_AMOUNT,
  PATH_COMPONENT__CA_DONE,
  SLUG__CA_DONE,
  PATH_COMPONENT__CA_RENT_CREDIT,
  PATH_COMPONENT__CA_RENT_CREDIT_DEPENDENT,
  PATH_COMPONENT__CA_RENT_CREDIT_PROPERTY,
  PATH_COMPONENT__CA_RENT_CREDIT_TAX,
  PATH_COMPONENT__CA_RENT_CREDIT_SUCCESS,
  SLUG__CA_RENT_CREDIT,
  SLUG__CA_RENT_CREDIT_DEPENDENT,
  SLUG__CA_RENT_CREDIT_PROPERTY,
  SLUG__CA_RENT_CREDIT_TAX,
  SLUG__CA_RENT_CREDIT_SUCCESS,
  PATH_COMPONENT__CA_HEALTHCARE,
  PATH_COMPONENT__CA_HEALTHCARE_SELF_DURATION,
  PATH_COMPONENT__CA_HEALTHCARE_SPOUSE_DURATION,
  PATH_COMPONENT__CA_HEALTHCARE_DEPENDENT_DURATION,
  SLUG__CA_HEALTHCARE,
  SLUG__CA_HEALTHCARE_SELF_DURATION,
  SLUG__CA_HEALTHCARE_SPOUSE_DURATION,
  SLUG__CA_HEALTHCARE_DEPENDENT_DURATION,
  PATH_COMPONENT__DC_RENT_CREDIT_PROPERTY_TYPE,
  PATH_COMPONENT__DC_RENT_CREDIT_AMOUNT,
  PATH_COMPONENT__DC_RENT_CREDIT_LANDLORD_INFO,
  PATH_COMPONENT__DC_RENT_CREDIT_PROPERTY_TAX,
  PATH_COMPONENT__DC_HEALTHCARE,
  PATH_COMPONENT__DC_HEALTHCARE_SELF_DURATION,
  PATH_COMPONENT__DC_HEALTHCARE_SPOUSE_DURATION,
  PATH_COMPONENT__DC_HEALTHCARE_DEPENDENT_DURATION,
  PATH_COMPONENT__DC_DONE,
  SLUG__DC_RENT_CREDIT_PROPERTY_TYPE,
  SLUG__DC_RENT_CREDIT_AMOUNT,
  SLUG__DC_RENT_CREDIT_LANDLORD_INFO,
  SLUG__DC_RENT_CREDIT_PROPERTY_TAX,
  SLUG__DC_HEALTHCARE,
  SLUG__DC_HEALTHCARE_SELF_DURATION,
  SLUG__DC_HEALTHCARE_SPOUSE_DURATION,
  SLUG__DC_HEALTHCARE_DEPENDENT_DURATION,
  SLUG__DC_DONE,
  COLLECTION_TYPE__DC_RENT_CREDIT,
  ENDPOINT_ATTRIBUTE__DC_RENT_CREDIT_PROPERTY_TYPE,
  PATH_COMPONENT__HI_HOUSING_DISTRIBUTION,
  PATH_COMPONENT__HI_HOUSING_INFO,
  PATH_COMPONENT__HI_RENT_CREDIT_QUALIFY,
  PATH_COMPONENT__HI_RENT_CREDIT_INFO,
  PATH_COMPONENT__HI_DONE,
  SLUG__HI_HOUSING_DISTRIBUTION,
  SLUG__HI_HOUSING_INFO,
  SLUG__HI_RENT_CREDIT_QUALIFY,
  SLUG__HI_RENT_CREDIT_INFO,
  SLUG__HI_DONE,
  PATH_COMPONENT__NC_USE_TAX_PURCHASES,
  PATH_COMPONENT__NC_USE_TAX_AMOUNT,
  PATH_COMPONENT__NC_DONE,
  SLUG__NC_USE_TAX_PURCHASES,
  SLUG__NC_USE_TAX_AMOUNT,
  SLUG__NC_DONE,
  PATH_COMPONENT__NJ_HEALTHCARE,
  PATH_COMPONENT__NJ_HEALTHCARE_SELF_DURATION,
  PATH_COMPONENT__NJ_HEALTHCARE_SPOUSE_DURATION,
  PATH_COMPONENT__NJ_HEALTHCARE_DEPENDENT_DURATION,
  PATH_COMPONENT__NJ_USE_TAX_PURCHASES,
  PATH_COMPONENT__NJ_DONE,
  SLUG__NJ_HEALTHCARE,
  SLUG__NJ_HEALTHCARE_SELF_DURATION,
  SLUG__NJ_HEALTHCARE_SPOUSE_DURATION,
  SLUG__NJ_HEALTHCARE_DEPENDENT_DURATION,
  SLUG__NJ_USE_TAX_PURCHASES,
  SLUG__NJ_DONE,
  PATH_COMPONENT__NY_SCHOOL_DISTRICT,
  PATH_COMPONENT__NY_COUNTY,
  PATH_COMPONENT__NY_USE_TAX_PURCHASES,
  PATH_COMPONENT__NY_USE_TAX_AMOUNT,
  PATH_COMPONENT__NY_DONE,
  SLUG__NY_SCHOOL_DISTRICT,
  SLUG__NY_COUNTY,
  SLUG__NY_USE_TAX_PURCHASES,
  SLUG__NY_USE_TAX_AMOUNT,
  SLUG__NY_DONE,
  PATH_COMPONENT__AL_USE_TAX_PURCHASES,
  PATH_COMPONENT__AL_USE_TAX_AMOUNT,
  PATH_COMPONENT__AL_DONE,
  PATH_COMPONENT__CO_USE_TAX_PURCHASES,
  PATH_COMPONENT__CO_USE_TAX_AMOUNT,
  PATH_COMPONENT__CO_DONE,
  PATH_COMPONENT__CT_USE_TAX_PURCHASES,
  PATH_COMPONENT__CT_USE_TAX_AMOUNT,
  PATH_COMPONENT__CT_DONE,
  PATH_COMPONENT__ID_TUITION,
  PATH_COMPONENT__ID_TUITION_AMOUNT,
  PATH_COMPONENT__ID_DONE,
  PATH_COMPONENT__IL_USE_TAX_PURCHASES,
  PATH_COMPONENT__IL_USE_TAX_AMOUNT,
  PATH_COMPONENT__IL_TUITION,
  PATH_COMPONENT__IL_TUITION_INFO,
  PATH_COMPONENT__IL_DONE,
  PATH_COMPONENT__IN_COUNTY_LIVED,
  PATH_COMPONENT__IN_SCHOOL_DISTRICT,
  PATH_COMPONENT__IN_COUNTY_WORKED,
  PATH_COMPONENT__IN_COUNTY_SPOUSE_WORKED,
  PATH_COMPONENT__IN_DONE,
  SLUG__AL_USE_TAX_PURCHASES,
  SLUG__AL_USE_TAX_AMOUNT,
  SLUG__AL_DONE,
  SLUG__CO_USE_TAX_PURCHASES,
  SLUG__CO_USE_TAX_AMOUNT,
  SLUG__CO_DONE,
  SLUG__CT_USE_TAX_PURCHASES,
  SLUG__CT_USE_TAX_AMOUNT,
  SLUG__CT_DONE,
  SLUG__ID_TUITION,
  SLUG__ID_TUITION_AMOUNT,
  SLUG__ID_DONE,
  SLUG__IL_USE_TAX_PURCHASES,
  SLUG__IL_USE_TAX_AMOUNT,
  SLUG__IL_TUITION,
  SLUG__IL_TUITION_INFO,
  SLUG__IL_DONE,
  SLUG__IN_COUNTY_LIVED,
  SLUG__IN_SCHOOL_DISTRICT,
  SLUG__IN_COUNTY_WORKED,
  SLUG__IN_COUNTY_SPOUSE_WORKED,
  SLUG__IN_DONE,
  PATH_COMPONENT__IA_SCHOOL_DISTRICT,
  PATH_COMPONENT__IA_DONE,
  PATH_COMPONENT__KY_USE_TAX_PURCHASES,
  PATH_COMPONENT__KY_USE_TAX_PRICE,
  PATH_COMPONENT__KY_REVIEW,
  PATH_COMPONENT__KY_DONE,
  PATH_COMPONENT__MI_SCHOOL_DISTRICT,
  PATH_COMPONENT__MI_USE_TAX_PURCHASES,
  PATH_COMPONENT__MI_USE_TAX_AMOUNT,
  PATH_COMPONENT__MI_DONE,
  PATH_COMPONENT__MN_TUITION,
  PATH_COMPONENT__MN_TUITION_INSTITUTION,
  PATH_COMPONENT__MN_TUITION_AMOUNT,
  PATH_COMPONENT__MN_DONE,
  PATH_COMPONENT__NE_SCHOOL_DISTRICT,
  PATH_COMPONENT__NE_DONE,
  SLUG__IA_SCHOOL_DISTRICT,
  SLUG__IA_DONE,
  SLUG__KY_USE_TAX_PURCHASES,
  SLUG__KY_USE_TAX_PRICE,
  SLUG__KY_REVIEW,
  SLUG__KY_DONE,
  SLUG__MI_SCHOOL_DISTRICT,
  SLUG__MI_USE_TAX_PURCHASES,
  SLUG__MI_USE_TAX_AMOUNT,
  SLUG__MI_DONE,
  SLUG__MN_TUITION,
  SLUG__MN_TUITION_INSTITUTION,
  SLUG__MN_TUITION_AMOUNT,
  SLUG__MN_DONE,
  SLUG__NE_SCHOOL_DISTRICT,
  SLUG__NE_DONE,
  PATH_COMPONENT__RI_HEALTHCARE,
  PATH_COMPONENT__RI_HEALTHCARE_SELF_DURATION,
  PATH_COMPONENT__RI_HEALTHCARE_SPOUSE_DURATION,
  PATH_COMPONENT__RI_HEALTHCARE_DEPENDENT_DURATION,
  PATH_COMPONENT__RI_DONE,
  SLUG__RI_HEALTHCARE,
  SLUG__RI_HEALTHCARE_SELF_DURATION,
  SLUG__RI_HEALTHCARE_SPOUSE_DURATION,
  SLUG__RI_HEALTHCARE_DEPENDENT_DURATION,
  SLUG__RI_DONE,
  PATH_COMPONENT__ND_SCHOOL_DISTRICT,
  PATH_COMPONENT__ND_INCOME_SOURCE,
  PATH_COMPONENT__ND_DONE,
  PATH_COMPONENT__OH_SCHOOL_DISTRICT,
  PATH_COMPONENT__OH_USE_TAX_PURCHASES,
  PATH_COMPONENT__OH_USE_TAX_AMOUNT,
  PATH_COMPONENT__OH_DONE,
  PATH_COMPONENT__PA_SCHOOL_DISTRICT,
  PATH_COMPONENT__PA_DONE,
  PATH_COMPONENT__SC_USE_TAX_PURCHASES,
  PATH_COMPONENT__SC_USE_TAX_AMOUNT,
  PATH_COMPONENT__SC_DONE,
  PATH_COMPONENT__UT_USE_TAX_PURCHASES,
  PATH_COMPONENT__UT_USE_TAX_AMOUNT,
  PATH_COMPONENT__UT_DONE,
  PATH_COMPONENT__VT_SCHOOL_DISTRICT,
  PATH_COMPONENT__VT_USE_TAX_PURCHASES,
  PATH_COMPONENT__VT_DONE,
  SLUG__ND_SCHOOL_DISTRICT,
  SLUG__ND_INCOME_SOURCE,
  SLUG__ND_DONE,
  SLUG__OH_SCHOOL_DISTRICT,
  SLUG__OH_USE_TAX_PURCHASES,
  SLUG__OH_USE_TAX_AMOUNT,
  SLUG__OH_DONE,
  SLUG__PA_SCHOOL_DISTRICT,
  SLUG__PA_DONE,
  SLUG__SC_USE_TAX_PURCHASES,
  SLUG__SC_USE_TAX_AMOUNT,
  SLUG__SC_DONE,
  SLUG__UT_USE_TAX_PURCHASES,
  SLUG__UT_USE_TAX_AMOUNT,
  SLUG__UT_DONE,
  SLUG__VT_SCHOOL_DISTRICT,
  SLUG__VT_USE_TAX_PURCHASES,
  SLUG__VT_DONE,
  PATH_COMPONENT__VA_USE_TAX_PURCHASES,
  PATH_COMPONENT__VA_USE_TAX_AMOUNT,
  PATH_COMPONENT__VA_DONE,
  PATH_COMPONENT__WV_DONE,
  PATH_COMPONENT__WI_SCHOOL_DISTRICT,
  PATH_COMPONENT__WI_USE_TAX_PURCHASES,
  PATH_COMPONENT__WI_USE_TAX_AMOUNT,
  PATH_COMPONENT__WI_DONE,
  SLUG__VA_USE_TAX_PURCHASES,
  SLUG__VA_USE_TAX_AMOUNT,
  SLUG__VA_DONE,
  SLUG__WV_DONE,
  SLUG__WI_SCHOOL_DISTRICT,
  SLUG__WI_USE_TAX_PURCHASES,
  SLUG__WI_USE_TAX_AMOUNT,
  SLUG__WI_DONE,
  PATH_COMPONENT__ME_USE_TAX_PURCHASES,
  PATH_COMPONENT__ME_USE_TAX_AMOUNT,
  PATH_COMPONENT__ME_DONE,
  SLUG__ME_USE_TAX_PURCHASES,
  SLUG__ME_USE_TAX_AMOUNT,
  SLUG__ME_DONE,
  PATH_COMPONENT__MA_HEALTH_TAXPAYER_COVERAGE,
  PATH_COMPONENT__MA_HEALTH_TAXPAYER_GOVT_INSURANCE,
  PATH_COMPONENT__MA_HEALTH_TAXPAYER_PRIVATE_INSURANCE,
  PATH_COMPONENT__MA_HEALTH_TAXPAYER_MONTHS,
  PATH_COMPONENT__MA_HEALTH_SPOUSE_COVERAGE,
  PATH_COMPONENT__MA_HEALTH_SPOUSE_GOVT_INSURANCE,
  PATH_COMPONENT__MA_HEALTH_SPOUSE_PRIVATE_INSURANCE,
  PATH_COMPONENT__MA_HEALTH_SPOUSE_MONTHS,
  PATH_COMPONENT__MA_DONE,
  SLUG__MA_HEALTH_TAXPAYER_COVERAGE,
  SLUG__MA_HEALTH_TAXPAYER_GOVT_INSURANCE,
  SLUG__MA_HEALTH_TAXPAYER_PRIVATE_INSURANCE,
  SLUG__MA_HEALTH_TAXPAYER_MONTHS,
  SLUG__MA_HEALTH_SPOUSE_COVERAGE,
  SLUG__MA_HEALTH_SPOUSE_GOVT_INSURANCE,
  SLUG__MA_HEALTH_SPOUSE_PRIVATE_INSURANCE,
  SLUG__MA_HEALTH_SPOUSE_MONTHS,
  SLUG__MA_DONE,
  COLLECTION_TYPE__MA_HEALTH,
  ENDPOINT_ATTRIBUTE__MA_HEALTH_TAXPAYER_COVERAGE,
  ENDPOINT_ATTRIBUTE__MA_HEALTH_SPOUSE_COVERAGE,
  PATH_COMPONENT__MN_TUITION_ACCOUNT,
  SLUG__MN_TUITION_ACCOUNT,
  PATH_COMPONENT__DC_RENT_CREDIT_OPTIONS,
  SLUG__DC_RENT_CREDIT_OPTIONS,
  PATH_COMPONENT__WV_USE_TAX_AMOUNT,
  SLUG__WV_USE_TAX_PURCHASES,
  PATH_COMPONENT__WV_USE_TAX_PURCHASES,
  SLUG__WV_USE_TAX_AMOUNT,
  SLUG__PA_USE_TAX_PURCHASES,
  PATH_COMPONENT__PA_USE_TAX_PURCHASES,
  SLUG__OK_USE_TAX_PURCHASES,
  SLUG__OK_USE_TAX_AMOUNT,
  SLUG__OK_DONE,
  PATH_COMPONENT__OK_USE_TAX_PURCHASES,
  PATH_COMPONENT__OK_USE_TAX_AMOUNT,
  PATH_COMPONENT__OK_DONE,
  PATH_COMPONENT__VT_USE_TAX_AMOUNT,
  SLUG__VT_USE_TAX_AMOUNT,
  SLUG__STATE_EXPENSES,
  PATH_COMPONENT__STATE_EXPENSES,
  PATH_COMPONENT__NJ_COUNTY,
  SLUG__NJ_COUNTY,
  SLUG__PA_W2_OCCUPATION,
  PATH_COMPONENT__PA_W2_OCCUPATION,
  SLUG__RI_USE_TAX_PURCHASES,
  SLUG__RI_USE_TAX_AMOUNT,
  PATH_COMPONENT__RI_USE_TAX_AMOUNT,
  PATH_COMPONENT__RI_USE_TAX_PURCHASES,
  ENDPOINT_ATTRIBUTE__STATE_RETURN,
  SLUG__NY_LIVING_DURATION,
  PATH_COMPONENT__NY_LIVING_DURATION,
  ENDPOINT_ATTRIBUTE__STATE_RETURN_DONE,
  PATH_COMPONENT__OR_SURPLUS_INTRO,
  SLUG__OR_SURPLUS_INTRO,
  PATH_COMPONENT__OR_DONE,
  SLUG__OR_DONE,
  PATH_COMPONENT__OR_SURPLUS_LIABILITY,
  SLUG__OR_SURPLUS_LIABILITY,
  PATH_COMPONENT__OR_SURPLUS_PRIOR_STATUS,
  SLUG__OR_SURPLUS_PRIOR_STATUS,
  PATH_COMPONENT__OR_SURPLUS_LIABILITY_AMOUNT_SINGLE,
  SLUG__OR_SURPLUS_LIABILITY_AMOUNT_SINGLE,
  PATH_COMPONENT__OR_SURPLUS_LIABILITY_AMOUNT_NEWLY_MARRIED,
  SLUG__OR_SURPLUS_LIABILITY_AMOUNT_NEWLY_MARRIED,
  PATH_COMPONENT__OR_SURPLUS_AGI,
  SLUG__OR_SURPLUS_AGI,
  PATH_COMPONENT__OR_SURPLUS_LIABILITY_AMOUNT_JOINT,
  SLUG__OR_SURPLUS_LIABILITY_AMOUNT_JOINT,
  PATH_COMPONENT__OR_SURPLUS_LIABILITY_AMOUNT_SEPARATED,
  SLUG__OR_SURPLUS_LIABILITY_AMOUNT_SEPARATED
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import {
  CA_HEALTHCARE,
  DC_HEALTHCARE,
  NJ_HEALTHCARE,
  RI_HEALTHCARE,
  getStateHealthcareNextPathComponent,
  getStateHealthcareNextQuery,
  isStateHealthcareSlug
} from '@app/src/taxflow/sections/state/utils/stateHealthcareUtils';
import { DEFAULT_COLLECTION_ID, PATH_COMPONENT__DASHBOARD } from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  getQueryResultByEndpointAttribute,
  getQueryResultsByEndpointAttribute
} from '@app/src/taxflow/shared/utils/sharedUtils';

export const getNextQuery = ({ question, queryResults, collectionId }) => {
  if (isStateHealthcareSlug({ stateHealthcare: CA_HEALTHCARE, slug: question.slug })) {
    return getStateHealthcareNextQuery({
      stateHealthcare: CA_HEALTHCARE,
      question,
      queryResults,
      collectionId
    });
  } else if (isStateHealthcareSlug({ stateHealthcare: DC_HEALTHCARE, slug: question.slug })) {
    return getStateHealthcareNextQuery({
      stateHealthcare: DC_HEALTHCARE,
      question,
      queryResults,
      collectionId
    });
  } else if (isStateHealthcareSlug({ stateHealthcare: NJ_HEALTHCARE, slug: question.slug })) {
    return getStateHealthcareNextQuery({
      stateHealthcare: NJ_HEALTHCARE,
      question,
      queryResults,
      collectionId
    });
  } else if (isStateHealthcareSlug({ stateHealthcare: RI_HEALTHCARE, slug: question.slug })) {
    return getStateHealthcareNextQuery({
      stateHealthcare: RI_HEALTHCARE,
      question,
      queryResults,
      collectionId
    });
  } else {
    return {};
  }
};

export const getNextPathComponentMap = () => {
  return {
    [SLUG__CA_USE_TAX_AMOUNT]: { nextPathComponent: PATH_COMPONENT__CA_RENT_CREDIT, questionnaireEarlyExit: false },
    [SLUG__CA_RENT_CREDIT_SUCCESS]: { nextPathComponent: PATH_COMPONENT__CA_HEALTHCARE, questionnaireEarlyExit: false },

    [SLUG__DC_RENT_CREDIT_AMOUNT]: {
      nextPathComponent: PATH_COMPONENT__DC_RENT_CREDIT_LANDLORD_INFO,
      questionnaireEarlyExit: false
    },
    [SLUG__DC_RENT_CREDIT_PROPERTY_TAX]: {
      nextPathComponent: PATH_COMPONENT__DC_HEALTHCARE,
      questionnaireEarlyExit: false
    },

    [SLUG__HI_HOUSING_INFO]: {
      nextPathComponent: PATH_COMPONENT__HI_RENT_CREDIT_QUALIFY,
      questionnaireEarlyExit: false
    },
    [SLUG__HI_RENT_CREDIT_INFO]: { nextPathComponent: PATH_COMPONENT__HI_DONE, questionnaireEarlyExit: true },

    [SLUG__NC_USE_TAX_AMOUNT]: { nextPathComponent: PATH_COMPONENT__NC_DONE, questionnaireEarlyExit: true },

    [SLUG__NJ_COUNTY]: { nextPathComponent: PATH_COMPONENT__NJ_HEALTHCARE, questionnaireEarlyExit: false },

    [SLUG__NY_SCHOOL_DISTRICT]: { nextPathComponent: PATH_COMPONENT__NY_COUNTY, questionnaireEarlyExit: false },
    [SLUG__NY_LIVING_DURATION]: {
      nextPathComponent: PATH_COMPONENT__NY_USE_TAX_PURCHASES,
      questionnaireEarlyExit: false
    },
    [SLUG__NY_USE_TAX_AMOUNT]: { nextPathComponent: PATH_COMPONENT__NY_DONE, questionnaireEarlyExit: true },

    [SLUG__AL_USE_TAX_AMOUNT]: { nextPathComponent: PATH_COMPONENT__AL_DONE, questionnaireEarlyExit: true },

    [SLUG__CO_USE_TAX_AMOUNT]: { nextPathComponent: PATH_COMPONENT__CO_DONE, questionnaireEarlyExit: true },

    [SLUG__CT_USE_TAX_AMOUNT]: { nextPathComponent: PATH_COMPONENT__CT_DONE, questionnaireEarlyExit: true },

    [SLUG__ID_TUITION_AMOUNT]: { nextPathComponent: PATH_COMPONENT__ID_DONE, questionnaireEarlyExit: true },

    [SLUG__IL_TUITION_INFO]: { nextPathComponent: PATH_COMPONENT__IL_DONE, questionnaireEarlyExit: true },

    [SLUG__IN_COUNTY_LIVED]: { nextPathComponent: PATH_COMPONENT__IN_COUNTY_WORKED, questionnaireEarlyExit: false },
    [SLUG__IN_COUNTY_SPOUSE_WORKED]: {
      nextPathComponent: PATH_COMPONENT__IN_SCHOOL_DISTRICT,
      questionnaireEarlyExit: false
    },
    [SLUG__IN_SCHOOL_DISTRICT]: { nextPathComponent: PATH_COMPONENT__IN_DONE, questionnaireEarlyExit: true },

    [SLUG__IA_SCHOOL_DISTRICT]: { nextPathComponent: PATH_COMPONENT__IA_DONE, questionnaireEarlyExit: true },

    [SLUG__KY_REVIEW]: { nextPathComponent: PATH_COMPONENT__DASHBOARD, questionnaireEarlyExit: true },

    [SLUG__MI_SCHOOL_DISTRICT]: {
      nextPathComponent: PATH_COMPONENT__MI_USE_TAX_PURCHASES,
      questionnaireEarlyExit: false
    },
    [SLUG__MI_USE_TAX_AMOUNT]: { nextPathComponent: PATH_COMPONENT__MI_DONE, questionnaireEarlyExit: true },

    [SLUG__MN_TUITION_INSTITUTION]: {
      nextPathComponent: PATH_COMPONENT__MN_TUITION_ACCOUNT,
      questionnaireEarlyExit: false
    },
    [SLUG__MN_TUITION_ACCOUNT]: { nextPathComponent: PATH_COMPONENT__MN_TUITION_AMOUNT, questionnaireEarlyExit: false },
    [SLUG__MN_TUITION_AMOUNT]: { nextPathComponent: PATH_COMPONENT__MN_DONE, questionnaireEarlyExit: true },

    [SLUG__NE_SCHOOL_DISTRICT]: { nextPathComponent: PATH_COMPONENT__NE_DONE, questionnaireEarlyExit: true },

    [SLUG__ND_SCHOOL_DISTRICT]: { nextPathComponent: PATH_COMPONENT__ND_INCOME_SOURCE, questionnaireEarlyExit: false },
    [SLUG__ND_INCOME_SOURCE]: { nextPathComponent: PATH_COMPONENT__ND_DONE, questionnaireEarlyExit: true },

    [SLUG__OH_SCHOOL_DISTRICT]: {
      nextPathComponent: PATH_COMPONENT__OH_USE_TAX_PURCHASES,
      questionnaireEarlyExit: false
    },
    [SLUG__OH_USE_TAX_AMOUNT]: { nextPathComponent: PATH_COMPONENT__OH_DONE, questionnaireEarlyExit: true },

    [SLUG__OK_USE_TAX_AMOUNT]: { nextPathComponent: PATH_COMPONENT__OK_DONE, questionnaireEarlyExit: true },

    [SLUG__OR_SURPLUS_LIABILITY_AMOUNT_NEWLY_MARRIED]: {
      nextPathComponent: PATH_COMPONENT__OR_DONE,
      questionnaireEarlyExit: true
    },
    [SLUG__OR_SURPLUS_LIABILITY_AMOUNT_JOINT]: {
      nextPathComponent: PATH_COMPONENT__OR_DONE,
      questionnaireEarlyExit: true
    },
    [SLUG__OR_SURPLUS_LIABILITY_AMOUNT_SEPARATED]: {
      nextPathComponent: PATH_COMPONENT__OR_SURPLUS_AGI,
      questionnaireEarlyExit: false
    },
    [SLUG__OR_SURPLUS_LIABILITY_AMOUNT_SINGLE]: {
      nextPathComponent: PATH_COMPONENT__OR_DONE,
      questionnaireEarlyExit: true
    },
    [SLUG__OR_SURPLUS_AGI]: {
      nextPathComponent: PATH_COMPONENT__OR_DONE,
      questionnaireEarlyExit: true
    },

    [SLUG__PA_SCHOOL_DISTRICT]: {
      nextPathComponent: PATH_COMPONENT__PA_USE_TAX_PURCHASES,
      questionnaireEarlyExit: false
    },
    [SLUG__PA_W2_OCCUPATION]: { nextPathComponent: PATH_COMPONENT__PA_DONE, questionnaireEarlyExit: true },

    [SLUG__RI_USE_TAX_AMOUNT]: { nextPathComponent: PATH_COMPONENT__RI_HEALTHCARE, questionnaireEarlyExit: false },

    [SLUG__SC_USE_TAX_AMOUNT]: { nextPathComponent: PATH_COMPONENT__SC_DONE, questionnaireEarlyExit: true },

    [SLUG__UT_USE_TAX_AMOUNT]: { nextPathComponent: PATH_COMPONENT__UT_DONE, questionnaireEarlyExit: true },

    [SLUG__VT_SCHOOL_DISTRICT]: {
      nextPathComponent: PATH_COMPONENT__VT_USE_TAX_PURCHASES,
      questionnaireEarlyExit: false
    },
    [SLUG__VT_USE_TAX_AMOUNT]: { nextPathComponent: PATH_COMPONENT__VT_DONE, questionnaireEarlyExit: true },

    [SLUG__VA_USE_TAX_AMOUNT]: { nextPathComponent: PATH_COMPONENT__VA_DONE, questionnaireEarlyExit: true },

    [SLUG__WV_USE_TAX_AMOUNT]: { nextPathComponent: PATH_COMPONENT__WV_DONE, questionnaireEarlyExit: true },

    [SLUG__WI_SCHOOL_DISTRICT]: {
      nextPathComponent: PATH_COMPONENT__WI_USE_TAX_PURCHASES,
      questionnaireEarlyExit: false
    },
    [SLUG__WI_USE_TAX_AMOUNT]: { nextPathComponent: PATH_COMPONENT__WI_DONE, questionnaireEarlyExit: true },

    [SLUG__ME_USE_TAX_AMOUNT]: { nextPathComponent: PATH_COMPONENT__ME_DONE, questionnaireEarlyExit: true },

    [SLUG__MA_HEALTH_SPOUSE_MONTHS]: { nextPathComponent: PATH_COMPONENT__MA_DONE, questionnaireEarlyExit: true }
  };
};

export const getOptionPathComponentMap = () => {
  return {
    [SLUG__CA_USE_TAX_PURCHASES]: {
      0: { nextPathComponent: PATH_COMPONENT__CA_RENT_CREDIT, questionnaireEarlyExit: false },
      1: { nextPathComponent: PATH_COMPONENT__CA_USE_TAX_AMOUNT, questionnaireEarlyExit: false },
      2: { nextPathComponent: PATH_COMPONENT__CA_DONE, questionnaireEarlyExit: true }
    },
    [SLUG__CA_RENT_CREDIT]: {
      0: { nextPathComponent: PATH_COMPONENT__CA_HEALTHCARE, questionnaireEarlyExit: false },
      1: { nextPathComponent: PATH_COMPONENT__CA_RENT_CREDIT_DEPENDENT, questionnaireEarlyExit: false }
    },
    [SLUG__CA_RENT_CREDIT_DEPENDENT]: {
      0: { nextPathComponent: PATH_COMPONENT__CA_RENT_CREDIT_PROPERTY, questionnaireEarlyExit: false },
      1: { nextPathComponent: PATH_COMPONENT__CA_HEALTHCARE, questionnaireEarlyExit: false }
    },
    [SLUG__CA_RENT_CREDIT_PROPERTY]: {
      0: { nextPathComponent: PATH_COMPONENT__CA_RENT_CREDIT_TAX, questionnaireEarlyExit: false },
      1: { nextPathComponent: PATH_COMPONENT__CA_HEALTHCARE, questionnaireEarlyExit: false }
    },
    [SLUG__CA_RENT_CREDIT_TAX]: {
      0: { nextPathComponent: PATH_COMPONENT__CA_RENT_CREDIT_SUCCESS, questionnaireEarlyExit: false },
      1: { nextPathComponent: PATH_COMPONENT__CA_HEALTHCARE, questionnaireEarlyExit: false }
    },

    [SLUG__HI_HOUSING_DISTRIBUTION]: {
      0: { nextPathComponent: PATH_COMPONENT__HI_RENT_CREDIT_QUALIFY, questionnaireEarlyExit: false },
      1: { nextPathComponent: PATH_COMPONENT__HI_HOUSING_INFO, questionnaireEarlyExit: false }
    },
    [SLUG__HI_RENT_CREDIT_QUALIFY]: {
      0: { nextPathComponent: PATH_COMPONENT__HI_DONE, questionnaireEarlyExit: true },
      1: { nextPathComponent: PATH_COMPONENT__HI_RENT_CREDIT_INFO, questionnaireEarlyExit: false }
    },

    [SLUG__NC_USE_TAX_PURCHASES]: {
      0: { nextPathComponent: PATH_COMPONENT__NC_DONE, questionnaireEarlyExit: true },
      1: { nextPathComponent: PATH_COMPONENT__NC_USE_TAX_AMOUNT, questionnaireEarlyExit: false }
    },

    [SLUG__NY_USE_TAX_PURCHASES]: {
      0: { nextPathComponent: PATH_COMPONENT__NY_DONE, questionnaireEarlyExit: true },
      1: { nextPathComponent: PATH_COMPONENT__NY_USE_TAX_AMOUNT, questionnaireEarlyExit: false }
    },

    [SLUG__AL_USE_TAX_PURCHASES]: {
      0: { nextPathComponent: PATH_COMPONENT__AL_DONE, questionnaireEarlyExit: true },
      1: { nextPathComponent: PATH_COMPONENT__AL_USE_TAX_AMOUNT, questionnaireEarlyExit: false }
    },

    [SLUG__CO_USE_TAX_PURCHASES]: {
      0: { nextPathComponent: PATH_COMPONENT__CO_DONE, questionnaireEarlyExit: true },
      1: { nextPathComponent: PATH_COMPONENT__CO_USE_TAX_AMOUNT, questionnaireEarlyExit: false }
    },

    [SLUG__CT_USE_TAX_PURCHASES]: {
      0: { nextPathComponent: PATH_COMPONENT__CT_DONE, questionnaireEarlyExit: true },
      1: { nextPathComponent: PATH_COMPONENT__CT_USE_TAX_AMOUNT, questionnaireEarlyExit: false }
    },

    [SLUG__DC_RENT_CREDIT_PROPERTY_TYPE]: {
      Apartment: { nextPathComponent: PATH_COMPONENT__DC_RENT_CREDIT_OPTIONS, questionnaireEarlyExit: false },
      PrivateHome: { nextPathComponent: PATH_COMPONENT__DC_RENT_CREDIT_OPTIONS, questionnaireEarlyExit: false },
      Condominium: { nextPathComponent: PATH_COMPONENT__DC_RENT_CREDIT_OPTIONS, questionnaireEarlyExit: false },
      DidntLiveInState: { nextPathComponent: PATH_COMPONENT__DC_DONE, questionnaireEarlyExit: true }
    },

    [SLUG__ID_TUITION]: {
      0: { nextPathComponent: PATH_COMPONENT__ID_DONE, questionnaireEarlyExit: true },
      1: { nextPathComponent: PATH_COMPONENT__ID_TUITION_AMOUNT, questionnaireEarlyExit: false }
    },

    [SLUG__IL_TUITION]: {
      0: { nextPathComponent: PATH_COMPONENT__IL_DONE, questionnaireEarlyExit: true },
      1: { nextPathComponent: PATH_COMPONENT__IL_TUITION_INFO, questionnaireEarlyExit: false }
    },

    [SLUG__KY_USE_TAX_PURCHASES]: {
      0: { nextPathComponent: PATH_COMPONENT__KY_DONE, questionnaireEarlyExit: true },
      1: { nextPathComponent: PATH_COMPONENT__KY_USE_TAX_PRICE, questionnaireEarlyExit: false }
    },
    [SLUG__KY_USE_TAX_PRICE]: {
      0: { nextPathComponent: PATH_COMPONENT__KY_REVIEW, questionnaireEarlyExit: false },
      1: { nextPathComponent: PATH_COMPONENT__KY_DONE, questionnaireEarlyExit: true }
    },

    [SLUG__MI_USE_TAX_PURCHASES]: {
      0: { nextPathComponent: PATH_COMPONENT__MI_DONE, questionnaireEarlyExit: true },
      1: { nextPathComponent: PATH_COMPONENT__MI_USE_TAX_AMOUNT, questionnaireEarlyExit: false }
    },

    [SLUG__MN_TUITION]: {
      0: { nextPathComponent: PATH_COMPONENT__MN_DONE, questionnaireEarlyExit: true },
      1: { nextPathComponent: PATH_COMPONENT__MN_TUITION_INSTITUTION, questionnaireEarlyExit: false }
    },

    [SLUG__NJ_USE_TAX_PURCHASES]: {
      0: { nextPathComponent: PATH_COMPONENT__NJ_COUNTY, questionnaireEarlyExit: false },
      1: { nextPathComponent: PATH_COMPONENT__NJ_COUNTY, questionnaireEarlyExit: false },
      2: { nextPathComponent: PATH_COMPONENT__NJ_DONE, questionnaireEarlyExit: true }
    },

    [SLUG__OH_USE_TAX_PURCHASES]: {
      0: { nextPathComponent: PATH_COMPONENT__OH_DONE, questionnaireEarlyExit: true },
      1: { nextPathComponent: PATH_COMPONENT__OH_USE_TAX_AMOUNT, questionnaireEarlyExit: false }
    },

    [SLUG__OK_USE_TAX_PURCHASES]: {
      0: { nextPathComponent: PATH_COMPONENT__OK_DONE, questionnaireEarlyExit: true },
      1: { nextPathComponent: PATH_COMPONENT__OK_USE_TAX_AMOUNT, questionnaireEarlyExit: false }
    },

    [SLUG__OR_SURPLUS_INTRO]: {
      0: { nextPathComponent: PATH_COMPONENT__OR_SURPLUS_LIABILITY, questionnaireEarlyExit: false },
      1: { nextPathComponent: PATH_COMPONENT__OR_DONE, questionnaireEarlyExit: true }
    },

    [SLUG__OR_SURPLUS_LIABILITY]: {
      0: { nextPathComponent: PATH_COMPONENT__OR_SURPLUS_PRIOR_STATUS, questionnaireEarlyExit: false },
      1: { nextPathComponent: PATH_COMPONENT__OR_DONE, questionnaireEarlyExit: true },
      2: { nextPathComponent: PATH_COMPONENT__OR_DONE, questionnaireEarlyExit: true }
    },

    [SLUG__RI_USE_TAX_PURCHASES]: {
      0: { nextPathComponent: PATH_COMPONENT__RI_HEALTHCARE, questionnaireEarlyExit: false },
      1: { nextPathComponent: PATH_COMPONENT__RI_USE_TAX_AMOUNT, questionnaireEarlyExit: false },
      2: { nextPathComponent: PATH_COMPONENT__RI_DONE, questionnaireEarlyExit: true }
    },

    [SLUG__SC_USE_TAX_PURCHASES]: {
      0: { nextPathComponent: PATH_COMPONENT__SC_DONE, questionnaireEarlyExit: true },
      1: { nextPathComponent: PATH_COMPONENT__SC_USE_TAX_AMOUNT, questionnaireEarlyExit: false }
    },

    [SLUG__UT_USE_TAX_PURCHASES]: {
      0: { nextPathComponent: PATH_COMPONENT__UT_DONE, questionnaireEarlyExit: true },
      1: { nextPathComponent: PATH_COMPONENT__UT_USE_TAX_AMOUNT, questionnaireEarlyExit: false }
    },

    [SLUG__VA_USE_TAX_PURCHASES]: {
      0: { nextPathComponent: PATH_COMPONENT__VA_DONE, questionnaireEarlyExit: true },
      1: { nextPathComponent: PATH_COMPONENT__VA_USE_TAX_AMOUNT, questionnaireEarlyExit: false }
    },

    [SLUG__VT_USE_TAX_PURCHASES]: {
      0: { nextPathComponent: PATH_COMPONENT__VT_DONE, questionnaireEarlyExit: true },
      1: { nextPathComponent: PATH_COMPONENT__VT_USE_TAX_AMOUNT, questionnaireEarlyExit: false }
    },

    [SLUG__WI_USE_TAX_PURCHASES]: {
      0: { nextPathComponent: PATH_COMPONENT__WI_DONE, questionnaireEarlyExit: true },
      1: { nextPathComponent: PATH_COMPONENT__WI_USE_TAX_AMOUNT, questionnaireEarlyExit: false }
    },

    [SLUG__WV_USE_TAX_PURCHASES]: {
      0: { nextPathComponent: PATH_COMPONENT__WV_DONE, questionnaireEarlyExit: true },
      1: { nextPathComponent: PATH_COMPONENT__WV_USE_TAX_AMOUNT, questionnaireEarlyExit: false }
    },

    [SLUG__ME_USE_TAX_PURCHASES]: {
      0: { nextPathComponent: PATH_COMPONENT__ME_DONE, questionnaireEarlyExit: true },
      1: { nextPathComponent: PATH_COMPONENT__ME_USE_TAX_AMOUNT, questionnaireEarlyExit: false }
    }
  };
};

export const getNextPathComponent = ({ question, collectionId, answer, queryResults, stateTaxSlugs }) => {
  if (isStateHealthcareSlug({ stateHealthcare: CA_HEALTHCARE, slug: question.slug })) {
    return {
      nextPathComponent: getStateHealthcareNextPathComponent({
        stateHealthcare: CA_HEALTHCARE,
        question,
        queryResults,
        collectionId
      })
    };
  } else if (isStateHealthcareSlug({ stateHealthcare: DC_HEALTHCARE, slug: question.slug })) {
    return {
      nextPathComponent: getStateHealthcareNextPathComponent({
        stateHealthcare: DC_HEALTHCARE,
        question,
        queryResults,
        collectionId
      })
    };
  } else if (question.slug === SLUG__DC_RENT_CREDIT_OPTIONS) {
    if (_.get(answer, 'value') === 'rent') {
      return { nextPathComponent: PATH_COMPONENT__DC_RENT_CREDIT_AMOUNT };
    } else {
      const propertyType = getQueryResultByEndpointAttribute({
        queryResults,
        collectionType: COLLECTION_TYPE__DC_RENT_CREDIT,
        collectionId: DEFAULT_COLLECTION_ID,
        slug: ENDPOINT_ATTRIBUTE__DC_RENT_CREDIT_PROPERTY_TYPE
      });
      if (_.get(propertyType, ['answer', 'value']) === 'Apartment') {
        return { nextPathComponent: PATH_COMPONENT__DC_HEALTHCARE };
      } else {
        return { nextPathComponent: PATH_COMPONENT__DC_RENT_CREDIT_PROPERTY_TAX };
      }
    }
  } else if (question.slug === SLUG__DC_RENT_CREDIT_LANDLORD_INFO) {
    const propertyType = getQueryResultByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__DC_RENT_CREDIT,
      collectionId: DEFAULT_COLLECTION_ID,
      slug: ENDPOINT_ATTRIBUTE__DC_RENT_CREDIT_PROPERTY_TYPE
    });
    if (_.get(propertyType, ['answer', 'value']) === 'Apartment') {
      return { nextPathComponent: PATH_COMPONENT__DC_HEALTHCARE };
    } else {
      return { nextPathComponent: PATH_COMPONENT__DC_RENT_CREDIT_PROPERTY_TAX };
    }
  } else if (question.slug === SLUG__IL_USE_TAX_PURCHASES) {
    if (_.get(answer, 'value') === '1') {
      return { nextPathComponent: PATH_COMPONENT__IL_USE_TAX_AMOUNT };
    } else {
      const dependents = getQueryResultsByEndpointAttribute({
        queryResults,
        collectionType: COLLECTION_TYPE__DEPENDENT,
        slug: ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME
      });

      if (!_.isEmpty(dependents)) {
        return { nextPathComponent: PATH_COMPONENT__IL_TUITION };
      } else {
        return { nextPathComponent: PATH_COMPONENT__IL_DONE, questionnaireEarlyExit: true };
      }
    }
  } else if (question.slug === SLUG__IL_USE_TAX_AMOUNT) {
    const dependents = getQueryResultsByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__DEPENDENT,
      slug: ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME
    });

    if (!_.isEmpty(dependents)) {
      return { nextPathComponent: PATH_COMPONENT__IL_TUITION };
    } else {
      return { nextPathComponent: PATH_COMPONENT__IL_DONE, questionnaireEarlyExit: true };
    }
  } else if (isStateHealthcareSlug({ stateHealthcare: NJ_HEALTHCARE, slug: question.slug })) {
    return {
      nextPathComponent: getStateHealthcareNextPathComponent({
        stateHealthcare: NJ_HEALTHCARE,
        question,
        queryResults,
        collectionId
      })
    };
  } else if (question.slug === SLUG__IN_COUNTY_WORKED) {
    const status = getQueryResultByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__SELF,
      collectionId: DEFAULT_COLLECTION_ID,
      slug: ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS
    });

    if (_.get(status, ['answer', 'value']) === 'married') {
      return { nextPathComponent: PATH_COMPONENT__IN_COUNTY_SPOUSE_WORKED };
    } else {
      return { nextPathComponent: PATH_COMPONENT__IN_SCHOOL_DISTRICT };
    }
  } else if (question.slug === SLUG__MA_HEALTH_TAXPAYER_COVERAGE) {
    if (_.get(answer, 'value') === '1' || _.get(answer, 'value') === '2') {
      return { nextPathComponent: PATH_COMPONENT__MA_HEALTH_TAXPAYER_GOVT_INSURANCE };
    } else if (_.get(answer, 'value') === '3') {
      return { nextPathComponent: PATH_COMPONENT__MA_DONE, questionnaireEarlyExit: true };
    } else {
      return { nextPathComponent: getHealthcareExitPathComponent({ queryResults }) };
    }
  } else if (question.slug === SLUG__MA_HEALTH_TAXPAYER_GOVT_INSURANCE) {
    if (_.get(answer, 'value') === 'Private Insurance') {
      return { nextPathComponent: PATH_COMPONENT__MA_HEALTH_TAXPAYER_PRIVATE_INSURANCE };
    } else {
      return { nextPathComponent: getHealthcareMonthsPathComponent({ queryResults }) };
    }
  } else if (question.slug === SLUG__MA_HEALTH_TAXPAYER_PRIVATE_INSURANCE) {
    return { nextPathComponent: getHealthcareMonthsPathComponent({ queryResults }) };
  } else if (question.slug === SLUG__MA_HEALTH_TAXPAYER_MONTHS) {
    return { nextPathComponent: getHealthcareExitPathComponent({ queryResults }) };
  } else if (question.slug === SLUG__MA_HEALTH_SPOUSE_COVERAGE) {
    if (_.get(answer, 'value') === '1' || _.get(answer, 'value') === '2') {
      return { nextPathComponent: PATH_COMPONENT__MA_HEALTH_SPOUSE_GOVT_INSURANCE };
    } else {
      return { nextPathComponent: PATH_COMPONENT__MA_DONE, questionnaireEarlyExit: true };
    }
  } else if (question.slug === SLUG__MA_HEALTH_SPOUSE_GOVT_INSURANCE) {
    if (_.get(answer, 'value') === 'Private Insurance') {
      return { nextPathComponent: PATH_COMPONENT__MA_HEALTH_SPOUSE_PRIVATE_INSURANCE };
    } else {
      return { nextPathComponent: getSpouseHealthcareMonthsPathComponent({ queryResults }) };
    }
  } else if (question.slug === SLUG__MA_HEALTH_SPOUSE_PRIVATE_INSURANCE) {
    return { nextPathComponent: getSpouseHealthcareMonthsPathComponent({ queryResults }) };
  } else if (isStateHealthcareSlug({ stateHealthcare: RI_HEALTHCARE, slug: question.slug })) {
    return {
      nextPathComponent: getStateHealthcareNextPathComponent({
        stateHealthcare: RI_HEALTHCARE,
        question,
        queryResults,
        collectionId
      })
    };
  } else if (question.slug === 'bulk-upload-questions-state-info') {
    const stateReturns = _.sortBy(
      getQueryResultsByEndpointAttribute({
        queryResults,
        collectionType: COLLECTION_TYPE__STATE_RETURN,
        slug: ENDPOINT_ATTRIBUTE__STATE_RETURN
      }),
      ['coll_id']
    );

    if (stateReturns.length === 0) {
      return { nextPathComponent: PATH_COMPONENT__STATE_RETURN, questionnaireEarlyExit: true };
    }

    const stateCollTypeDone = getQueryResultByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__STATE_RETURN,
      collectionId: DEFAULT_COLLECTION_ID,
      slug: ENDPOINT_ATTRIBUTE__STATE_RETURN_DONE
    });

    if (!stateCollTypeDone) {
      const firstState = _.get(stateReturns[0], ['answer', 'value']);
      const statePathComponent = _.get(stateTaxSlugs, firstState) || PATH_COMPONENT__STATE_DEFAULT;

      return { nextPathComponent: statePathComponent };
    } else if (stateReturns.length === 1) {
      return {};
    }
  } else if (question.slug === SLUG__STATE_RETURN) {
    const stateQueryResult = _.get(answer, 'value');
    const statePathComponent = _.get(stateTaxSlugs, stateQueryResult) || PATH_COMPONENT__STATE_DEFAULT;
    return { nextPathComponent: statePathComponent };
  } else if (question.slug === SLUG__PA_USE_TAX_PURCHASES) {
    const jobNames = getQueryResultsByEndpointAttribute({
      queryResults,
      collectionType: INCOME_COLLECTION_TYPES.FREELANCE,
      slug: INCOME_ENDPOINT_ATTRIBUTES.FREELANCE_JOB_NAME
    });

    if (_.isEmpty(jobNames)) {
      return { nextPathComponent: PATH_COMPONENT__PA_W2_OCCUPATION };
    } else {
      return { nextPathComponent: PATH_COMPONENT__PA_DONE, questionnaireEarlyExit: true };
    }
  } else if (question.slug === SLUG__NY_COUNTY) {
    const county = _.get(answer, 'value');
    const currentOption = _.find(
      _.get(question, ['question_meta', 'data']),
      (option) => _.get(option, 'value') === county
    );

    if (_.get(currentOption, 'inNewYorkCity')) {
      return { nextPathComponent: PATH_COMPONENT__NY_LIVING_DURATION };
    }

    return { nextPathComponent: PATH_COMPONENT__NY_USE_TAX_PURCHASES };
  } else if (question.slug === SLUG__OR_SURPLUS_PRIOR_STATUS) {
    const status = getQueryResultByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__SELF,
      collectionId: DEFAULT_COLLECTION_ID,
      slug: ENDPOINT_ATTRIBUTE__SELF_MARRIAGE_FILING_OPTION
    });
    const currentFilingStatus = _.get(status, ['answer', 'value']);
    const priorFilingStatus = answer.value;

    if (priorFilingStatus === '0' && currentFilingStatus === 'filing-jointly') {
      // If they filed jointly both last year and this year, show the JOINT component
      return { nextPathComponent: PATH_COMPONENT__OR_SURPLUS_LIABILITY_AMOUNT_JOINT };
    } else if (priorFilingStatus === '0' && currentFilingStatus !== 'filing-jointly') {
      // If they filed jointly last year, but not this year then show the SEPARATED component
      return { nextPathComponent: PATH_COMPONENT__OR_SURPLUS_LIABILITY_AMOUNT_SEPARATED };
    } else if (priorFilingStatus === '1' && currentFilingStatus === 'filing-jointly') {
      // If they filed individually last year, but jointly this year then show the NEWLY_MARRIED component
      return { nextPathComponent: PATH_COMPONENT__OR_SURPLUS_LIABILITY_AMOUNT_NEWLY_MARRIED };
    } else {
      // Else show the SINGLE component
      return { nextPathComponent: PATH_COMPONENT__OR_SURPLUS_LIABILITY_AMOUNT_SINGLE };
    }
  }

  return { nextPathComponent: null };
};

const getHealthcareMonthsPathComponent = ({ queryResults }) => {
  const coverage = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__MA_HEALTH,
    collectionId: DEFAULT_COLLECTION_ID,
    slug: ENDPOINT_ATTRIBUTE__MA_HEALTH_TAXPAYER_COVERAGE
  });

  if (_.get(coverage, ['answer', 'value']) === '2') {
    return PATH_COMPONENT__MA_HEALTH_TAXPAYER_MONTHS;
  } else {
    return getHealthcareExitPathComponent({ queryResults });
  }
};

const getHealthcareExitPathComponent = ({ queryResults }) => {
  const status = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__SELF,
    collectionId: DEFAULT_COLLECTION_ID,
    slug: ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS
  });

  if (_.get(status, ['answer', 'value']) === 'married') {
    return PATH_COMPONENT__MA_HEALTH_SPOUSE_COVERAGE;
  } else {
    return PATH_COMPONENT__MA_DONE;
  }
};

const getSpouseHealthcareMonthsPathComponent = ({ queryResults }) => {
  const coverage = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__MA_HEALTH,
    collectionId: DEFAULT_COLLECTION_ID,
    slug: ENDPOINT_ATTRIBUTE__MA_HEALTH_SPOUSE_COVERAGE
  });

  if (_.get(coverage, ['answer', 'value']) === '2') {
    return PATH_COMPONENT__MA_HEALTH_SPOUSE_MONTHS;
  } else {
    return PATH_COMPONENT__MA_DONE;
  }
};

export const getStateReturnCollId = ({ firstScreenSlug, collectionId, queryResults, stateTaxSlugs }) => {
  // stateTaxSlugs is a map w states as props and each value is the slug of the first screen in the state flow
  const stateStartSlugMap = _.invert(stateTaxSlugs);

  // get current state based on which screen the user is viewing
  const state = stateStartSlugMap[firstScreenSlug];

  let stateCollId;
  if (firstScreenSlug === SLUG__STATE_DEFAULT || firstScreenSlug === SLUG__STATE_NO_INCOME_TAX) {
    // for state-default and state-income-tax screens, coll id is in the query param
    stateCollId = collectionId;
  } else if (state) {
    // for specific state flow start screens, need to find the correct coll id
    // search for the state-return query result w matching state value and use that coll id
    const stateReturns = getQueryResultsByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__STATE_RETURN,
      slug: ENDPOINT_ATTRIBUTE__STATE_RETURN
    });
    stateCollId = _.get(
      stateReturns.find((stateReturn) => _.get(stateReturn, ['answer', 'value']) === state),
      'coll_id'
    );
  }

  return stateCollId;
};

export const getSlugMap = () => {
  return {
    [PATH_COMPONENT__STATE_DEFAULT]: SLUG__STATE_DEFAULT,
    [PATH_COMPONENT__STATE_NO_INCOME_TAX]: SLUG__STATE_NO_INCOME_TAX,
    [PATH_COMPONENT__STATE_RETURN]: SLUG__STATE_RETURN,
    [PATH_COMPONENT__STATE_RESIDENCY]: SLUG__STATE_RESIDENCY,
    [PATH_COMPONENT__STATE_INCOME]: SLUG__STATE_INCOME,
    [PATH_COMPONENT__STATE_EXPENSES]: SLUG__STATE_EXPENSES,

    [PATH_COMPONENT__CA_USE_TAX_PURCHASES]: SLUG__CA_USE_TAX_PURCHASES,
    [PATH_COMPONENT__CA_USE_TAX_AMOUNT]: SLUG__CA_USE_TAX_AMOUNT,
    [PATH_COMPONENT__CA_RENT_CREDIT]: SLUG__CA_RENT_CREDIT,
    [PATH_COMPONENT__CA_RENT_CREDIT_DEPENDENT]: SLUG__CA_RENT_CREDIT_DEPENDENT,
    [PATH_COMPONENT__CA_RENT_CREDIT_PROPERTY]: SLUG__CA_RENT_CREDIT_PROPERTY,
    [PATH_COMPONENT__CA_RENT_CREDIT_TAX]: SLUG__CA_RENT_CREDIT_TAX,
    [PATH_COMPONENT__CA_RENT_CREDIT_SUCCESS]: SLUG__CA_RENT_CREDIT_SUCCESS,
    [PATH_COMPONENT__CA_HEALTHCARE]: SLUG__CA_HEALTHCARE,
    [PATH_COMPONENT__CA_HEALTHCARE_SELF_DURATION]: SLUG__CA_HEALTHCARE_SELF_DURATION,
    [PATH_COMPONENT__CA_HEALTHCARE_SPOUSE_DURATION]: SLUG__CA_HEALTHCARE_SPOUSE_DURATION,
    [PATH_COMPONENT__CA_HEALTHCARE_DEPENDENT_DURATION]: SLUG__CA_HEALTHCARE_DEPENDENT_DURATION,
    [PATH_COMPONENT__CA_DONE]: SLUG__CA_DONE,

    [PATH_COMPONENT__DC_RENT_CREDIT_OPTIONS]: SLUG__DC_RENT_CREDIT_OPTIONS,
    [PATH_COMPONENT__DC_RENT_CREDIT_PROPERTY_TYPE]: SLUG__DC_RENT_CREDIT_PROPERTY_TYPE,
    [PATH_COMPONENT__DC_RENT_CREDIT_AMOUNT]: SLUG__DC_RENT_CREDIT_AMOUNT,
    [PATH_COMPONENT__DC_RENT_CREDIT_LANDLORD_INFO]: SLUG__DC_RENT_CREDIT_LANDLORD_INFO,
    [PATH_COMPONENT__DC_RENT_CREDIT_PROPERTY_TAX]: SLUG__DC_RENT_CREDIT_PROPERTY_TAX,
    [PATH_COMPONENT__DC_HEALTHCARE]: SLUG__DC_HEALTHCARE,
    [PATH_COMPONENT__DC_HEALTHCARE_SELF_DURATION]: SLUG__DC_HEALTHCARE_SELF_DURATION,
    [PATH_COMPONENT__DC_HEALTHCARE_SPOUSE_DURATION]: SLUG__DC_HEALTHCARE_SPOUSE_DURATION,
    [PATH_COMPONENT__DC_HEALTHCARE_DEPENDENT_DURATION]: SLUG__DC_HEALTHCARE_DEPENDENT_DURATION,
    [PATH_COMPONENT__DC_DONE]: SLUG__DC_DONE,

    [PATH_COMPONENT__HI_HOUSING_DISTRIBUTION]: SLUG__HI_HOUSING_DISTRIBUTION,
    [PATH_COMPONENT__HI_HOUSING_INFO]: SLUG__HI_HOUSING_INFO,
    [PATH_COMPONENT__HI_RENT_CREDIT_QUALIFY]: SLUG__HI_RENT_CREDIT_QUALIFY,
    [PATH_COMPONENT__HI_RENT_CREDIT_INFO]: SLUG__HI_RENT_CREDIT_INFO,
    [PATH_COMPONENT__HI_DONE]: SLUG__HI_DONE,

    [PATH_COMPONENT__NC_USE_TAX_PURCHASES]: SLUG__NC_USE_TAX_PURCHASES,
    [PATH_COMPONENT__NC_USE_TAX_AMOUNT]: SLUG__NC_USE_TAX_AMOUNT,
    [PATH_COMPONENT__NC_DONE]: SLUG__NC_DONE,

    [PATH_COMPONENT__NJ_COUNTY]: SLUG__NJ_COUNTY,
    [PATH_COMPONENT__NJ_HEALTHCARE]: SLUG__NJ_HEALTHCARE,
    [PATH_COMPONENT__NJ_HEALTHCARE_SELF_DURATION]: SLUG__NJ_HEALTHCARE_SELF_DURATION,
    [PATH_COMPONENT__NJ_HEALTHCARE_SPOUSE_DURATION]: SLUG__NJ_HEALTHCARE_SPOUSE_DURATION,
    [PATH_COMPONENT__NJ_HEALTHCARE_DEPENDENT_DURATION]: SLUG__NJ_HEALTHCARE_DEPENDENT_DURATION,
    [PATH_COMPONENT__NJ_USE_TAX_PURCHASES]: SLUG__NJ_USE_TAX_PURCHASES,
    [PATH_COMPONENT__NJ_DONE]: SLUG__NJ_DONE,

    [PATH_COMPONENT__NY_SCHOOL_DISTRICT]: SLUG__NY_SCHOOL_DISTRICT,
    [PATH_COMPONENT__NY_COUNTY]: SLUG__NY_COUNTY,
    [PATH_COMPONENT__NY_LIVING_DURATION]: SLUG__NY_LIVING_DURATION,
    [PATH_COMPONENT__NY_USE_TAX_PURCHASES]: SLUG__NY_USE_TAX_PURCHASES,
    [PATH_COMPONENT__NY_USE_TAX_AMOUNT]: SLUG__NY_USE_TAX_AMOUNT,
    [PATH_COMPONENT__NY_DONE]: SLUG__NY_DONE,

    [PATH_COMPONENT__AL_USE_TAX_PURCHASES]: SLUG__AL_USE_TAX_PURCHASES,
    [PATH_COMPONENT__AL_USE_TAX_AMOUNT]: SLUG__AL_USE_TAX_AMOUNT,
    [PATH_COMPONENT__AL_DONE]: SLUG__AL_DONE,

    [PATH_COMPONENT__CO_USE_TAX_PURCHASES]: SLUG__CO_USE_TAX_PURCHASES,
    [PATH_COMPONENT__CO_USE_TAX_AMOUNT]: SLUG__CO_USE_TAX_AMOUNT,
    [PATH_COMPONENT__CO_DONE]: SLUG__CO_DONE,

    [PATH_COMPONENT__CT_USE_TAX_PURCHASES]: SLUG__CT_USE_TAX_PURCHASES,
    [PATH_COMPONENT__CT_USE_TAX_AMOUNT]: SLUG__CT_USE_TAX_AMOUNT,
    [PATH_COMPONENT__CT_DONE]: SLUG__CT_DONE,

    [PATH_COMPONENT__ID_TUITION]: SLUG__ID_TUITION,
    [PATH_COMPONENT__ID_TUITION_AMOUNT]: SLUG__ID_TUITION_AMOUNT,
    [PATH_COMPONENT__ID_DONE]: SLUG__ID_DONE,

    [PATH_COMPONENT__IL_USE_TAX_PURCHASES]: SLUG__IL_USE_TAX_PURCHASES,
    [PATH_COMPONENT__IL_USE_TAX_AMOUNT]: SLUG__IL_USE_TAX_AMOUNT,
    [PATH_COMPONENT__IL_TUITION]: SLUG__IL_TUITION,
    [PATH_COMPONENT__IL_TUITION_INFO]: SLUG__IL_TUITION_INFO,
    [PATH_COMPONENT__IL_DONE]: SLUG__IL_DONE,

    [PATH_COMPONENT__IN_COUNTY_LIVED]: SLUG__IN_COUNTY_LIVED,
    [PATH_COMPONENT__IN_COUNTY_WORKED]: SLUG__IN_COUNTY_WORKED,
    [PATH_COMPONENT__IN_COUNTY_SPOUSE_WORKED]: SLUG__IN_COUNTY_SPOUSE_WORKED,
    [PATH_COMPONENT__IN_SCHOOL_DISTRICT]: SLUG__IN_SCHOOL_DISTRICT,
    [PATH_COMPONENT__IN_DONE]: SLUG__IN_DONE,

    [PATH_COMPONENT__IA_SCHOOL_DISTRICT]: SLUG__IA_SCHOOL_DISTRICT,
    [PATH_COMPONENT__IA_DONE]: SLUG__IA_DONE,

    [PATH_COMPONENT__KY_USE_TAX_PURCHASES]: SLUG__KY_USE_TAX_PURCHASES,
    [PATH_COMPONENT__KY_USE_TAX_PRICE]: SLUG__KY_USE_TAX_PRICE,
    [PATH_COMPONENT__KY_REVIEW]: SLUG__KY_REVIEW,
    [PATH_COMPONENT__KY_DONE]: SLUG__KY_DONE,

    [PATH_COMPONENT__MI_SCHOOL_DISTRICT]: SLUG__MI_SCHOOL_DISTRICT,
    [PATH_COMPONENT__MI_USE_TAX_PURCHASES]: SLUG__MI_USE_TAX_PURCHASES,
    [PATH_COMPONENT__MI_USE_TAX_AMOUNT]: SLUG__MI_USE_TAX_AMOUNT,
    [PATH_COMPONENT__MI_DONE]: SLUG__MI_DONE,

    [PATH_COMPONENT__MN_TUITION]: SLUG__MN_TUITION,
    [PATH_COMPONENT__MN_TUITION_INSTITUTION]: SLUG__MN_TUITION_INSTITUTION,
    [PATH_COMPONENT__MN_TUITION_ACCOUNT]: SLUG__MN_TUITION_ACCOUNT,
    [PATH_COMPONENT__MN_TUITION_AMOUNT]: SLUG__MN_TUITION_AMOUNT,
    [PATH_COMPONENT__MN_DONE]: SLUG__MN_DONE,

    [PATH_COMPONENT__NE_SCHOOL_DISTRICT]: SLUG__NE_SCHOOL_DISTRICT,
    [PATH_COMPONENT__NE_DONE]: SLUG__NE_DONE,

    [PATH_COMPONENT__ND_SCHOOL_DISTRICT]: SLUG__ND_SCHOOL_DISTRICT,
    [PATH_COMPONENT__ND_INCOME_SOURCE]: SLUG__ND_INCOME_SOURCE,
    [PATH_COMPONENT__ND_DONE]: SLUG__ND_DONE,

    [PATH_COMPONENT__OH_SCHOOL_DISTRICT]: SLUG__OH_SCHOOL_DISTRICT,
    [PATH_COMPONENT__OH_USE_TAX_PURCHASES]: SLUG__OH_USE_TAX_PURCHASES,
    [PATH_COMPONENT__OH_USE_TAX_AMOUNT]: SLUG__OH_USE_TAX_AMOUNT,
    [PATH_COMPONENT__OH_DONE]: SLUG__OH_DONE,

    [PATH_COMPONENT__OK_USE_TAX_PURCHASES]: SLUG__OK_USE_TAX_PURCHASES,
    [PATH_COMPONENT__OK_USE_TAX_AMOUNT]: SLUG__OK_USE_TAX_AMOUNT,
    [PATH_COMPONENT__OK_DONE]: SLUG__OK_DONE,

    [PATH_COMPONENT__OR_SURPLUS_INTRO]: SLUG__OR_SURPLUS_INTRO,
    [PATH_COMPONENT__OR_SURPLUS_LIABILITY]: SLUG__OR_SURPLUS_LIABILITY,
    [PATH_COMPONENT__OR_SURPLUS_PRIOR_STATUS]: SLUG__OR_SURPLUS_PRIOR_STATUS,
    [PATH_COMPONENT__OR_SURPLUS_LIABILITY_AMOUNT_SINGLE]: SLUG__OR_SURPLUS_LIABILITY_AMOUNT_SINGLE,
    [PATH_COMPONENT__OR_SURPLUS_LIABILITY_AMOUNT_NEWLY_MARRIED]: SLUG__OR_SURPLUS_LIABILITY_AMOUNT_NEWLY_MARRIED,
    [PATH_COMPONENT__OR_SURPLUS_LIABILITY_AMOUNT_JOINT]: SLUG__OR_SURPLUS_LIABILITY_AMOUNT_JOINT,
    [PATH_COMPONENT__OR_SURPLUS_LIABILITY_AMOUNT_SEPARATED]: SLUG__OR_SURPLUS_LIABILITY_AMOUNT_SEPARATED,
    [PATH_COMPONENT__OR_SURPLUS_AGI]: SLUG__OR_SURPLUS_AGI,
    [PATH_COMPONENT__OR_DONE]: SLUG__OR_DONE,

    [PATH_COMPONENT__PA_SCHOOL_DISTRICT]: SLUG__PA_SCHOOL_DISTRICT,
    [PATH_COMPONENT__PA_USE_TAX_PURCHASES]: SLUG__PA_USE_TAX_PURCHASES,
    [PATH_COMPONENT__PA_W2_OCCUPATION]: SLUG__PA_W2_OCCUPATION,
    [PATH_COMPONENT__PA_DONE]: SLUG__PA_DONE,

    [PATH_COMPONENT__RI_HEALTHCARE]: SLUG__RI_HEALTHCARE,
    [PATH_COMPONENT__RI_HEALTHCARE_SELF_DURATION]: SLUG__RI_HEALTHCARE_SELF_DURATION,
    [PATH_COMPONENT__RI_HEALTHCARE_SPOUSE_DURATION]: SLUG__RI_HEALTHCARE_SPOUSE_DURATION,
    [PATH_COMPONENT__RI_HEALTHCARE_DEPENDENT_DURATION]: SLUG__RI_HEALTHCARE_DEPENDENT_DURATION,
    [PATH_COMPONENT__RI_USE_TAX_PURCHASES]: SLUG__RI_USE_TAX_PURCHASES,
    [PATH_COMPONENT__RI_USE_TAX_AMOUNT]: SLUG__RI_USE_TAX_AMOUNT,
    [PATH_COMPONENT__RI_DONE]: SLUG__RI_DONE,

    [PATH_COMPONENT__SC_USE_TAX_PURCHASES]: SLUG__SC_USE_TAX_PURCHASES,
    [PATH_COMPONENT__SC_USE_TAX_AMOUNT]: SLUG__SC_USE_TAX_AMOUNT,
    [PATH_COMPONENT__SC_DONE]: SLUG__SC_DONE,

    [PATH_COMPONENT__UT_USE_TAX_PURCHASES]: SLUG__UT_USE_TAX_PURCHASES,
    [PATH_COMPONENT__UT_USE_TAX_AMOUNT]: SLUG__UT_USE_TAX_AMOUNT,
    [PATH_COMPONENT__UT_DONE]: SLUG__UT_DONE,

    [PATH_COMPONENT__VT_SCHOOL_DISTRICT]: SLUG__VT_SCHOOL_DISTRICT,
    [PATH_COMPONENT__VT_USE_TAX_PURCHASES]: SLUG__VT_USE_TAX_PURCHASES,
    [PATH_COMPONENT__VT_USE_TAX_AMOUNT]: SLUG__VT_USE_TAX_AMOUNT,
    [PATH_COMPONENT__VT_DONE]: SLUG__VT_DONE,

    [PATH_COMPONENT__VA_USE_TAX_PURCHASES]: SLUG__VA_USE_TAX_PURCHASES,
    [PATH_COMPONENT__VA_USE_TAX_AMOUNT]: SLUG__VA_USE_TAX_AMOUNT,
    [PATH_COMPONENT__VA_DONE]: SLUG__VA_DONE,

    [PATH_COMPONENT__WV_USE_TAX_PURCHASES]: SLUG__WV_USE_TAX_PURCHASES,
    [PATH_COMPONENT__WV_USE_TAX_AMOUNT]: SLUG__WV_USE_TAX_AMOUNT,
    [PATH_COMPONENT__WV_DONE]: SLUG__WV_DONE,

    [PATH_COMPONENT__WI_SCHOOL_DISTRICT]: SLUG__WI_SCHOOL_DISTRICT,
    [PATH_COMPONENT__WI_USE_TAX_PURCHASES]: SLUG__WI_USE_TAX_PURCHASES,
    [PATH_COMPONENT__WI_USE_TAX_AMOUNT]: SLUG__WI_USE_TAX_AMOUNT,
    [PATH_COMPONENT__WI_DONE]: SLUG__WI_DONE,

    [PATH_COMPONENT__ME_USE_TAX_PURCHASES]: SLUG__ME_USE_TAX_PURCHASES,
    [PATH_COMPONENT__ME_USE_TAX_AMOUNT]: SLUG__ME_USE_TAX_AMOUNT,
    [PATH_COMPONENT__ME_DONE]: SLUG__ME_DONE,

    [PATH_COMPONENT__MA_HEALTH_TAXPAYER_COVERAGE]: SLUG__MA_HEALTH_TAXPAYER_COVERAGE,
    [PATH_COMPONENT__MA_HEALTH_TAXPAYER_GOVT_INSURANCE]: SLUG__MA_HEALTH_TAXPAYER_GOVT_INSURANCE,
    [PATH_COMPONENT__MA_HEALTH_TAXPAYER_PRIVATE_INSURANCE]: SLUG__MA_HEALTH_TAXPAYER_PRIVATE_INSURANCE,
    [PATH_COMPONENT__MA_HEALTH_TAXPAYER_MONTHS]: SLUG__MA_HEALTH_TAXPAYER_MONTHS,
    [PATH_COMPONENT__MA_HEALTH_SPOUSE_COVERAGE]: SLUG__MA_HEALTH_SPOUSE_COVERAGE,
    [PATH_COMPONENT__MA_HEALTH_SPOUSE_GOVT_INSURANCE]: SLUG__MA_HEALTH_SPOUSE_GOVT_INSURANCE,
    [PATH_COMPONENT__MA_HEALTH_SPOUSE_PRIVATE_INSURANCE]: SLUG__MA_HEALTH_SPOUSE_PRIVATE_INSURANCE,
    [PATH_COMPONENT__MA_HEALTH_SPOUSE_MONTHS]: SLUG__MA_HEALTH_SPOUSE_MONTHS,
    [PATH_COMPONENT__MA_DONE]: SLUG__MA_DONE
  };
};
