import React from 'react';
import { useWatch } from 'react-hook-form';
import FreelanceIncomeInput from '@app/src/Components/TaxProfile/FormInputs/Income/FreelanceIncomeInput';
import SpouseFreelanceIncomeInput from '@app/src/Components/TaxProfile/FormInputs/Income/SpouseFreelanceIncomeInput';
import SpouseW2IncomeInput from '@app/src/Components/TaxProfile/FormInputs/Income/SpouseW2IncomeInput';
import W2IncomeInput from '@app/src/Components/TaxProfile/FormInputs/Income/W2IncomeInput';
import TaxProfileFormSection from '@app/src/Components/TaxProfile/FormSections/TaxProfileFormSection';

/**
 * @typedef IncomeSectionProps
 * @property {string} jobType
 * @property {string} incomeType
 * @property {string} filingStatus
 */

/** @type {React.FC<IncomeSectionProps>} */
const IncomeSection = () => {
  const filingStatus = useWatch({ name: 'filingStatus' });

  return (
    <TaxProfileFormSection header='Income'>
      <W2IncomeInput />
      <FreelanceIncomeInput />
      {filingStatus === 'married' && (
        <>
          <SpouseW2IncomeInput />
          <SpouseFreelanceIncomeInput />
        </>
      )}
    </TaxProfileFormSection>
  );
};

export default IncomeSection;
