import React from 'react';
import ExpenseSlider from '@app/src/Components/TaxProfile/ExpenseSlider';

const HomeOfficeSlider = () => {
  return (
    <ExpenseSlider
      name='deductibleHomeOfficePercentage'
      label='Percent of home used for home office'
      enabledField='deductibleHomeOfficeEnabled'
    />
  );
};

export default HomeOfficeSlider;
