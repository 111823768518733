import React, { forwardRef } from 'react';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Slider from '@mui/material/Slider';
import NumberFormat from 'react-number-format';
import '@app/src/Components/Common/KeeperSlider/KeeperSlider.scss';

const NumberFormatWithRef = forwardRef((props, ref) => <NumberFormat getInputRef={ref} {...props} />);

const KeeperSlider = ({
  value,
  increment = 1,
  max = 100,
  min = 0,
  label,
  onSliderChange,
  onInputChange = () => {},
  format = '###%',
  showInput,
  id,
  onBlur,
  disabled,
  ...props
}) => (
  <div>
    {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
    <div className='slider-input'>
      {showInput && (
        <OutlinedInput
          id={id}
          className='slider-input-base'
          inputComponent={NumberFormatWithRef}
          inputProps={{
            format: format,
            onValueChange: ({ value }) => {
              if (value === '') {
                onInputChange(min);
                return;
              }

              onInputChange(value);
            },
            isAllowed: ({ floatValue }) => floatValue === undefined || (floatValue >= min && floatValue <= max),
            value: value,
            style: { textAlign: 'center' }
          }}
          disabled={disabled}
          onBlur={onBlur}
        />
      )}
      <Slider
        variant='keeper-slider'
        step={increment}
        onChange={(event, value) => onSliderChange(value)}
        value={Number(value)}
        min={min}
        max={max}
        onChangeCommitted={onBlur}
        disabled={disabled}
        {...props}
      />
    </div>
  </div>
);

export default KeeperSlider;
