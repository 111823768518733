import _ from 'lodash';
import { createSelector } from 'reselect';
import { submitIssueItemsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import {
  COLLECTION_TYPE__STATE_RETURN,
  SLUG__STATE_RETURN
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import { SUBMIT_AUDIT_ISSUE, SUBMIT_AUDIT_TYPE } from '@app/src/taxflow/sections/submit/constants/submitConstants';

export const isPaymentValidSelector = (state) => _.get(state, ['taxFlow', 'isPaymentValid']);

export const irsEnabledSelector = (state) => {
  const navigationItems = _.get(state, ['taxFlowNavigation', 'navigationItems']);
  const queryResults = _.get(state, ['taxFlow', 'queryResults']);

  const submitNavItem = navigationItems.find((item) => item.id === 'submit');
  const irsEnabledStatus = _.get(submitNavItem, ['meta', 'irsEnabled']);

  const states = queryResults
    .filter(
      (queryResult) =>
        queryResult.coll_type === COLLECTION_TYPE__STATE_RETURN && queryResult.slug === SLUG__STATE_RETURN
    )
    .map((state) => _.get(state, ['answer', 'value']));

  return states.every((state) => _.get(irsEnabledStatus, state));
};

export const submitIssueTypeSelector = createSelector([submitIssueItemsSelector], (submitIssueItems) => {
  const submitIssueType = submitIssueItems.find((s) => s.slug === SUBMIT_AUDIT_TYPE);
  return _.get(submitIssueType, 'value');
});

export const uncheckedSubmitIssueMessagesSelector = createSelector([submitIssueItemsSelector], (submitIssueItems) => {
  return submitIssueItems.filter((e) => e.slug === SUBMIT_AUDIT_ISSUE && !e.isChecked);
});
