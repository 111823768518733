import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import { Briefcase } from 'iconsax-react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import IconWithFallback from '@app/src/Components/Common/IconWithFallback';
import SquarePill from '@app/src/Components/Common/SquarePill/SquarePill';
import ManageJobsPanel from '@app/src/Components/TaxProfile/ManageJobsPanel';
import { useTaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';
import { SQUARE_PILL_ACTION_ICON_TYPES } from '@app/src/constants/constants';

const JobPill = ({ job, onClick, iconUrl }) => {
  const { id, name } = job;

  return (
    <SquarePill
      key={id ?? name}
      text={name}
      decorativeIcon={
        <IconWithFallback iconUrl={iconUrl} fallbackIcon={<Briefcase />} altText={`${name}-icon`} size={16} />
      }
      onClick={onClick}
      actionIconType={SQUARE_PILL_ACTION_ICON_TYPES.NONE}
    />
  );
};

const JobInput = ({ jobs = [], title, openJobPanel, getItemProps = () => ({}) }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      {title && <span style={{ fontSize: 16, fontWeight: 400 }}>{title}</span>}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 8
        }}
      >
        {jobs.map((job) => {
          const props = getItemProps(job) ?? {};

          return <JobPill key={job.id ?? job.name} job={job} onClick={openJobPanel} {...props} />;
        })}
      </div>
      {openJobPanel && (
        <Button onClick={openJobPanel} style={{ width: 'fit-content', alignSelf: 'center' }}>
          Manage Jobs
        </Button>
      )}
    </div>
  );
};

const useJobsInput = () => {
  const [jobPanelOpen, setJobPanelOpen] = useState(false);
  const [spouseJobPanelOpen, setSpouseJobPanelOpen] = useState(false);
  const { allJobs, topJobCategories, onSubmit } = useTaxProfileFormContext();

  const openJobPanel = () => {
    setJobPanelOpen(true);
  };

  const openSpouseJobPanel = () => {
    setSpouseJobPanelOpen(true);
  };

  const jobsBySlug = useMemo(() => _.keyBy(allJobs, 'slug'), [allJobs]);

  const getItemProps = useCallback(
    (job) => {
      return {
        iconUrl: _.get(jobsBySlug, [job.contentfulSlug, 'icon_url'])
      };
    },
    [jobsBySlug]
  );

  const { handleSubmit } = useFormContext();

  const jobs = useWatch({ name: 'jobs' });
  const spouseJobs = useWatch({ name: 'spouseJobs' });
  const filingStatus = useWatch({ name: 'filingStatus' });

  const prepareForAnalytics = useCallback((jobs) => jobs.map(({ contentfulSlug, name }) => contentfulSlug ?? name), []);

  return {
    // Shared
    allJobs,
    topJobCategories,
    onSubmit,
    getItemProps,
    prepareForAnalytics,
    handleSubmit,
    filingStatus,
    // Jobs
    jobs,
    jobPanelOpen,
    openJobPanel,
    setJobPanelOpen,
    // Spouse jobs
    spouseJobs,
    openSpouseJobPanel,
    spouseJobPanelOpen,
    setSpouseJobPanelOpen
  };
};

const JobsInput = () => {
  const {
    // Shared
    allJobs,
    topJobCategories,
    onSubmit,
    getItemProps,
    prepareForAnalytics,
    handleSubmit,
    filingStatus,
    // Jobs
    jobs,
    jobPanelOpen,
    openJobPanel,
    setJobPanelOpen,
    // Spouse jobs
    spouseJobs,
    openSpouseJobPanel,
    spouseJobPanelOpen,
    setSpouseJobPanelOpen
  } = useJobsInput();

  return (
    <div
      style={{
        border: '1px solid #AAAAAB',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        padding: 16,
        gap: 16
      }}
    >
      <JobInput
        jobs={jobs}
        getItemProps={getItemProps}
        title={(() => {
          if (filingStatus !== 'married' && _.isEmpty(spouseJobs)) {
            return null;
          }

          return 'My Jobs';
        })()}
        openJobPanel={openJobPanel}
      />
      {filingStatus === 'married' && (
        <JobInput
          jobs={spouseJobs}
          title="My Spouse's Jobs"
          getItemProps={getItemProps}
          openJobPanel={openSpouseJobPanel}
        />
      )}
      <Controller
        name='jobs'
        render={({ field: { value, onChange, name } }) => {
          return (
            <ManageJobsPanel
              open={jobPanelOpen}
              onClose={() => setJobPanelOpen(false)}
              allJobs={allJobs}
              topJobCategories={topJobCategories}
              onSave={(jobs) => {
                onChange(jobs);
                handleSubmit(
                  onSubmit(name, {
                    prepareForAnalytics
                  })
                )();
              }}
              value={value}
            />
          );
        }}
      />
      {filingStatus === 'married' && (
        <Controller
          name='spouseJobs'
          render={({ field: { value, onChange, name } }) => {
            return (
              <ManageJobsPanel
                open={spouseJobPanelOpen}
                onClose={() => setSpouseJobPanelOpen(false)}
                allJobs={allJobs}
                topJobCategories={topJobCategories}
                onSave={(jobs) => {
                  onChange(jobs);
                  handleSubmit(
                    onSubmit(name, {
                      prepareForAnalytics
                    })
                  )();
                }}
                value={value}
              />
            );
          }}
        />
      )}
    </div>
  );
};

export default JobsInput;
