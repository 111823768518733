import _ from 'lodash';
import { createSelector } from 'reselect';

export const bankListSelector = (state) => _.get(state, ['bank', 'bankList']);
export const bankLinkSkippedSelector = (state) => _.get(state, ['bank', 'bankLinkSkipped']);
export const chargeableAccountSelector = (state) => _.get(state, ['bank', 'chargeableAccount']);
export const bankLinkPendingSelector = (state) => _.get(state, ['bank', 'loading']);
export const forcePlaidRefreshSelector = (state) => _.get(state, ['bank', 'forceRefresh']);

export const viewableAccountsSelector = createSelector([bankListSelector], (bankList) => {
  return bankList
    .filter((account) => {
      const subAccounts = _.get(account, 'subAccounts', []).filter((subAccount) => !subAccount.archived);
      return !account.isArchived && subAccounts.length > 0;
    })
    .map((account) => ({
      ...account,
      subAccounts: _.get(account, 'subAccounts', []).filter((subAccount) => !subAccount.archived)
    }));
});

export const unlinkedBanksSelector = createSelector([viewableAccountsSelector], (accounts) =>
  _.filter(accounts, { linked: 0 })
);
