import React from 'react';
import AskButton from '@app/src/Components/TaxProfile/AskButton';
import ControlledYearlyMoneyInput from '@app/src/Components/TaxProfile/ControlledYearlyMoneyInput';

const TraditionalIraContributionsInput = () => {
  return (
    <ControlledYearlyMoneyInput
      name='traditionalIraContributions'
      label='Traditional IRA contributions'
      textFieldProps={{
        InputProps: {
          endAdornment: (
            <AskButton
              field='traditionalIraContributions'
              message='What are traditional IRA contributions?'
              response='This is the total amount you contributed to a traditional IRA retirement account. Since traditional IRA contributions are tax-deductible, Keeper will subtract this amount from your taxable income to reduce your taxes owed.'
              origin='Tax Profile Input'
            />
          )
        }
      }}
    />
  );
};

export default TraditionalIraContributionsInput;
