import React from 'react';
import QuarterlyTaxPaymentsInput from '@app/src/Components/TaxProfile/FormInputs/TaxesPaid/QuarterlyTaxPaymentsInput';
import SpouseStateTaxesWithheldInput from '@app/src/Components/TaxProfile/FormInputs/TaxesPaid/SpouseStateTaxesWithheldInput';
import SpouseTaxesWithheldInput from '@app/src/Components/TaxProfile/FormInputs/TaxesPaid/SpouseTaxesWithheldInput';
import StateTaxesWithheldInput from '@app/src/Components/TaxProfile/FormInputs/TaxesPaid/StateTaxesWithheldInput';
import TaxesWithheldInput from '@app/src/Components/TaxProfile/FormInputs/TaxesPaid/TaxesWithheldInput';
import TaxProfileFormSection from '@app/src/Components/TaxProfile/FormSections/TaxProfileFormSection';

/**
 * @typedef TaxesPaidSectionProps
 * @property {import('react-hook-form').UseFormReturn['control']} control
 * @property {import('react-hook-form').UseFormReturn['handleSubmit']} handleSubmit
 * @property {string} filingStatus
 */

/** @type {React.FC<TaxesPaidSectionProps>} */
const TaxesPaidSection = () => {
  return (
    <TaxProfileFormSection header='Taxes paid'>
      <TaxesWithheldInput />
      <StateTaxesWithheldInput />
      <SpouseTaxesWithheldInput />
      <SpouseStateTaxesWithheldInput />
      <QuarterlyTaxPaymentsInput />
    </TaxProfileFormSection>
  );
};

export default TaxesPaidSection;
