import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { TaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';
import { useJobType } from '@app/src/hooks/useJobType';
import { trackActivity } from '@app/src/services/analyticsService';

const TaxProfileFormProvider = ({
  year,
  allJobs,
  onSubmit,
  topJobCategories,
  values,
  isLoading,
  postSubmit,
  children
}) => {
  const methods = useForm({
    values,
    defaultValues: {
      income_type: [],
      jobs: [],
      spouseJobs: [],
      deductibleMealsEnabled: false,
      deductibleTravelEnabled: false
    }
  });

  const { control, reset } = methods;

  const jobs = useWatch({ control, name: 'jobs' });

  const incomeTypes = useWatch({ control, name: 'income_type' });

  const { jobType, incomeType } = useJobType(jobs, incomeTypes, allJobs);

  useEffect(() => {
    // Reset when a new tax profile is fetched
    reset(values);
  }, [reset, values]);

  const createOnSubmit = useCallback(
    (property, options = {}) =>
      async (data) => {
        const previousValue = _.get(values, property);
        const newValue = _.get(data, property);

        if (previousValue === newValue) {
          return;
        }

        await onSubmit(data);

        const { prepareForAnalytics } = options;

        trackActivity('tax profile changed', {
          property_changed: property,
          old: prepareForAnalytics ? prepareForAnalytics(previousValue) : previousValue,
          new: prepareForAnalytics ? prepareForAnalytics(newValue) : newValue,
          origin: 'taxProfile'
        });

        if (postSubmit) {
          postSubmit({
            property,
            newValue,
            options
          });
        }
      },
    [onSubmit, postSubmit, values]
  );

  return (
    <FormProvider {...methods}>
      <TaxProfileFormContext.Provider
        value={{
          year,
          jobType,
          allJobs,
          topJobCategories,
          incomeType,
          isLoading,
          onSubmit: createOnSubmit
        }}
      >
        {children}
      </TaxProfileFormContext.Provider>
    </FormProvider>
  );
};

export default TaxProfileFormProvider;
