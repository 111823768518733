import React from 'react';
import _ from 'lodash';
import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { hasQuestionSpecialChars } from '@app/src/global/Helpers';
import { hasFieldErrorSelector } from '@app/src/selectors/taxValidationSelectors';

class TaxValidationInput extends React.Component {
  onChange = (e) => {
    if (e.target.value && hasQuestionSpecialChars(e.target.value, this.props.question)) {
      return;
    }
    this.props.onChange(e);
  };

  render() {
    const { className, hasError, question } = this.props;

    return (
      <TextField
        fullWidth
        className={classNames(
          'tax-validation-input',
          {
            'tax-validation-input-error': hasError
          },
          'form-control',
          className
        )}
        inputProps={{ maxLength: _.get(question, ['question_meta', 'maxLength']), 'data-testid': question.slug }}
        error={hasError}
        onChange={this.onChange}
        autoComplete={question.slug}
        {..._.omit(this.props, ['dispatch', 'className', 'hasError', 'question', 'answer', 'onChange'])}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  hasError: hasFieldErrorSelector
});

const ConnectedTaxValidationInput = connect(mapStateToProps)(TaxValidationInput);

export default ConnectedTaxValidationInput;
