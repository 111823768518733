import React from 'react';
import { useWatch } from 'react-hook-form';
import AskButton from '@app/src/Components/TaxProfile/AskButton';
import ControlledYearlyMoneyInput from '@app/src/Components/TaxProfile/ControlledYearlyMoneyInput';
import { TAXES_WITHHELD_RESPONSE } from '@app/src/constants/constants';

const SpouseTaxesWithheldInput = () => {
  const filingStatus = useWatch({ name: 'filingStatus' });

  if (filingStatus !== 'married') {
    return null;
  }

  return (
    <ControlledYearlyMoneyInput
      name='spouseW2FederalIncomeTaxesWithheld'
      label="Spouse's W-2 federal income taxes withheld"
      textFieldProps={{
        InputProps: {
          endAdornment: (
            <AskButton
              field='spouseW2FederalIncomeTaxesWithheld'
              message='What are W-2 federal taxes withheld?'
              response={TAXES_WITHHELD_RESPONSE}
              origin='Tax Profile Input'
            />
          )
        }
      }}
    />
  );
};

export default SpouseTaxesWithheldInput;
