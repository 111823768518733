import _ from 'lodash';
import { isReactNative } from '@app/src/global/Helpers';
import { PATH_COMPONENT__HOME } from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  PATH_COMPONENT__SUBMIT_DEBIT,
  SLUG__SUBMIT_DEBIT,
  SLUG__SUBMIT_EXIT_BOUNCE,
  PATH_COMPONENT__SUBMIT_EXIT_BOUNCE,
  SLUG__SUBMIT_DEBIT_MANUAL,
  PATH_COMPONENT__SUBMIT_DEBIT_MANUAL,
  PATH_COMPONENT__SUBMIT_PIN,
  SLUG__SUBMIT_PIN,
  SLUG__SUBMIT_SWITCH_TO_ANNUAL,
  SLUG__SUBMIT_PAY_NOW,
  PATH_COMPONENT__SUBMIT_SWITCH_TO_ANNUAL,
  PATH_COMPONENT__SUBMIT_PAY_NOW,
  PATH_COMPONENT__SUBMIT_SIGNATURE,
  SLUG__SUBMIT_SIGNATURE,
  PATH_COMPONENT__SUBMIT_EMAIL_INFO,
  SLUG__SUBMIT_EMAIL_INFO,
  PATH_COMPONENT__SUBMIT_CONFIRMATION,
  SLUG__SUBMIT_CONFIRMATION,
  SLUG__SUBMIT_PAY_TEST,
  PATH_COMPONENT__SUBMIT_PAY_TEST,
  PATH_COMPONENT__SUBMIT_BANK_NUMBERS,
  SLUG__SUBMIT_BANK_NUMBERS,
  PATH_COMPONENT__SUBMIT_CONFIRM_ID,
  SLUG__SUBMIT_CONFIRM_ID,
  PATH_COMPONENT__SUBMIT_CONFIRM_ID_INTRO,
  SLUG__SUBMIT_CONFIRM_ID_INTRO
} from '@app/src/taxflow/sections/submit/constants/submitConstants';

export const getNextPathComponentMap = () => {
  return {
    [SLUG__SUBMIT_SWITCH_TO_ANNUAL]: {
      nextPathComponent: PATH_COMPONENT__SUBMIT_EMAIL_INFO,
      questionnaireEarlyExit: true
    },
    [SLUG__SUBMIT_EMAIL_INFO]: {
      nextPathComponent: PATH_COMPONENT__SUBMIT_SIGNATURE,
      questionnaireEarlyExit: true
    },
    [SLUG__SUBMIT_CONFIRMATION]: {
      nextPathComponent: PATH_COMPONENT__SUBMIT_CONFIRMATION,
      questionnaireEarlyExit: true
    },
    [SLUG__SUBMIT_CONFIRM_ID_INTRO]: {
      nextPathComponent: PATH_COMPONENT__SUBMIT_CONFIRM_ID,
      questionnaireEarlyExit: true
    }
  };
};

export const getNextPathComponent = ({ question, paid, answer, irsPaymentAccount, idVerificationResult }) => {
  let nextPathComponent = null;

  if (question.slug === SLUG__SUBMIT_CONFIRM_ID && idVerificationResult === 'pass') {
    nextPathComponent = PATH_COMPONENT__HOME;
  } else if (question.slug === SLUG__SUBMIT_CONFIRM_ID && idVerificationResult === 'fail') {
    nextPathComponent = PATH_COMPONENT__HOME;
  } else if (
    question.slug === SLUG__SUBMIT_DEBIT ||
    question.slug === SLUG__SUBMIT_DEBIT_MANUAL ||
    question.slug === SLUG__SUBMIT_BANK_NUMBERS
  ) {
    const chaseAccountSelected = _.get(irsPaymentAccount, 'institution_id') === 'ins_56';

    if (_.get(answer, 'value') === 'ach' && chaseAccountSelected) {
      nextPathComponent = PATH_COMPONENT__SUBMIT_BANK_NUMBERS;
    } else {
      nextPathComponent = getPaymentPathComponent({ paid });
    }
  } else if (question.slug === SLUG__SUBMIT_PAY_NOW) {
    if (paid) {
      nextPathComponent = PATH_COMPONENT__SUBMIT_EMAIL_INFO;
    } else {
      nextPathComponent = PATH_COMPONENT__SUBMIT_PAY_NOW;
    }
  } else if (question.slug === SLUG__SUBMIT_PAY_TEST) {
    if (paid) {
      nextPathComponent = PATH_COMPONENT__SUBMIT_EMAIL_INFO;
    } else {
      nextPathComponent = PATH_COMPONENT__SUBMIT_PAY_TEST;
    }
  } else if (question.slug === SLUG__SUBMIT_SIGNATURE) {
    nextPathComponent = PATH_COMPONENT__SUBMIT_CONFIRMATION;
  }
  return { nextPathComponent };
};

export const getPaymentPathComponent = ({ paid }) => {
  if (paid) {
    return PATH_COMPONENT__SUBMIT_EMAIL_INFO;
  } else if (isReactNative()) {
    return PATH_COMPONENT__SUBMIT_SWITCH_TO_ANNUAL;
  } else {
    return PATH_COMPONENT__SUBMIT_PAY_NOW;
  }
};

export const getSlugMap = () => {
  return {
    [PATH_COMPONENT__SUBMIT_PIN]: SLUG__SUBMIT_PIN,
    [PATH_COMPONENT__SUBMIT_DEBIT]: SLUG__SUBMIT_DEBIT,
    [PATH_COMPONENT__SUBMIT_DEBIT_MANUAL]: SLUG__SUBMIT_DEBIT_MANUAL,
    [PATH_COMPONENT__SUBMIT_BANK_NUMBERS]: SLUG__SUBMIT_BANK_NUMBERS,
    [PATH_COMPONENT__SUBMIT_SWITCH_TO_ANNUAL]: SLUG__SUBMIT_SWITCH_TO_ANNUAL,
    [PATH_COMPONENT__SUBMIT_PAY_NOW]: SLUG__SUBMIT_PAY_NOW,
    [PATH_COMPONENT__SUBMIT_PAY_TEST]: SLUG__SUBMIT_PAY_TEST,
    [PATH_COMPONENT__SUBMIT_EMAIL_INFO]: SLUG__SUBMIT_EMAIL_INFO,
    [PATH_COMPONENT__SUBMIT_SIGNATURE]: SLUG__SUBMIT_SIGNATURE,
    [PATH_COMPONENT__SUBMIT_CONFIRMATION]: SLUG__SUBMIT_CONFIRMATION,
    [PATH_COMPONENT__SUBMIT_EXIT_BOUNCE]: SLUG__SUBMIT_EXIT_BOUNCE,
    [PATH_COMPONENT__SUBMIT_CONFIRM_ID_INTRO]: SLUG__SUBMIT_CONFIRM_ID_INTRO,
    [PATH_COMPONENT__SUBMIT_CONFIRM_ID]: SLUG__SUBMIT_CONFIRM_ID
  };
};
