/* eslint no-console:0*/
import _ from 'lodash';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { setAssistantMessages } from '@app/src/actions/assistantActions';
import {
  setAuthLoader,
  setAuthResponseError,
  setCurrentUser,
  setEmailUnique,
  setPhoneUnique,
  setSettings
} from '@app/src/actions/authActions';
import { setExpensesYear } from '@app/src/actions/expenseReviewActions';
import { setCurrentQuestion as setOnboardingCurrentQuestion } from '@app/src/actions/onboardingActions';
import { setSettingsLoading } from '@app/src/actions/settingsActions';
import { setCurrentQuestion } from '@app/src/actions/taxFlowActions';
import { REACT_APP_ENV, serverUrl } from '@app/src/global/Environment';
import { createCookie, deleteCookie, getDomain, isReactNative, sentMsgToReactNative } from '@app/src/global/Helpers';
import { clearUser, setUser, trackActivity } from '@app/src/services/analyticsService';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import setSentryUser from '@app/src/utils/sentry/setSentryUser';
import setAuthToken from '@app/src/utils/setAuthToken';
import { notify } from '@app/src/utils/snackbarUtils';

const baseUrl = serverUrl();

var _timeout;
const dispatchAuthError = (msg, dispatch) => {
  if (_timeout) clearTimeout(_timeout);
  dispatch(setAuthResponseError(msg));
  notify(msg);

  _timeout = setTimeout(() => {
    dispatch(setAuthResponseError(''));
  }, 3000);
};

export const axiosWithErrorHandling =
  ({ method, url, headers, data, authenticated }) =>
  async () => {
    const res = await axios({
      method,
      url: `${baseUrl}${url}`,
      headers,
      data
    });

    if (authenticated && _.get(res, ['data', 'msg']) === 'Unauthorized Request') {
      throw new Error(_.get(res, ['data', 'msg']));
    }

    return res;
  };

/**
 * @desc Login - Get User Token
 * @param {*} obj Data Obj
 */
export const requestLoginOtp = (obj, callback) => async (dispatch) => {
  dispatch(setAuthResponseError(''));

  if (REACT_APP_ENV === 'staging-prod-db') {
    dispatchAuthError('Login is disabled for staging-prod-db', dispatch);
    return;
  }

  if (!obj || !obj.phone || obj.phone.toString().length !== 10) {
    dispatchAuthError('Phone number must be 10 digits long', dispatch);
    return;
  }

  dispatch(setAuthLoader(true));
  obj.phone = '1' + obj.phone;
  try {
    const res = await dispatch(
      axiosWithErrorHandling({
        method: 'post',
        url: 'api/auth/app-token',
        data: obj
      })
    );
    dispatch(setAuthLoader(false));
    const { status, msg } = res.data;
    if (status === 'ok') {
      callback(res);
    } else {
      dispatchAuthError(msg || 'Unable to send OTP,please try again', dispatch);
    }
  } catch (e) {
    dispatch(setAuthLoader(false));
    dispatchAuthError('Unable to send OTP,please try again', dispatch);
    defaultCaptureException(e);
  }
};

/**
 * @desc Login - Get User Token via email
 * @param {*} obj Data Obj
 */
export const requestLoginEmail = (obj, callback) => async (dispatch) => {
  dispatch(setAuthResponseError(''));

  if (REACT_APP_ENV === 'staging-prod-db') {
    dispatchAuthError('Login is disabled for staging-prod-db', dispatch);
    return;
  }

  dispatch(setAuthLoader(true));
  try {
    const res = await dispatch(
      axiosWithErrorHandling({
        method: 'post',
        url: 'api/auth/email-app-token',
        data: obj
      })
    );
    dispatch(setAuthLoader(false));
    const { status, msg } = res.data;
    if (status === 'ok') {
      callback(res);
    } else {
      dispatchAuthError(msg || 'Unable to send token, please try again', dispatch);
    }
  } catch (e) {
    dispatch(setAuthLoader(false));
    dispatchAuthError('Unable to send token, please try again', dispatch);
    defaultCaptureException(e);
  }
};

export const validateLoginOtpBase = (obj) => async (dispatch) => {
  dispatch(setAuthResponseError(''));
  dispatch(setAuthLoader(true));
  if (obj.phone) {
    obj.phone = '1' + obj.phone;
  }
  try {
    const res = await dispatch(
      axiosWithErrorHandling({
        method: 'post',
        url: 'api/auth/sign-in',
        data: obj
      })
    );
    const { status, data, msg } = res.data;
    if (status === 'ok') {
      const { token } = data;
      localStorage.setItem('KeeperToken', token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
      dispatch(getSettings());

      // set user for analytics
      await setUser(decoded);
      setLoggedInCookie(decoded);
      trackActivity('log in');

      if (isReactNative()) {
        sentMsgToReactNative('login', { token });
      }
    } else {
      dispatchAuthError(msg || 'Unable to login please try again', dispatch);
    }
  } catch (e) {
    dispatchAuthError('Unable to login please try again', dispatch);
    defaultCaptureException(e);
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc Log user out
 */
export const logout =
  ({ origin } = {}) =>
  (dispatch) => {
    if (isReactNative()) {
      if (origin !== 'page_load') {
        sentMsgToReactNative('logout');
      }
      return;
    }

    /**
     * Remove token from localStorage
     * Remove auth header for future requests
     * Set current user to {} which will set isAuthenticated to false
     */
    localStorage.removeItem('KeeperToken');
    setAuthToken(false);
    dispatch(setCurrentUser({}));
    dispatch(setCurrentQuestion({}));
    dispatch(setOnboardingCurrentQuestion({}));
    dispatch(setAssistantMessages([]));
    clearUser();
    setSentryUser(null);
    removeLoggedInCookie(false);
  };

export const setLoggedInCookie = (user) => {
  const domain = getDomain();
  createCookie('isLoggedIn', true, 60, '/', domain);
  createCookie('user_email', user.email, 60, '/', domain);
};

const removeLoggedInCookie = () => {
  const domain = getDomain();
  deleteCookie('isLoggedIn', '/', domain);
  deleteCookie('user_email', '/', domain);
};

/**
 * @desc Check if there is an account with this email
 * @param {*} email
 */
export const checkEmailUnique = (email) => async (dispatch) => {
  try {
    dispatch(setAuthResponseError(''));
    dispatch(setAuthLoader(true));

    const res = await dispatch(
      axiosWithErrorHandling({
        method: 'post',
        url: 'api/auth/check-email-unique',
        data: { email }
      })
    );
    const { status, msg } = _.get(res, 'data', {});
    if (status === 'ok') {
      dispatch(setEmailUnique(true));
    } else {
      trackActivity('onboarding: email submission failed', { reason: msg });
      dispatch(setEmailUnique(false));
    }
  } catch (e) {
    defaultCaptureException(e);
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc Check if there is an account with this phone
 * @param {*} phone
 */
export const checkPhoneUnique = (phone) => async (dispatch) => {
  try {
    dispatch(setAuthResponseError(''));
    dispatch(setAuthLoader(true));

    const res = await dispatch(
      axiosWithErrorHandling({
        method: 'post',
        url: 'api/auth/check-phone-unique',
        data: { phone }
      })
    );
    const { status, msg } = _.get(res, 'data', {});
    if (status === 'ok') {
      dispatch(setPhoneUnique(true));
    } else {
      trackActivity('onboarding: phone submission failed', { reason: msg });
      dispatch(setPhoneUnique(false));
    }
  } catch (e) {
    defaultCaptureException(e);
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc Sign Up. Register User from tax filing
 * @param {*} obj Data Obj
 */
export const signUpTaxFlow = (obj) => async (dispatch) => {
  dispatch(setAuthResponseError(''));
  dispatch(setAuthLoader(true));

  try {
    const res = await dispatch(
      axiosWithErrorHandling({
        method: 'post',
        url: 'api/auth/signup-tax-filing',
        data: obj
      })
    );
    dispatch(setAuthLoader(false));

    await dispatch(setTokenUser(res.data));
  } catch (e) {
    dispatch(setAuthLoader(false));
    throw e;
  }
};

/**
 * @desc Sets token and user data
 * @param {*} res
 */
export const setTokenUser =
  ({ data, status, msg }) =>
  async (dispatch) => {
    if (status === 'ok') {
      const { token } = data;
      // Set token to ls
      localStorage.setItem('KeeperToken', token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      await setUser(decoded);

      return data;
    } else {
      return Promise.reject(msg);
    }
  };

export const redirectIfUnauthorized = (res) => async (dispatch) => {
  if (_.get(res, ['data', 'msg']) === 'Unauthorized Request') {
    dispatch(logout());
  }
};

export const getSettings = () => async (dispatch) => {
  try {
    const res = await dispatch(
      axiosWithErrorHandling({
        method: 'get',
        url: 'api/profile/settings',
        authenticated: true
      })
    );
    const data = _.get(res, ['data', 'data']);
    dispatch(setSettings(data));
    dispatch(setExpensesYear(data.year.toString()));
    setSentryUser(data);
  } catch (e) {
    defaultCaptureException(e);
  }
};

/**
 * @desc Refresh user auth token
 */
export const getNewToken = () => async (dispatch) => {
  const res = await dispatch(
    axiosWithErrorHandling({
      method: 'post',
      url: 'api/auth/get-new-token',
      data: {},
      authenticated: true
    })
  );

  const token = _.get(res, ['data', 'data', 'token']);
  localStorage.setItem('KeeperToken', token);
  setAuthToken(token);

  const decoded = jwt_decode(token);
  dispatch(setCurrentUser(decoded));

  // set user for analytics
  await setUser(decoded);
  setLoggedInCookie(decoded);
  trackActivity('token refreshed');
};

export const parseImpersonationToken =
  ({ token }) =>
  async (dispatch) => {
    try {
      const res = await dispatch(
        axiosWithErrorHandling({
          method: 'post',
          url: 'api/auth/impersonation-signin',
          data: { token }
        })
      );
      const keeperToken = _.get(res, ['data', 'data', 'token']);
      localStorage.setItem('KeeperToken', keeperToken);
      window.location.replace('/');
    } catch (e) {
      dispatchAuthError('Unable to impersonate, please try again', dispatch);
    }
  };

export const deleteUser = () => async (dispatch) => {
  try {
    dispatch(setSettingsLoading(true));
    const token = localStorage.getItem('KeeperToken');

    const res = await axios.post(
      `${baseUrl}api/profile/delete-user`,
      {},
      {
        headers: {
          Authorization: token
        }
      }
    );

    if (_.get(res, ['data', 'status']) !== 'ok') {
      throw new Error('failed to delete account');
    }

    dispatch(logout());
  } catch (e) {
    defaultCaptureException(e);
  } finally {
    dispatch(setSettingsLoading(false));
  }
};
