import React from 'react';
import { useSelector } from 'react-redux';
import BankList from '@app/src/Components/Bank/BankList/BankList';
import PlaidSecurityNotice from '@app/src/Components/HolisticOnboarding/components/PlaidSecurityNotice/PlaidSecurityNotice';
import { bankListSelector } from '@app/src/selectors/bankSelectors';

const OnboardingBankLink = () => {
  const banklist = useSelector(bankListSelector);

  return (
    <>
      <BankList origin='onboarding' />
      {banklist.length === 0 && <PlaidSecurityNotice />}
    </>
  );
};

export default OnboardingBankLink;
