import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { PATH_COMPONENT__HOME } from '@app/src/taxflow/sections/special/constants/specialConstants';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';

export const useNavTaxHomeOnYearChange = () => {
  const year = useSelector(yearSelector);
  const history = useHistory();
  useUpdateEffect(() => {
    history.replace(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__HOME}`);
  }, [year]);
};
