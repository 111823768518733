import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
import CloseIcon from '@app/src/assets/close.svg?react';
import { selfUnlockModalOpenSelector } from '@app/src/selectors/navigationListSelectors';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { setSelfUnlockModalOpen } from '@app/src/taxflow/navigation/actions/navigationActions';

const TaxFlowPremiumSelfUnlockModal = ({ selfUnlockModalOpen, setSelfUnlockModalOpen, year }) => {
  return (
    <Dialog
      open={selfUnlockModalOpen}
      onClose={() => {
        setSelfUnlockModalOpen(false);
      }}
    >
      <DialogTitle sx={{ marginTop: 1.5, marginRight: 1.5, marginLeft: 1.5 }}>
        <div>{year} deductions locked for editing</div>
        <IconButton
          aria-label='close'
          onClick={() => {
            setSelfUnlockModalOpen(false);
          }}
          sx={{
            color: 'primary.main',
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingRight: 1.5, paddingLeft: 1.5 }}>
        <DialogContentText>
          If you need to make changes, contact your assigned tax pro to unlock your return
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  selfUnlockModalOpen: selfUnlockModalOpenSelector(state),
  year: yearSelector(state)
});

const mapDispatchToProps = {
  setSelfUnlockModalOpen
};

const ConnectedTaxFlowPremiumSelfUnlockModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxFlowPremiumSelfUnlockModal);

export default ConnectedTaxFlowPremiumSelfUnlockModal;
