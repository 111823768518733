import React from 'react';
import AskButton from '@app/src/Components/TaxProfile/AskButton';
import ControlledYearlyMoneyInput from '@app/src/Components/TaxProfile/ControlledYearlyMoneyInput';

const StudentTuitionPaidInput = () => {
  return (
    <ControlledYearlyMoneyInput
      name='studentTuitionPaid'
      label='Student tuition paid'
      textFieldProps={{
        InputProps: {
          endAdornment: (
            <AskButton
              field='studentTuitionPaid'
              message='What is student tuition paid?'
              response='This is the annual payment made towards student tuition, excluding any scholarships received. Keeper will calculate education tax credits using the amount you paid.'
              origin='Tax Profile Input'
            />
          )
        }
      }}
    />
  );
};

export default StudentTuitionPaidInput;
