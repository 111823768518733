import _ from 'lodash';
import axios from 'axios';
import { deleteTaxData, getTaxData, updateTaxData } from '@app/src/api/taxDataApi';
import { serverUrl } from '@app/src/global/Environment';
import { isReactNative, sentMsgToReactNative } from '@app/src/global/Helpers';
import { currentNavigationItemSelector } from '@app/src/selectors/navigationListSelectors';
import { collectionUploadStatusSelector } from '@app/src/selectors/taxFlowSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { setCurrentTaxState } from '@app/src/taxflow/collection/actions/collectionActions';
import {
  getFormUploadEnabledMap,
  getFormUploadNextPathComponentMap,
  getPrefillPathComponentMap
} from '@app/src/taxflow/collection/utils/collectionUtils';
import { getManualInputStarted } from '@app/src/taxflow/common/services/forms';
import { allCollectionTypesSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import {
  COLLECTION_TYPE__CREDIT_CHARITY,
  COLLECTION_TYPE__CREDIT_STANDARD,
  ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_CHARITY
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import { INCOME_COLLECTION_TYPES, INCOME_SLUGS } from '@app/src/taxflow/sections/income/incomeConstants';
import {
  COLLECTION_TYPE__DEPENDENT,
  COLLECTION_TYPE__SELF,
  ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import {
  COLLECTION_TYPE__STATE_RETURN,
  COLLECTION_TYPE__STATE_RESIDENCY,
  COLLECTION_TYPE__STATE_INCOME,
  ENDPOINT_ATTRIBUTE__STATE_RETURN,
  COLLECTION_TYPE__STATE_EXPENSES
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import { setQueryResults } from '@app/src/taxflow/shared/actions/sharedActions';
import { DEFAULT_COLLECTION_ID, STATE_TAX_COLL_TYPE_MAP } from '@app/src/taxflow/shared/constants/sharedConstants';
import { queryResultsSelector, currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { getFilingStatus } from '@app/src/taxflow/shared/utils/sharedUtils';

const baseUrl = serverUrl();

export const deleteCollection =
  ({ collectionType, collectionId }) =>
  async (dispatch, getState) => {
    const allCollectionTypes = allCollectionTypesSelector(getState());
    const queryResults = queryResultsSelector(getState());
    const year = yearSelector(getState());
    const currentCollTypeItem = allCollectionTypes.find((item) => item.collectionType === collectionType);
    const taxData = await dispatch(getTaxData({ year }));

    let unificationRecord;
    let unifiedCollIds = [];
    if (collectionType === INCOME_COLLECTION_TYPES.INVEST || collectionType === INCOME_COLLECTION_TYPES.COMPOSITE) {
      unificationRecord = taxData.find((queryResult) => {
        return queryResult.coll_id === collectionId && queryResult.slug === INCOME_SLUGS.INVEST_UNIFICATION;
      });
      unifiedCollIds = (_.get(unificationRecord, ['answer', 'value']) || []).filter(
        (collId) => collId !== Number(collectionId)
      );
      for (const collId of unifiedCollIds) {
        await Promise.all([
          dispatch(deleteTaxData({ coll_type: collectionType, coll_id: `${collId}`, year })),
          axios.delete(`${baseUrl}api/form-upload/ocr-results`, {
            data: {
              coll_type: collectionType,
              coll_id: `${collId}`,
              year
            }
          })
        ]);
      }
      const collIds = _.get(unificationRecord, ['answer', 'value']) || [Number(collectionId)];
      const newQueryResults = queryResults.filter((queryResult) => {
        return !(queryResult.coll_type === collectionType && collIds.includes(Number(queryResult.coll_id)));
      });
      dispatch(setQueryResults(newQueryResults));
    } else if (collectionType === COLLECTION_TYPE__STATE_RETURN) {
      // delete multistate collections
      await Promise.all([
        dispatch(
          deleteTaxData({ coll_type: COLLECTION_TYPE__STATE_RESIDENCY, year }),
          deleteTaxData({ coll_type: COLLECTION_TYPE__STATE_INCOME, year }),
          deleteTaxData({ coll_type: COLLECTION_TYPE__STATE_EXPENSES, year })
        )
      ]);

      // delete state return collection if last state return was deleted
      const stateReturns = _.filter(taxData, { slug: ENDPOINT_ATTRIBUTE__STATE_RETURN });
      if (stateReturns.length === 1) {
        await dispatch(deleteTaxData({ coll_type: COLLECTION_TYPE__STATE_RETURN }));
      }

      // delete individual state return collections
      const stateCode = _.chain(taxData)
        .find({
          slug: ENDPOINT_ATTRIBUTE__STATE_RETURN,
          coll_id: collectionId
        })
        .get('value')
        .value();
      dispatch(deleteStateReturn(stateCode));
    } else if (collectionType === COLLECTION_TYPE__CREDIT_CHARITY) {
      await dispatch(
        updateTaxData({
          taxData: [
            {
              coll_type: COLLECTION_TYPE__CREDIT_STANDARD,
              coll_id: collectionId,
              slug: ENDPOINT_ATTRIBUTE__CREDIT_STANDARD_CHARITY,
              value: '0'
            }
          ],
          generateSharedCollectionId: false,
          year
        })
      );
    }

    let collTypeStartedQuery;
    if (currentCollTypeItem && !currentCollTypeItem.required && !currentCollTypeItem.suppressed) {
      if (currentCollTypeItem.plural) {
        const collectionStartedSlug = currentCollTypeItem.slug;
        const hasOtherCollections = queryResults.some(
          (result) =>
            result.slug === collectionStartedSlug &&
            result.coll_id !== collectionId &&
            !unifiedCollIds.includes(Number(result.coll_id))
        );

        if (!hasOtherCollections) {
          const navSectionItem = currentNavigationItemSelector(getState());
          collTypeStartedQuery = _.get(
            _.find(
              _.get(navSectionItem, ['meta', 'collectionTypeStartedQueries']),
              (query) => query.coll_type === collectionType
            ),
            'slug'
          );
        }
      }
    }

    await Promise.all([
      dispatch(deleteTaxData({ coll_type: collectionType, coll_id: collectionId, year })),
      axios.delete(`${baseUrl}api/form-upload/ocr-results`, {
        data: {
          coll_type: collectionType,
          coll_id: collectionId,
          year
        }
      }),
      ...(collTypeStartedQuery
        ? [
            dispatch(
              deleteTaxData({
                coll_type: collectionType,
                coll_id: DEFAULT_COLLECTION_ID,
                slug: collTypeStartedQuery,
                year
              })
            )
          ]
        : [])
    ]);

    const filingStatus = getFilingStatus({
      maybeSelfTaxData: _.filter(taxData, { coll_type: COLLECTION_TYPE__SELF }),
      maybeDependentTaxData: _.filter(taxData, { coll_type: COLLECTION_TYPE__DEPENDENT })
    });
    if (!_.isNil(filingStatus)) {
      await dispatch(
        updateTaxData({
          taxData: [
            {
              coll_type: COLLECTION_TYPE__SELF,
              coll_id: '0',
              slug: ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS,
              value: filingStatus
            }
          ],
          generateSharedCollectionId: false,
          year
        })
      );
    }
  };

export const updateTaxState = (stateCode) => (dispatch) => {
  dispatch(setCurrentTaxState(stateCode));
};

const deleteStateReturn = (stateCode) => async (dispatch, getState) => {
  const year = yearSelector(getState());
  const stateTaxCollectionTypes = STATE_TAX_COLL_TYPE_MAP[stateCode];
  for (const collectionType of stateTaxCollectionTypes) {
    await dispatch(deleteTaxData({ coll_type: collectionType, year }));
  }
};

export const getNextPathComponentWithUpload =
  ({ pathComponent }) =>
  async (dispatch, getState) => {
    const currentQuestion = currentQuestionSelector(getState());
    if (!_.keys(getFormUploadNextPathComponentMap()).includes(currentQuestion.slug)) {
      return pathComponent;
    }

    const uploadStatus = collectionUploadStatusSelector(getState());
    if (!uploadStatus) {
      return pathComponent;
    }

    if (uploadStatus.status === 'pending') {
      return getPrefillPathComponentMap()[currentQuestion.slug];
    } else if (uploadStatus.status === 'success') {
      return getFormUploadNextPathComponentMap()[currentQuestion.slug];
    }
  };

export const getNextPathComponentWithSkip =
  ({ pathComponent, collectionType, collectionId, isCollectionUploaded }) =>
  async (dispatch) => {
    if (!_.keys(getFormUploadNextPathComponentMap()).includes(pathComponent)) {
      return pathComponent;
    }

    // If form upload not enabled or collection was uploaded already, don't prompt user to choose between manual entry and upload
    const formUploadEnabled = getFormUploadEnabledMap()[pathComponent];
    if (!formUploadEnabled || isCollectionUploaded) {
      return getFormUploadNextPathComponentMap()[pathComponent];
    }

    const manualInputStarted = await dispatch(
      getManualInputStarted({
        collectionType,
        collectionId
      })
    );
    if (manualInputStarted) {
      return getFormUploadNextPathComponentMap()[pathComponent];
    }

    // check if mobile
    if (isReactNative()) {
      sentMsgToReactNative('get_os');
    }
    return pathComponent;
  };

export const trackDeleteCollection = ({ collectionType, collectionId }) => {
  trackActivity('collection: removed', { collectionType, collectionId });
};
