import React, { useEffect } from 'react';
import _ from 'lodash';
import Icon from '@mui/material/Icon';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import TaxFlowPill from '@app/src/Components/TaxFlow/Common/TaxFlowPill';
import TaxFlowSubmitProgressTimer from '@app/src/Components/TaxFlow/Question/TaxFlowSubmitProgressTimer';
import {
  useGetReviewPillsQuery,
  useGetSubmitTimestampQuery,
  useGetTaxDataQuery,
  useGetUIStageQuery
} from '@app/src/api/taxDataApi';
import MailIcon from '@app/src/assets/mail.svg?react';
import PhoneIcon from '@app/src/assets/phone.svg?react';
import TickCircleIcon from '@app/src/assets/tick-circle.svg?react';
import { formatPhoneNumberToDisplay } from '@app/src/global/Helpers';
import { savingsSelector } from '@app/src/selectors/dashboardSelectors';
import { userSelector } from '@app/src/selectors/userSelectors';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { INCOME_COLLECTION_TYPES, INCOME_ENDPOINT_ATTRIBUTES } from '@app/src/taxflow/sections/income/incomeConstants';
import {
  COLLECTION_TYPE__DEPENDENT,
  ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import {
  COLLECTION_TYPE__STATE_RETURN,
  ENDPOINT_ATTRIBUTE__STATE_RETURN
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import { UI_STAGE__OPS_REVIEW, UI_STAGE__PURGATORY } from '@app/src/taxflow/shared/constants/sharedConstants';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { colorSuccess } from '@app/src/theme';
import '@app/src/Components/TaxFlow/Question/TaxFlowSubmitProgressElement.scss';

const TaxFlowSubmitProgressElement = ({ user, setLoading = _.noop }) => {
  const year = useSelector(yearSelector);
  const { data: uiStage, isLoading: uiStageLoading } = useGetUIStageQuery({ year });
  const { items: reviewItems, isLoading: reviewItemsLoading } = useInstantReviewItems();
  const { reviewPills: reviewPills, isLoading: reviewPillsLoading } = useGetReviewPillsQuery({ year });

  const isLoading = uiStageLoading || reviewItemsLoading || reviewPillsLoading;

  useEffect(() => setLoading(isLoading), [setLoading, isLoading]);

  if (isLoading) {
    return null;
  }

  return (
    <div className='taxflow-submit-progress'>
      <div className='taxflow-submit-progress-chips'>
        <TaxFlowPill
          text={user.email}
          icon={
            <Icon className='taxflow-chip-icon'>
              <MailIcon width={16} height={16} />
            </Icon>
          }
        />
        <TaxFlowPill
          text={formatPhoneNumberToDisplay(user.phone)}
          icon={
            <Icon className='taxflow-chip-icon'>
              <PhoneIcon width={16} height={16} />
            </Icon>
          }
        />
      </div>
      <div className='taxflow-submit-progress-panel'>
        <div className='taxflow-submit-progress-panel-title'>Checking your tax return</div>
        <TaxFlowSubmitProgressTimer />

        <hr></hr>

        <div className='taxflow-submit-progress-panel-list'>
          {reviewItems.map((item, i) => {
            let itemLabel = item.label;
            let animateSlugs = false;

            if (item.label === 'Form completion') {
              if (reviewPills && item.actualDuration > 2) {
                animateSlugs = true;
                itemLabel = 'Form completion - ';
              } else if (uiStage === UI_STAGE__PURGATORY) {
                itemLabel = 'Form completion - Done!';
              }
            }
            const reviewPillsText = _.chain(reviewPills).flatMap('pills').map('text').value();

            return (
              <div key={i} className='taxflow-submit-progress-panel-list-item'>
                <div
                  className='taxflow-submit-progress-panel-list-item-text'
                  style={{ animationDelay: item.actualDelay + 's' }}
                >
                  <div className='label'>{itemLabel}</div>
                  {animateSlugs && (
                    <div
                      className='slugs-container'
                      style={{
                        '--slugAnimationTranslate': -100 * (reviewPillsText.length - 1) + '%',
                        '--slugAnimationDuration': item.actualDuration + 's',
                        '--slugAnimationSteps': reviewPillsText.length - 1
                      }}
                    >
                      {reviewPillsText.map(({ text }) => (
                        <div className='slugs-container-item' key={text}>
                          {text}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div
                  className='taxflow-submit-progress-panel-list-item-checkmark'
                  style={{
                    animationDelay: animateSlugs ? item.actualDelay + item.actualDuration + 's' : item.actualDelay + 's'
                  }}
                >
                  <TickCircleIcon fill={colorSuccess} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const useInstantReviewItems = () => {
  const year = useSelector(yearSelector);

  const { data: uiStage, isLoading: isUIStageLoading } = useGetUIStageQuery({ year });
  const { data: taxData, isLoading: taxDataLoading } = useGetTaxDataQuery({ year });
  const dependentTaxData = _.filter(taxData, {
    coll_type: COLLECTION_TYPE__DEPENDENT
  });
  const investmentTaxData = _.filter(taxData, {
    coll_type: INCOME_COLLECTION_TYPES.INVEST
  });
  const stateTaxData = _.filter(taxData, {
    coll_type: COLLECTION_TYPE__STATE_RETURN
  });
  const { data: submitTimestamp, isLoading: isSubmitTimestampLoading } = useGetSubmitTimestampQuery({ year });
  const writeOffSavings = useSelector(savingsSelector);

  if (isUIStageLoading || taxDataLoading || isSubmitTimestampLoading) {
    return { items: [], isLoading: true };
  }

  // make items show up one by one with an animation delay on submit-bye
  // duration = duration in seconds for item to animate before next item appears
  const animate = uiStage === UI_STAGE__PURGATORY;

  // forms
  const items = [{ label: 'Form completion', duration: animate ? 4.5 : 0 }];

  // sched c
  if (writeOffSavings > 0) {
    items.push({ label: 'Schedule C deductions', duration: animate ? 7 : 0 });
  }

  // dependents
  const dependents = _.filter(dependentTaxData, {
    slug: ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME
  });
  if (dependents.length) {
    items.push({ label: 'Child tax credit', duration: animate ? 6 : 0 });
  }

  // filing status
  items.push({ label: 'Filing status', duration: animate ? 3 : 0 });

  // investments
  const investments = _.filter(investmentTaxData, {
    slug: INCOME_ENDPOINT_ATTRIBUTES.INVEST_DESCRIPTION
  });
  if (investments.length) {
    items.push({ label: 'Investments', duration: animate ? 7.5 : 0 });
  }

  // credits and deductions and state returns
  const states = _.filter(stateTaxData, {
    slug: ENDPOINT_ATTRIBUTE__STATE_RETURN
  });
  if (uiStage === UI_STAGE__PURGATORY) {
    items.push({ label: 'Credits and deductions', duration: 'infinite' });
  } else {
    items.push({ label: 'Credits and deductions', duration: 0 });

    for (const state of states) {
      if (_.get(state, ['answer', 'value'])) {
        items.push({ label: _.get(state, ['answer', 'value']) + ' return', duration: 0 });
      }
    }

    // only for manual review
    if (uiStage === UI_STAGE__OPS_REVIEW) {
      items.push({
        label: 'Tax expert assignment',
        duration: 'infinite'
      });
    }
  }

  // format array of items with css animation metadata
  // calculate delay and duration for each item based on previous item and submit timestamp
  // delay and duration are the preset delay and duration values for each item from above
  // actual delay and actual duration = preset values + adjustments based on current situation (e.g.
  // if user is viewing the screen for the first time or returning, if page is refreshed mid-animation)
  let i;
  const itemsWithDelay = [];
  for (i = 0; i < items.length; i++) {
    if (i === 0) {
      // if the page is refreshed, dont re-show previous animations
      let actualDuration = items[i].duration;
      const animationEnd = moment(submitTimestamp).add({ seconds: items[i].duration });
      if (animationEnd.isBefore()) {
        actualDuration = 0;
      }

      itemsWithDelay.push({
        label: items[i].label,
        delay: 0,
        actualDelay: 0,
        duration: items[i].duration,
        actualDuration
      });
    } else {
      // make each item appear when the last item is done
      const delay = itemsWithDelay[i - 1].duration + itemsWithDelay[i - 1].delay;

      // if the page is refreshed, dont re-show previous animations
      let actualDelay = itemsWithDelay[i - 1].actualDuration + itemsWithDelay[i - 1].actualDelay;
      let actualDuration = items[i].duration;
      const animationStart = moment(submitTimestamp).add({ seconds: delay });
      if (animationStart.isBefore()) {
        actualDelay = 0;

        if (actualDuration !== 'infinite') {
          const animationEnd = moment(submitTimestamp).add({ seconds: delay + actualDuration });
          actualDuration = animationEnd.isBefore() ? 0 : 1.5 * Math.ceil(animationEnd.diff(moment(), 'seconds') / 1.5);
        }
      }

      itemsWithDelay.push({
        label: items[i].label,
        delay,
        actualDelay,
        duration: items[i].duration,
        actualDuration
      });
    }
  }

  return { items: itemsWithDelay, isLoading: false };
};

const mapStateToProps = (state, props) => ({
  history: props.history,
  currentQuestion: currentQuestionSelector(state),
  user: userSelector(state)
});

const ConnectedTaxFlowSubmitProgressElement = connect(mapStateToProps)(TaxFlowSubmitProgressElement);

export default ConnectedTaxFlowSubmitProgressElement;
