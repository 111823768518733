import React, { ReactElement } from 'react';

interface IconWithFallbackProps {
  iconStyling?: React.CSSProperties;
  iconUrl?: string;
  fallbackIcon?: ReactElement;
  altText: string;
  filter?: string;
  size?: number;
}

const IconWithFallback = ({
  iconStyling,
  iconUrl,
  fallbackIcon,
  altText,
  filter,
  size = 24
}: IconWithFallbackProps): ReactElement | null => {
  if (!iconUrl && !fallbackIcon) return null;

  const iconSizeStyle = { height: `${size}px`, width: `${size}px` };

  if (iconUrl) {
    return <img src={iconUrl} alt={altText} style={{ ...iconSizeStyle, filter }} />;
  }

  return (
    <span style={{ display: 'inline-block', ...iconSizeStyle }} role='img' aria-label={altText}>
      {fallbackIcon &&
        React.cloneElement(fallbackIcon, {
          style: { ...iconStyling, ...iconSizeStyle, ...(fallbackIcon.props.style || {}) }
        })}
    </span>
  );
};

export default IconWithFallback;
