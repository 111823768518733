import React from 'react';
import _ from 'lodash';
import ControlledYearlyMoneyInput from '@app/src/Components/TaxProfile/ControlledYearlyMoneyInput';
import { useTaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';

const FreelanceIncomeInput = () => {
  const { jobType } = useTaxProfileFormContext();

  return (
    <ControlledYearlyMoneyInput name='taxpayer1099Income' label={`${_.capitalize(jobType)} income`} defaultValue={0} />
  );
};

export default FreelanceIncomeInput;
