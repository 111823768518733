import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { ArrowLeft } from 'iconsax-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PATHS_WITHOUT_FOOTER } from '@app/src/constants/onboardingConstants';
import { isReactNative, sentMsgToReactNative } from '@app/src/global/Helpers';
import { authLoadingSelector } from '@app/src/selectors/authSelectors';
import { bankLinkPendingSelector } from '@app/src/selectors/bankSelectors';
import {
  isEntryPointSelector,
  isPreviousSelector,
  loadingSelector,
  skippableSelector,
  validationSelector
} from '@app/src/selectors/holisticOnboardingSelectors';
import { onContinue } from '@app/src/services/holisticOnboardingService';

const OnboardingFooter = () => {
  const dispatch = useDispatch();

  const { push, goBack, location } = useHistory();
  const { pathname } = location;

  const onboardingLoading = useSelector(loadingSelector);
  const bankLinkLoading = useSelector(bankLinkPendingSelector);
  const skippable = useSelector((state) => skippableSelector(state, location));
  const isValid = useSelector((state) => validationSelector(state, location));
  const isEntryPoint = useSelector((state) => isEntryPointSelector(state, location));
  const isPrevious = useSelector((state) => isPreviousSelector(state, location));
  const authLoading = useSelector(authLoadingSelector);

  const loading = onboardingLoading || bankLinkLoading || authLoading;

  const handleContinue = () => {
    dispatch(onContinue({ pathname, push }));
  };

  const handleGoBack = () => {
    if (isReactNative() && isEntryPoint) {
      sentMsgToReactNative('leave_onboarding_webview');
    } else {
      goBack();
    }
  };

  return PATHS_WITHOUT_FOOTER.includes(pathname) ? null : (
    <div className='h-onboarding-footer'>
      <div className='h-onboarding-footer-content'>
        <Stack spacing={1} direction='row' justifyContent='flex-end'>
          {isPrevious && (
            <Button variant='outlined' color='primary' onClick={handleGoBack}>
              <ArrowLeft />
            </Button>
          )}
          <LoadingButton
            variant={skippable ? 'outlined' : 'contained'}
            color='primary'
            disabled={(!skippable && !isValid) || loading}
            disableRipple={skippable}
            loading={loading}
            onClick={handleContinue}
            sx={{ minWidth: '90px' }}
            disableElevation
          >
            {skippable ? 'Skip' : 'Next'}
          </LoadingButton>
        </Stack>
      </div>
    </div>
  );
};

export default OnboardingFooter;
