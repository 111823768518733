import React from 'react';
import AskButton from '@app/src/Components/TaxProfile/AskButton';
import ControlledYearlyMoneyInput from '@app/src/Components/TaxProfile/ControlledYearlyMoneyInput';
import { useTaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';

const QuarterlyTaxPaymentsInput = () => {
  const { year } = useTaxProfileFormContext();

  return (
    <ControlledYearlyMoneyInput
      name='taxpayerFederalQuarterlyTaxPayments'
      label='Quarterly tax payments'
      textFieldProps={{
        InputProps: {
          endAdornment: (
            <AskButton
              field='taxpayerFederalQuarterlyTaxPayments'
              message='What are quarterly tax payments?'
              response={`Quarterly taxes, or estimated tax payments, are payments due four times a year to help manage your tax burden and avoid underpayment penalties. If you made quarterly tax payments, enter the total amount paid in ${year} here.`}
              origin='Tax Profile Input'
            />
          )
        }
      }}
    />
  );
};

export default QuarterlyTaxPaymentsInput;
