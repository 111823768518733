import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useGetTaxAmountsQuery } from '@app/src/api/taxDataApi';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';

export const useDetailedAmounts = () => {
  const year = useSelector(yearSelector);

  const { data: amounts, isLoading: amountsLoading } = useGetTaxAmountsQuery({ year });

  const pdfAmounts = _.pick(amounts, [
    'adjusted_gross_income',
    'standard_or_itemized_deduction',
    'before_credits_tax',
    'other_tax',
    'business_income_deduction',
    'taxable_income',
    'non_refundable_credits',
    'total_tax_owed',
    'refundable_credits',
    'total_payments_made'
  ]);

  const drakeAmounts = _.pick(amounts, ['federal_amount', 'multistate']);

  return {
    amounts: { pdf: Object.values(pdfAmounts).every(_.isNil) ? null : pdfAmounts, drake: drakeAmounts },
    isLoading: amountsLoading
  };
};
