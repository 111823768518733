import React, { useState } from 'react';
import url from 'url';
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import TaxFlowPill from '@app/src/Components/TaxFlow/Common/TaxFlowPill';
import { useGetCarPillsQuery, useUpdateTaxDataMutation } from '@app/src/api/taxDataApi';
import history from '@app/src/keeperHistory';
import { isQuestionnaireFlowSelector } from '@app/src/selectors/taxFlowSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { getErrors } from '@app/src/services/taxValidationService';
import TaxFlowCollectionDeleteModal from '@app/src/taxflow/collection/components/TaxFlowCollectionDeleteModal';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { advanceToNextQuestionnaireQuestion } from '@app/src/taxflow/main/services/mainService';
import { getPathComponentBySlug } from '@app/src/taxflow/main/utils/mainUtils';
import { CAR_SLUGS, COLLECTION_TYPE__CAR } from '@app/src/taxflow/sections/car/carConstants';
import { getSlugMap } from '@app/src/taxflow/sections/car/carUtils';
import { DEFAULT_COLLECTION_ID, TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';

const TaxFlowCarPills = ({ refreshValidation, isQuestionnaireFlow, advanceToNextQuestionnaireQuestion }) => {
  const year = useSelector(yearSelector);

  const { data: carPills, isLoading: carPillsLoading } = useGetCarPillsQuery({ year });

  const [showCollectionDeleteModal, setShowCollectionDeleteModal] = useState(false);
  const [deleteModalTargetCollectionId, setDeleteModalTargetCollectionId] = useState();

  const [updateTaxData] = useUpdateTaxDataMutation();
  if (carPillsLoading) {
    return null;
  }

  const confirmPillDeletion = ({ collectionId, text }) => {
    trackActivity('car: pill removal requested', { collectionId, pillName: text });
    setShowCollectionDeleteModal(true);
    setDeleteModalTargetCollectionId(collectionId);
  };

  const onClickCarPill = async ({ startSlug, collectionId, text }) => {
    trackActivity('car: pill clicked', { collectionId, pillName: text });
    const nextPathComponent = getPathComponentBySlug(startSlug);
    if (_.isNil(nextPathComponent)) {
      defaultCaptureMessage('Could not advance to next car question', {
        coll_id: collectionId,
        slug: startSlug
      });
      return;
    }
    updateTaxData({
      taxData: [
        {
          coll_id: DEFAULT_COLLECTION_ID,
          coll_type: COLLECTION_TYPE__CAR,
          slug: CAR_SLUGS.NAV_START,
          value: '1'
        }
      ],
      generateSharedCollectionId: false,
      year
    });

    if (isQuestionnaireFlow) {
      const slugMap = getSlugMap();
      await advanceToNextQuestionnaireQuestion({
        nextSlug: slugMap[nextPathComponent],
        nextCollectionId: collectionId,
        questionnaireEarlyExit: false,
        history
      });
    } else {
      history.push(
        url.format({
          pathname: `/${TAXFLOW_BASE_URL}/${nextPathComponent}`,
          query: {
            collectionId: collectionId === '0' ? undefined : collectionId
          }
        })
      );
    }
  };

  const deriveVariant = (pill) => {
    if (pill.warning) {
      return 'warning';
    }
    if (pill.addable) {
      return 'add';
    }
    return 'default';
  };

  return carPills.map((pill, index) => {
    const { collectionId, text } = pill;
    return (
      <div key={index}>
        <TaxFlowPill
          text={pill.text}
          removable={pill.removable}
          variant={deriveVariant(pill)}
          onClick={() => onClickCarPill(pill)}
          onRemove={() => confirmPillDeletion({ collectionId, text })}
        />
        {showCollectionDeleteModal && (
          <TaxFlowCollectionDeleteModal
            collectionType={COLLECTION_TYPE__CAR}
            collectionId={deleteModalTargetCollectionId}
            hideModal={() => setShowCollectionDeleteModal(false)}
            onDelete={refreshValidation}
          />
        )}
      </div>
    );
  });
};

const mapStateToProps = (state) => {
  return {
    isQuestionnaireFlow: isQuestionnaireFlowSelector(state)
  };
};

const mapDispatchToProps = {
  refreshValidation: getErrors,
  advanceToNextQuestionnaireQuestion
};

const ConnectedTaxFlowCarPills = connect(mapStateToProps, mapDispatchToProps)(TaxFlowCarPills);

export default ConnectedTaxFlowCarPills;
