import React, { useEffect } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { connect, useSelector } from 'react-redux';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { useGetPaidQuery } from '@app/src/api/plaidApi';
import { useGetTaxDataQuery } from '@app/src/api/taxDataApi';
import { isReactNative } from '@app/src/global/Helpers';
import { trackActivity } from '@app/src/services/analyticsService';
import { getAllQuestionsLazy } from '@app/src/services/taxFlowContentfulService';
import {
  accountDetailsSelector,
  subscriptionPromptQuestionSelector,
  yearSelector
} from '@app/src/taxflow/main/selectors/mainSelectors';
import { ENDPOINT_ATTRIBUTE__SUBMIT_SUBSCRIPTION_PROMPT } from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { startSubscriptionNow, startSubscriptionLater } from '@app/src/taxflow/sections/submit/services/submitService';
import '@app/src/taxflow/navigation/components/TaxFlowSubscriptionPrompt.scss';

const TaxFlowSubscriptionPrompt = ({
  accountDetails,
  startSubscriptionLater,
  startSubscriptionNow,
  subscriptionPromptQuestion,
  getAllQuestionsLazy
}) => {
  const year = useSelector(yearSelector);

  // TODO - move this into rtk-query
  useEffect(() => {
    getAllQuestionsLazy();
  }, [getAllQuestionsLazy]);

  const onStartNow = async () => {
    await startSubscriptionNow();
  };

  const onStartLater = async () => {
    await startSubscriptionLater();
  };

  const onSpringStart = (event) => {
    if (event.type === 'OPEN') {
      trackActivity('navigation: subscription prompt');
    }
  };

  const { data: paid, isLoading: isPaidLoading } = useGetPaidQuery();
  const { data: taxData, isLoading: taxDataLoading } = useGetTaxDataQuery({
    year
  });
  const showPrompt =
    !isPaidLoading &&
    !taxDataLoading &&
    !paid &&
    !_.some(taxData, { slug: ENDPOINT_ATTRIBUTE__SUBMIT_SUBSCRIPTION_PROMPT }) &&
    !_.isNil(subscriptionPromptQuestion);

  return (
    <BottomSheet
      className='taxflow-subscription-prompt'
      open={showPrompt}
      onDismiss={onStartLater}
      onSpringStart={onSpringStart}
      expandOnContentDrag
    >
      <div className='taxflow-subscription-prompt-content'>
        <h1 className='taxflow-subscription-prompt-header'>{_.get(subscriptionPromptQuestion, 'title')}</h1>
        <p className='taxflow-subscription-prompt-body'>{_.get(subscriptionPromptQuestion, 'summary')}</p>
        <Stack spacing={1}>
          {isReactNative() && (
            <Button variant='contained' size='large' onClick={onStartNow}>
              {_.get(accountDetails, 'interval') === 'month' ? 'Switch to' : 'Start'} annual plan now
            </Button>
          )}
          <Button variant={isReactNative() ? 'outlined' : 'contained'} size='large' onClick={onStartLater}>
            Start my taxes, {_.get(accountDetails, 'interval') === 'month' ? 'switch' : 'pay'} later
          </Button>
        </Stack>
      </div>
    </BottomSheet>
  );
};

const mapStateToProps = (state) => ({
  subscriptionPromptQuestion: subscriptionPromptQuestionSelector(state),
  accountDetails: accountDetailsSelector(state)
});

const mapDispatchToProps = {
  startSubscriptionNow,
  startSubscriptionLater,
  getAllQuestionsLazy
};

const ConnectedTaxFlowSubscriptionPrompt = connect(mapStateToProps, mapDispatchToProps)(TaxFlowSubscriptionPrompt);

export default ConnectedTaxFlowSubscriptionPrompt;
