import React from 'react';
import Button from '@mui/material/Button';
import { colorFontDark, colorPrimary, colorPrimaryAlt3, colorSurface } from '@app/src/theme';

const ToggleButton = ({ onClick, selected, children, center, ...props }) => (
  <Button
    onClick={onClick}
    variant='passive'
    fullWidth
    sx={{
      justifyContent: center ? 'center' : 'flex-start',
      textTransform: 'none',
      textAlign: 'left',
      color: colorFontDark
    }}
    style={{
      backgroundColor: selected ? colorPrimaryAlt3 : colorSurface,
      borderColor: selected && colorPrimary
    }}
    {...props}
  >
    {children}
  </Button>
);

export default ToggleButton;
