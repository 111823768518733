import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import CategoryUsageSection from '@app/src/Components/TaxProfile/FormSections/CategoryUsageSection';
import CreditsAndDeductionsSection from '@app/src/Components/TaxProfile/FormSections/CreditsAndDeductionsSection';
import HouseholdDetailsSection from '@app/src/Components/TaxProfile/FormSections/HouseholdDetailsSection';
import IncomeSection from '@app/src/Components/TaxProfile/FormSections/IncomeSection';
import TaxesPaidSection from '@app/src/Components/TaxProfile/FormSections/TaxesPaidSection';
import TaxProfileFormProvider from '@app/src/Components/TaxProfile/TaxProfileFormProvider';
import { trackActivity } from '@app/src/services/analyticsService';

const TaxProfileForm = (props) => {
  useEffect(() => {
    trackActivity('navigation: tax profile', {
      year: props.year
    });
  }, [props.year]);

  return (
    <TaxProfileFormProvider {...props}>
      <TaxProfileFormBody year={props.year} />
    </TaxProfileFormProvider>
  );
};

export default TaxProfileForm;

const TaxProfileFormBody = ({ year }) => {
  const {
    formState: { isSubmitting }
  } = useFormContext();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '1.5',
        paddingBottom: 58,
        width: 450,
        cursor: isSubmitting ? 'progress' : undefined
      }}
    >
      <h2
        style={{
          margin: 0,
          fontSize: 24,
          fontWeight: 500
        }}
      >
        Tax profile ({year})
      </h2>
      <hr
        style={{
          marginTop: 0,
          marginBottom: 16,
          width: '100%'
        }}
      />
      <HouseholdDetailsSection />
      <IncomeSection />
      <CategoryUsageSection />
      <TaxesPaidSection />
      <CreditsAndDeductionsSection />
    </div>
  );
};
