import React from 'react';
import classNames from 'classnames';
import { BankLinkOptions } from '@app/src/Components/BankLinkOptions/BankLinkOptions';
import '@app/src/Components/ExpenseReview/ExpenseReviewExpensesNoResultsText.scss';

const ExpenseReviewExpensesNoResultsText = ({ className }) => {
  return (
    <div className={classNames('expense-review-expenses-no-results-container', className)}>
      <BankLinkOptions
        origin='expenseReviewNoResults'
        title={'No matching transactions found'}
        subTitle={`Link bank account(s) you use for work and we'll automatically scan your past purchases for eligible deductions`}
      />
    </div>
  );
};

export default ExpenseReviewExpensesNoResultsText;
