import React from 'react';
import _ from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import AskButton from '@app/src/Components/TaxProfile/AskButton';
import ControlledYearlyMoneyInput from '@app/src/Components/TaxProfile/ControlledYearlyMoneyInput';
import { useTaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';
import { taxProfileApi } from '@app/src/api/taxProfileApi';
import { STATE_TAXES_WITHHELD_RESPONSE } from '@app/src/constants/constants';

const StateTaxesWithheldInput = () => {
  const {
    getValues,
    formState: { isSubmitting }
  } = useFormContext();

  const { year } = useTaxProfileFormContext();

  const { data: taxEstimate, isSuccess: taxEstimateIsSuccess } =
    taxProfileApi.endpoints.calculateTaxEstimate.useQueryState({ year });

  const state = useWatch({ name: 'stateAbbreviation' });

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  if (_.isNil(state) || state === '') {
    return null;
  }

  return (
    <ControlledYearlyMoneyInput
      name='taxpayerW2StateIncomeTaxesWithheld'
      label='W-2 state income taxes withheld'
      textFieldProps={{
        ...(taxEstimateIsSuccess &&
          getValues('taxpayerW2StateIncomeTaxesWithheld') === null &&
          !isSubmitting &&
          taxEstimate.state?.totalTaxesWithheld && {
            placeholder: `${formatter.format(taxEstimate.state?.totalTaxesWithheld)} / yr`,
            sx: {
              input: {
                '&::placeholder': {
                  color: 'black'
                }
              }
            }
          }),
        InputProps: {
          endAdornment: (
            <AskButton
              field='taxpayerW2StateIncomeTaxesWithheld'
              message='What are W-2 state taxes withheld?'
              response={STATE_TAXES_WITHHELD_RESPONSE}
              origin='Tax Profile Input'
            />
          )
        }
      }}
    />
  );
};

export default StateTaxesWithheldInput;
