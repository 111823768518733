import { useEffect } from 'react';
import { useCalculateTaxEstimateQuery } from '@app/src/api/taxProfileApi';
import { trackActivity } from '@app/src/services/analyticsService';

const useTaxEstimate = (year) => {
  const result = useCalculateTaxEstimateQuery({
    year
  });

  useEffect(() => {
    if (!result.isSuccess) {
      return;
    }

    trackActivity('navigation: tax estimate', {
      new_est_tax_bill: result.data?.federal?.taxesOwed,
      est_state_tax_bill: result.data?.state?.taxesOwed ?? 0,
      year
    });
  }, [result.data, result.isSuccess, year]);

  return result;
};

export default useTaxEstimate;
