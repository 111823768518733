import {
  COLLECTION_TYPE__CAR,
  CAR_ENDPOINT_ATTRIBUTES,
  CAR_PATH_COMPONENTS,
  CAR_SLUGS
} from '@app/src/taxflow/sections/car/carConstants';
import { getQueryResultValueByEndpointAttribute } from '@app/src/taxflow/shared/utils/sharedUtils';

export const getNextPathComponentMap = () => {
  return {
    [CAR_SLUGS.BRAND]: { nextPathComponent: CAR_PATH_COMPONENTS.SERVICE_DATE, questionnaireEarlyExit: false },
    [CAR_SLUGS.PURCHASE_DATA]: { nextPathComponent: CAR_PATH_COMPONENTS.NEW_USED, questionnaireEarlyExit: false },
    [CAR_SLUGS.MILEAGE_SPECIFIC]: { nextPathComponent: CAR_PATH_COMPONENTS.EXIT_BOUNCE, questionnaireEarlyExit: false },
    [CAR_SLUGS.YEARS_DEPRECIATION]: {
      nextPathComponent: CAR_PATH_COMPONENTS.DEPRECIATION_AMOUNT_ESTIMATE,
      questionnaireEarlyExit: false
    },
    [CAR_SLUGS.DEPRECIATION_AMOUNT_ESTIMATE]: {
      nextPathComponent: CAR_PATH_COMPONENTS.DEPRECIATION_STRAIGHT_LINE,
      questionnaireEarlyExit: false
    },
    [CAR_SLUGS.DEPRECIATION_AMOUNT_SPECIFIC]: {
      nextPathComponent: CAR_PATH_COMPONENTS.DEPRECIATION_STRAIGHT_LINE,
      questionnaireEarlyExit: false
    },
    [CAR_SLUGS.DEPRECIATION_STRAIGHT_LINE]: {
      nextPathComponent: CAR_PATH_COMPONENTS.NAV_START,
      questionnaireEarlyExit: false
    },
    [CAR_SLUGS.SERVICE_DATE]: { nextPathComponent: CAR_PATH_COMPONENTS.OPTIONS, questionnaireEarlyExit: false },
    [CAR_SLUGS.OTHER_PERSONAL]: {
      nextPathComponent: CAR_PATH_COMPONENTS.WORK_MILES_TRACKED,
      questionnaireEarlyExit: false
    },
    [CAR_SLUGS.MILEAGE_ESTIMATION]: {
      nextPathComponent: CAR_PATH_COMPONENTS.EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [CAR_SLUGS.LEASE]: {
      nextPathComponent: CAR_PATH_COMPONENTS.NAV_START,
      questionnaireEarlyExit: false
    },
    [CAR_SLUGS.LOAN]: {
      nextPathComponent: CAR_PATH_COMPONENTS.PAST_DEPRECIATION,
      questionnaireEarlyExit: false
    }
  };
};

export const getOptionPathComponentMap = () => {
  return {
    [CAR_SLUGS.OPTIONS]: {
      car_lease: { nextPathComponent: CAR_PATH_COMPONENTS.LEASE, questionnaireEarlyExit: false },
      car_loan: { nextPathComponent: CAR_PATH_COMPONENTS.PURCHASE_DATA, questionnaireEarlyExit: false },
      own_car: { nextPathComponent: CAR_PATH_COMPONENTS.PURCHASE_DATA, questionnaireEarlyExit: false },
      other: { nextPathComponent: CAR_PATH_COMPONENTS.NAV_START, questionnaireEarlyExit: false }
    },
    [CAR_SLUGS.WORK_MILES_TRACKED]: {
      0: { nextPathComponent: CAR_PATH_COMPONENTS.MILEAGE_ESTIMATION, questionnaireEarlyExit: false },
      1: { nextPathComponent: CAR_PATH_COMPONENTS.MILEAGE_SPECIFIC, questionnaireEarlyExit: false }
    },
    [CAR_SLUGS.PAST_DEPRECIATION]: {
      0: { nextPathComponent: CAR_PATH_COMPONENTS.NAV_START, questionnaireEarlyExit: false },
      1: { nextPathComponent: CAR_PATH_COMPONENTS.PAST_DEPRECIATION_KNOWN, questionnaireEarlyExit: false },
      2: { nextPathComponent: CAR_PATH_COMPONENTS.NAV_START, questionnaireEarlyExit: false }
    },
    [CAR_SLUGS.PAST_DEPRECIATION_KNOWN]: {
      0: { nextPathComponent: CAR_PATH_COMPONENTS.YEARS_DEPRECIATION, questionnaireEarlyExit: false },
      1: { nextPathComponent: CAR_PATH_COMPONENTS.DEPRECIATION_AMOUNT_SPECIFIC, questionnaireEarlyExit: false }
    },
    [CAR_SLUGS.NAV_START]: {
      0: { nextPathComponent: CAR_PATH_COMPONENTS.EXIT_BOUNCE, questionnaireEarlyExit: false }
    }
  };
};

export const getNextPathComponent = ({ question, collectionId, queryResults }) => {
  let nextPathComponent = null;
  if (question.slug === CAR_SLUGS.NEW_USED) {
    const options = getQueryResultValueByEndpointAttribute({
      queryResults,
      collectionType: COLLECTION_TYPE__CAR,
      collectionId,
      slug: CAR_ENDPOINT_ATTRIBUTES.OPTIONS
    });
    if (options === 'own_car') {
      nextPathComponent = CAR_PATH_COMPONENTS.PAST_DEPRECIATION;
    } else if (options === 'car_loan') {
      nextPathComponent = CAR_PATH_COMPONENTS.LOAN;
    }
  } else if (question.slug === CAR_SLUGS.NAV_START) {
    nextPathComponent = CAR_PATH_COMPONENTS.OTHER_PERSONAL;
  } else if (question.slug === CAR_SLUGS.OPTIONS) {
    nextPathComponent = CAR_PATH_COMPONENTS.NAV_START;
  }
  return { nextPathComponent };
};

export const getSlugMap = () => {
  return {
    [CAR_PATH_COMPONENTS.BRAND]: CAR_SLUGS.BRAND,
    [CAR_PATH_COMPONENTS.DEPRECIATION_AMOUNT_SPECIFIC]: CAR_SLUGS.DEPRECIATION_AMOUNT_SPECIFIC,
    [CAR_PATH_COMPONENTS.DEPRECIATION_AMOUNT_ESTIMATE]: CAR_SLUGS.DEPRECIATION_AMOUNT_ESTIMATE,
    [CAR_PATH_COMPONENTS.DEPRECIATION_STRAIGHT_LINE]: CAR_SLUGS.DEPRECIATION_STRAIGHT_LINE,
    [CAR_PATH_COMPONENTS.EXIT_BOUNCE]: CAR_SLUGS.EXIT_BOUNCE,
    [CAR_PATH_COMPONENTS.PAST_DEPRECIATION_KNOWN]: CAR_SLUGS.PAST_DEPRECIATION_KNOWN,
    [CAR_PATH_COMPONENTS.LEASE]: CAR_SLUGS.LEASE,
    [CAR_PATH_COMPONENTS.LOAN]: CAR_SLUGS.LOAN,
    [CAR_PATH_COMPONENTS.MILEAGE_ESTIMATION]: CAR_SLUGS.MILEAGE_ESTIMATION,
    [CAR_PATH_COMPONENTS.MILEAGE_SPECIFIC]: CAR_SLUGS.MILEAGE_SPECIFIC,
    [CAR_PATH_COMPONENTS.NEW_USED]: CAR_SLUGS.NEW_USED,
    [CAR_PATH_COMPONENTS.NAV_START]: CAR_SLUGS.NAV_START,
    [CAR_PATH_COMPONENTS.OPTIONS]: CAR_SLUGS.OPTIONS,
    [CAR_PATH_COMPONENTS.OTHER_PERSONAL]: CAR_SLUGS.OTHER_PERSONAL,
    [CAR_PATH_COMPONENTS.PAST_DEPRECIATION]: CAR_SLUGS.PAST_DEPRECIATION,
    [CAR_PATH_COMPONENTS.PURCHASE_DATA]: CAR_SLUGS.PURCHASE_DATA,
    [CAR_PATH_COMPONENTS.SERVICE_DATE]: CAR_SLUGS.SERVICE_DATE,
    [CAR_PATH_COMPONENTS.WORK_MILES_TRACKED]: CAR_SLUGS.WORK_MILES_TRACKED,
    [CAR_PATH_COMPONENTS.YEARS_DEPRECIATION]: CAR_SLUGS.YEARS_DEPRECIATION
  };
};

// This is used for the questionnaire to set the next collectionId. If the next path component
// is the NAV_START, always set the collectionId to 0
export const getNextQuery = ({ nextPathComponent }) => {
  if (nextPathComponent === CAR_PATH_COMPONENTS.NAV_START) {
    return {
      collectionId: '0'
    };
  }
  return {};
};
