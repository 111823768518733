import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { usePreviousDistinct } from 'react-use';
import { useCreateTaxProfileMutation, useLazyGetTaxProfileQuery } from '@app/src/api/taxProfileApi';
import { expensesYearSelector } from '@app/src/selectors/expenseReviewSelectors';

const useGetOrCreateTaxProfileQuery = () => {
  const year = useSelector(expensesYearSelector);
  const [trigger, result] = useLazyGetTaxProfileQuery();
  const lastValidResult = usePreviousDistinct(result, (_prev, next) => next.isError);
  const [createTaxProfile, createTaxProfileResult] = useCreateTaxProfileMutation(year);

  useEffect(() => {
    const getOrCreateTaxProfile = async () => {
      const currentResult = await trigger(year);

      if (currentResult.error?.status === 404) {
        await createTaxProfile(year);
      }
    };

    getOrCreateTaxProfile();
  }, [createTaxProfile, trigger, year]);

  if (createTaxProfileResult.isLoading) {
    return lastValidResult;
  }

  return result;
};

export default useGetOrCreateTaxProfileQuery;
