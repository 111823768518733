import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ICON_URLS } from '@app/src/constants/constants';
import { useIsPremiumSubscriber } from '@app/src/hooks/pricingHooks';
import { setBlockingModalProps } from '@app/src/taxflow/navigation/actions/navigationActions';

/** Returns a function surfacing a modal communicating that a user either needs premium or that the current situation is supported by their premium plan */
export const useShowPremiumBlockingModal = () => {
  const dispatch = useDispatch();
  const { data: isPremiumSubscriber, isLoading: isPremiumSubscriberLoading } = useIsPremiumSubscriber();

  const showPremiumModal = useCallback(
    ({ formName }) => {
      if (isPremiumSubscriberLoading) {
        return null;
      }
      const premiumModalTitle = isPremiumSubscriber
        ? `Your premium plan covers ${formName}`
        : `Premium plan required for ${formName}`;
      const premiumModalBody = isPremiumSubscriber
        ? "Your premium plan allows you to file your taxes with hundreds of complex situations and a dedicated tax pro! We'll pair you with a tax professional to get your return perfect before sending it to the IRS."
        : `Keeper's premium plan allows you to file your taxes with hundreds of complex situations and a dedicated tax pro! We'll pair you with a tax professional to get your return perfect before sending it to the IRS.`;
      dispatch(
        setBlockingModalProps({
          open: true,
          slug: `${formName}-blocking-modal`,
          title: premiumModalTitle,
          body: premiumModalBody,
          iconUrl: ICON_URLS.MAGIC_STAR
        })
      );
    },
    [isPremiumSubscriber, isPremiumSubscriberLoading, dispatch]
  );

  return showPremiumModal;
};
