import React from 'react';
import ExpenseSlider from '@app/src/Components/TaxProfile/ExpenseSlider';
import { useTaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';

const CarExpenseSlider = () => {
  const { jobType } = useTaxProfileFormContext();

  return (
    <ExpenseSlider
      name='deductibleCarExpensePercentage'
      label={`Percent of driving for ${jobType}`}
      enabledField='deductibleCarExpensesEnabled'
    />
  );
};

export default CarExpenseSlider;
