import React from 'react';
import ExpenseSlider from '@app/src/Components/TaxProfile/ExpenseSlider';
import { useTaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';

const PublicTransportSlider = () => {
  const { jobType } = useTaxProfileFormContext();

  return (
    <ExpenseSlider
      name='deductiblePublicTransportPercentage'
      label={`Percent of public transport for ${jobType}`}
      enabledField='deductiblePublicTransportEnabled'
    />
  );
};

export default PublicTransportSlider;
