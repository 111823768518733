import React from 'react';
import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import { useTaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';
import TaxProfileSelect from '@app/src/Components/TaxProfile/TaxProfileSelect';
import { STATES } from '@app/src/taxflow/shared/constants/sharedConstants';

const options = STATES.map(({ code, name }) => {
  return {
    value: code,
    label: name
  };
});

const StateInput = () => {
  const { handleSubmit } = useFormContext();

  const { onSubmit, isLoading } = useTaxProfileFormContext();

  return (
    <Controller
      name='stateAbbreviation'
      render={({ field, fieldState: { error } }) => (
        <TaxProfileSelect
          {...field}
          disabled={isLoading}
          id='state'
          label='State'
          error={error}
          placeholder='Select a state'
          value={_.isNil(field.value) ? '' : field.value}
          onChange={(event) => {
            field.onChange(event);

            handleSubmit(onSubmit(field.name))();
          }}
          options={options}
        />
      )}
    />
  );
};

export default StateInput;
