import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import TaxFlowConfirmModal from '@app/src/Components/TaxFlow/Common/TaxFlowConfirmModal';
import { getIsTaxFilingSandwiched, isReactNative, sentMsgToReactNative } from '@app/src/global/Helpers';
import { useIsMobileWidth } from '@app/src/hooks/useIsMobileWidth';
import history from '@app/src/keeperHistory';
import { trackActivity } from '@app/src/services/analyticsService';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { PATH_COMPONENT__EXPENSES } from '@app/src/taxflow/shared/constants/sharedConstants';
import '@app/src/Components/TaxFlow/Question/TaxFlowExpensesLinkItem.scss';

const TaxFlowEditExpensesNavigator = ({ active = false, expenseCategoryIds, setActive }) => {
  const year = useSelector(yearSelector);
  const isMobile = useIsMobileWidth();
  const isFilingSandwiched = getIsTaxFilingSandwiched();

  const navigateNativeDeductionsTab = useCallback(() => {
    sentMsgToReactNative('home', { year, categoryIds: expenseCategoryIds });
  }, [expenseCategoryIds, year]);

  // Bypass modal when sandwiched - instead of awaiting user confirmation, route to the native deductions tab
  useEffect(() => {
    if (isFilingSandwiched && active) {
      navigateNativeDeductionsTab();
      setActive(false);
    }
  }, [isFilingSandwiched, active, navigateNativeDeductionsTab, setActive]);

  const handleConfirm = async () => {
    trackActivity('modal: clicked', {
      modal: 'leave tax filing to edit expenses'
    });
    // case: in-app
    if (isReactNative()) {
      navigateNativeDeductionsTab();
    }
    // case: mobile web
    else if (isMobile) {
      window.open('https://keeper.tax/get-the-app', '_blank');
    }
    // case: desktop
    else {
      const params = new URLSearchParams();
      _.forEach(expenseCategoryIds, (categoryId) => {
        params.append('category', categoryId);
      });
      params.append('year', year);
      history.push(`/${PATH_COMPONENT__EXPENSES}?${params}`);
    }
  };

  if (isFilingSandwiched) {
    return null;
  }

  return (
    <TaxFlowConfirmModal
      open={active}
      setOpen={setActive}
      header='Leave tax filing to edit expenses?'
      body='You can edit your expenses on the deductions tab and come back to finish filing.'
      // non breaking hyphen
      confirmText='Yes, edit my expenses'
      onConfirm={handleConfirm}
    />
  );
};

export default TaxFlowEditExpensesNavigator;
