import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import JobSelect from '@app/src/Components/Common/JobSelect/JobSelect';
import { setHolisticOnboardingJob } from '@app/src/actions/holisticOnboardingActions';
import { jobsSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { jobCategoriesSelector, topJobCategoriesSelector } from '@app/src/selectors/onboardingSelectors';

const OnboardingJobSelect = () => {
  const dispatch = useDispatch();
  const allJobs = useSelector(jobCategoriesSelector);
  const selectedJobs = useSelector(jobsSelector);
  const topJobCategories = useSelector(topJobCategoriesSelector);

  const handleSetSelectedJobs = (newSelectedJobs) => {
    dispatch(setHolisticOnboardingJob(newSelectedJobs));
  };

  return (
    <JobSelect
      selectedJobs={selectedJobs}
      setSelectedJobs={handleSetSelectedJobs}
      origin='holistic onboarding'
      allJobs={allJobs}
      topJobCategories={topJobCategories}
    />
  );
};

export default OnboardingJobSelect;
