import React from 'react';
import { useFormContext } from 'react-hook-form';
import AskButton from '@app/src/Components/TaxProfile/AskButton';
import ControlledYearlyMoneyInput from '@app/src/Components/TaxProfile/ControlledYearlyMoneyInput';
import { STATE_TAXES_WITHHELD_RESPONSE } from '@app/src/constants/constants';

const SpouseStateTaxesWithheldInput = () => {
  const { watch } = useFormContext();

  const filingStatus = watch('filingStatus');
  const state = watch('stateAbbreviation');

  if (filingStatus !== 'married' || state === '') {
    return null;
  }

  return (
    <ControlledYearlyMoneyInput
      name='spouseW2StateIncomeTaxesWithheld'
      label="Spouse's W-2 state income taxes withheld"
      textFieldProps={{
        InputProps: {
          endAdornment: (
            <AskButton
              field='spouseW2StateIncomeTaxesWithheld'
              message='What are W-2 state taxes withheld?'
              response={STATE_TAXES_WITHHELD_RESPONSE}
              origin='Tax Profile Input'
            />
          )
        }
      }}
    />
  );
};

export default SpouseStateTaxesWithheldInput;
