import { useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useInterval } from 'react-use';
import { TAGS } from '@app/src/api/baseApi';
import taxDataApi, { useLazyGetUIStageQuery } from '@app/src/api/taxDataApi';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';

/**
 * Hook refetching all api data given a change in ui stage
 */
export const useUpdatePageOnUIStageChange = ({ uiStagePollingIntervalMs }) => {
  const year = useSelector(yearSelector);
  const [getUIStage] = useLazyGetUIStageQuery();
  const [previousUIStage, setPreviousUIStage] = useState(null);
  const [previousYear, setPreviousYear] = useState(year);
  const dispatch = useDispatch();

  useInterval(async () => {
    const { data: uiStage } = await getUIStage({ year });

    if (!_.isNil(previousUIStage) && uiStage !== previousUIStage && year === previousYear) {
      dispatch(taxDataApi.util.invalidateTags(_.values(TAGS)));
    }

    setPreviousUIStage(uiStage);
    setPreviousYear(year);
  }, uiStagePollingIntervalMs);
};
