import { useEffect, useState } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useSelector } from 'react-redux';
import useEvent from 'react-use-event-hook';
import { REACT_APP_ENV } from '@app/src/global/Environment';
import { isReactNative } from '@app/src/global/Helpers';
import { initDatadogRum } from '@app/src/services/analyticsService';
import { isWebviewFocusedSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';

/** Record user sessions with datadog RUM */
export const useRecordUserSessions = () => {
  const isNativeWebviewFocused = useSelector(isWebviewFocusedSelector);
  const shouldRecordSession = (!isReactNative() || isNativeWebviewFocused) && REACT_APP_ENV === 'production';
  const [isRUMInitialized, setIsRUMInitialized] = useState(false);

  const startRecording = useEvent(() => {
    if (!isRUMInitialized) {
      initDatadogRum();
      setIsRUMInitialized(true);
    }
    datadogRum.startSessionReplayRecording();
  });

  useEffect(() => {
    if (shouldRecordSession) {
      startRecording();
    } else {
      datadogRum.stopSessionReplayRecording();
    }
  }, [shouldRecordSession, startRecording]);
};
