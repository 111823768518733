import React, { useMemo } from 'react';
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import AskLink from '@app/src/Components/Common/AskLink/AskLink';
import { isReactNative, replaceString, sentMsgToReactNative } from '@app/src/global/Helpers';
import { trackActivity } from '@app/src/services/analyticsService';
import { requestInfoResponseMessage, openInfoComponent } from '@app/src/services/assistantService';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import '@app/src/Components/TaxFlow/Common/TaxFlowLearnMoreElement.scss';

const TaxFlowLearnMoreElement = ({
  question,
  slug,
  type,
  buttonText,
  modalTitle,
  description,
  url,
  contentType,
  image,
  accordionInfo,
  disableChatMessaging,
  openInfoComponent,
  requestInfoResponseMessage
}) => {
  const year = useSelector(yearSelector);
  const formattedDescription = useMemo(
    () =>
      replaceString(description, {
        taxFilingYear: year
      }),
    [description, year]
  );

  const hasDescription = useMemo(() => _.isString(description) && !_.isEmpty(description), [description]);

  const onOpenInfo = async (e) => {
    e.preventDefault();

    trackActivity('question: more info clicked', {
      flow: question.flow,
      question: question.slug,
      title: question.title,
      info: slug,
      hasDescription,
      disableChatMessaging
    });

    if (type === 'link') {
      if (isReactNative()) {
        sentMsgToReactNative('open_learn_more_link', {
          url
        });
      } else {
        window.open(url, '_blank');
      }
    } else if (disableChatMessaging) {
      openInfoComponent({
        title: modalTitle,
        source: formattedDescription,
        contentType,
        image,
        accordionInfo,
        type,
        origin: 'taxFlowLearnMoreElement',
        slug
      });
    } else {
      await requestInfoResponseMessage({
        message: modalTitle,
        response: formattedDescription,
        contentType,
        image,
        accordionInfo,
        type,
        origin: 'tax filing learn more button clicked',
        slug
      });
    }
  };

  if (!slug) {
    return null;
  }

  return (
    <AskLink onClick={onOpenInfo} styles={{ marginTop: '16px' }}>
      {buttonText || 'Learn more'}
    </AskLink>
  );
};

const mapDispatchToProps = {
  openInfoComponent,
  requestInfoResponseMessage
};

const ConnectedTaxFlowLearnMoreElement = connect(null, mapDispatchToProps)(TaxFlowLearnMoreElement);

export default ConnectedTaxFlowLearnMoreElement;
