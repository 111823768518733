import React from 'react';
import { useSelector } from 'react-redux';
import ExpenseUploadLinkPrompt from '@app/src/Components/Common/ExpenseUpload/modals/ExpenseUploadLinkPrompt';
import ChurnExplanation from '@app/src/Components/Settings/Modals/Churn/ChurnExplanation';
import ChurnInitial from '@app/src/Components/Settings/Modals/Churn/ChurnInitial';
import DeleteAccountModal from '@app/src/Components/Settings/Modals/DeleteAccountModal';
import SubscriptionFlow from '@app/src/Components/Subscription/SubscriptionFlow';
import { settingsModalTypeSelector } from '@app/src/selectors/settingsSelectors';

const SettingsModalContent = () => {
  const modalType = useSelector(settingsModalTypeSelector);

  switch (modalType) {
    case 'edit_plan':
      return <SubscriptionFlow initialScreen='subscriptionType' />;
    case 'edit_payment':
      return <SubscriptionFlow initialScreen='pricingMethod' />;
    case 'churn-initial':
      return <ChurnInitial />;
    case 'churn-explanation':
      return <ChurnExplanation />;
    case 'delete-account':
      return <DeleteAccountModal />;
    case 'expense-upload-link-prompt':
      return <ExpenseUploadLinkPrompt />;
    default:
      return null;
  }
};

export default SettingsModalContent;
