import _ from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import { DEFAULT_WORK } from '@app/src/constants/constants';
import { Url_ONBOARDING_DURATION_YEAR, Url_ONBOARDING_DURATION_MONTH } from '@app/src/constants/onboardingConstants';
import { getCurrentYear, getFormNamePreface, getPreviousYear } from '@app/src/global/Helpers';
import { jobCategoriesSelector } from '@app/src/selectors/onboardingSelectors';
import { incomeFreelanceFormSelector } from '@app/src/selectors/taxFlowSelectors';
import { annualPriceSelector, premiumPriceSelector } from '@app/src/selectors/userSelectors';
import { workDetailsSelector } from '@app/src/selectors/workSelectors';
import {
  isPremiumSubscriberSelector,
  submitWarningsSelector,
  uiStageSelector
} from '@app/src/taxflow/main/selectors/mainSelectors';
import { uncheckedSubmitIssueMessagesSelector } from '@app/src/taxflow/sections/submit/selectors/submitSelectors';
import { UI_STAGE__REJECTED_ESC, UI_STAGE__USER_ESC } from '@app/src/taxflow/shared/constants/sharedConstants';
import { getIndefiniteArticle } from '@app/src/utils/expenseUtils';

const getSubmitText = ({ uiStage, hasSubmitIssues, hasSubmitWarnings }) => {
  if (hasSubmitIssues && uiStage === UI_STAGE__USER_ESC) {
    return {
      title: 'We found an issue with your return',
      description: 'Please resolve any issues before submitting your return for review.'
    };
  }
  if (hasSubmitIssues && uiStage === UI_STAGE__REJECTED_ESC) {
    return {
      title: 'Your return was rejected by the IRS',
      description: 'Please resolve any issues before resubmitting.'
    };
  }
  if (hasSubmitWarnings) {
    return {
      title: 'Audit risks found',
      description: 'Please resolve audit risks before submitting your return for review.'
    };
  }
  return { title: `Let's review your details`, description: '' };
};

export const replaceStrings = (str) => (dispatch, getState) => {
  const taxStatus = _.get(getState(), ['taxFlow', 'cache', 'taxStatus'], '');
  const work = _.get(getState(), ['taxFlow', 'cache', 'work'], DEFAULT_WORK);
  const jobIndex = _.get(getState(), ['taxFlow', 'currentContext', 'jobIndex'], 0);
  const jobName = _.get(getState(), ['taxFlow', 'currentContext', 'jobNames', jobIndex], '');
  const jobCategories = jobCategoriesSelector(getState());
  const job = jobCategories.find((j) => j.slug === jobName);
  const jobDurationYear =
    _.get(getState(), ['taxFlow', 'currentAnswer', 'value', Url_ONBOARDING_DURATION_YEAR, 'value']) === '2019'
      ? '2019'
      : '2020';
  const jobDurationMonth = _.get(getState(), [
    'taxFlow',
    'currentAnswer',
    'value',
    Url_ONBOARDING_DURATION_MONTH,
    'value'
  ]);
  const annualPrice = annualPriceSelector(getState());
  const premiumPrice = premiumPriceSelector(getState());
  const formType = incomeFreelanceFormSelector(getState());

  var monthName = moment.months(jobDurationMonth - 1);
  const date = monthName + ' ' + jobDurationYear;
  const otherJobMatch = /^other:(.*)$/.exec(jobName);

  let jobAsVerb = DEFAULT_WORK;
  if (jobCategories.length === 1) {
    jobAsVerb = otherJobMatch
      ? `${DEFAULT_WORK} as ${getIndefiniteArticle(otherJobMatch[1])} ${otherJobMatch[1]}`
      : _.get(job, 'jobAsVerb', DEFAULT_WORK);
  }

  const workDetails = workDetailsSelector(getState());

  const dependents = _.get(getState(), ['taxFlow', 'currentContext', 'dependents'], 0);
  const dependentName = _.get(getState(), ['taxFlow', 'currentContext', 'dependentName'], 'the dependent');
  const currentYear = getCurrentYear();
  const previousYear = getPreviousYear();

  const isPremiumSubscriber = isPremiumSubscriberSelector(getState());
  const uiStage = uiStageSelector(getState());
  const hasSubmitWarnings = !_.isEmpty(submitWarningsSelector(getState()));
  const hasSubmitIssues = !_.isEmpty(uncheckedSubmitIssueMessagesSelector(getState()));

  let newStr = str;
  if (newStr) {
    newStr = newStr.replace('%s', !_.isEmpty(taxStatus) && taxStatus === 'married' ? '(or your spouse)' : '');
    newStr = newStr.replace('{work}', work);
    newStr = newStr.replace('{date}', date);
    newStr = newStr.replace('{jobSpecificWork}', jobAsVerb);
    newStr = newStr.replace('{firstname}', _.get(workDetails, 'firstname'));
    newStr = newStr.replace('{X} dependents', pluralize('dependent', dependents, true));
    newStr = newStr.replace('{name}', dependentName);
    newStr = newStr.replace('{pricing}', isPremiumSubscriber ? premiumPrice : annualPrice);
    newStr = newStr.replace('{freelanceIncomeForm}', getFormNamePreface(formType) || 'income');
    newStr = newStr.replace('{currentYear}', currentYear);
    newStr = newStr.replace('{previousYear}', previousYear);
    newStr = newStr.replace('{previousYear}', previousYear);
    newStr = newStr.replace('{submitTitle}', getSubmitText({ hasSubmitWarnings, hasSubmitIssues, uiStage }).title);
    newStr = newStr.replace(
      '{submitDescription}',
      getSubmitText({ hasSubmitWarnings, hasSubmitIssues, uiStage }).description
    );
  }

  return newStr;
};
