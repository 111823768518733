import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import LoginOtp from '@app/src/Components/Login/LoginWithOTP/LoginOtp';
import LoginResend from '@app/src/Components/Login/LoginWithOTP/LoginResend';
import { emailSelector, phoneSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { notify } from '@app/src/utils/snackbarUtils';

const OnboardingVerify = () => {
  const [otp, setOtp] = useState('');

  const phone = useSelector(phoneSelector);
  const email = useSelector(emailSelector);

  const type = phone ? 'phone' : 'email';
  const id = phone || email;

  const handleSuccess = () => {
    notify("You've successfully verified your account!");
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <LoginOtp type={type} id={id} otp={otp} onOtpChange={setOtp} onSuccess={handleSuccess} />
      <LoginResend type={type} id={id} onResendSuccess={() => setOtp('')} />
    </div>
  );
};

export default OnboardingVerify;
