import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { ArrowDown2 } from 'iconsax-react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setHolisticOnboardingStartDate } from '@app/src/actions/holisticOnboardingActions';
import { jobDurationSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { onContinue } from '@app/src/services/holisticOnboardingService';
import { colorNeutralGraphite } from '@app/src/theme';
import { getMonthsToStartOfLastYear } from '@app/src/utils/holisticOnboardingUtils';

const OnboardingJobDuration = () => {
  const dispatch = useDispatch();

  const {
    location: { pathname },
    push
  } = useHistory();

  const selectedDates = useSelector(jobDurationSelector);

  const handleSelect = ({ target: { value } }) => {
    dispatch(setHolisticOnboardingStartDate(value));

    if (value === '0') {
      setTimeout(() => {
        dispatch(onContinue({ push, pathname }));
      }, 1000);
    }
  };

  const lastYearStart = moment().subtract(1, 'years').format('YYYY');

  return (
    <Stack spacing={1}>
      <Select
        id='onboarding-duration'
        onChange={handleSelect}
        IconComponent={() => <ArrowDown2 />}
        defaultValue=''
        fullWidth
        style={{ color: selectedDates ? 'inherit' : colorNeutralGraphite }}
        displayEmpty
        value={selectedDates}
      >
        <MenuItem value='' disabled>
          Select a month
        </MenuItem>
        {getMonthsToStartOfLastYear().map((text, idx) => (
          <MenuItem key={idx} value={text}>
            {text}
          </MenuItem>
        ))}
        <MenuItem value={`2023 - January`}>Before {lastYearStart}</MenuItem>
      </Select>
    </Stack>
  );
};

export default OnboardingJobDuration;
