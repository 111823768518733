import React from 'react';
import ExpenseSlider from '@app/src/Components/TaxProfile/ExpenseSlider';
import { useTaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';

const PhoneSlider = () => {
  const { jobType } = useTaxProfileFormContext();

  return <ExpenseSlider name='deductiblePhoneExpensePercentage' label={`Percent of phone usage for ${jobType}`} />;
};

export default PhoneSlider;
