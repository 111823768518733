import { useCallback } from 'react';

const useTaxEstimateDetails = ({ year, setShowForm, taxEstimateResults }) => {
  const navigateToForm = useCallback(() => {
    setShowForm(true);
  }, [setShowForm]);

  return {
    year,
    navigateToForm,
    isLoading: taxEstimateResults.isLoading || taxEstimateResults.isUninitialized || taxEstimateResults.error,
    taxEstimate: taxEstimateResults.data
  };
};

export default useTaxEstimateDetails;
