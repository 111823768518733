import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useDeepCompareEffect, usePrevious } from 'react-use';
import {
  useGetBulkUploadAlertQuery,
  useGetBulkUploadPillsQuery,
  useGetBulkUploadSuggestionPillsQuery,
  useGetReviewPillsQuery,
  useGetSubmitIssuesQuery,
  useGetSubmitWarningsQuery,
  useGetUIStageQuery
} from '@app/src/api/taxDataApi';
import { isReactNative } from '@app/src/global/Helpers';
import { useHasTransactions } from '@app/src/hooks/useHasDeductions';
import { useUIState } from '@app/src/hooks/useUIState';
import { trackActivity } from '@app/src/services/analyticsService';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { isWebviewFocusedSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';

export const useHomeAnalytics = ({ isLoading }) => {
  const year = useSelector(yearSelector);

  const { uiState, isLoading: uiStateLoading } = useUIState();
  const { data: uiStage } = useGetUIStageQuery({ year });
  const { data: bulkUploadPills } = useGetBulkUploadPillsQuery({ year });
  const { data: bulkUploadSuggestionPills } = useGetBulkUploadSuggestionPillsQuery({ year });
  const { data: bulkUploadAlert } = useGetBulkUploadAlertQuery({ year });
  const { data: submitWarnings } = useGetSubmitWarningsQuery({ year });
  const { data: submitIssues } = useGetSubmitIssuesQuery({ year });
  const { data: reviewPills } = useGetReviewPillsQuery({ year });
  const { hasTransactions } = useHasTransactions({ year });

  const isWebviewFocused = useSelector(isWebviewFocusedSelector);

  const uiStateSlug = uiState?.slug;
  const [loadStart, setLoadStart] = useState(0);
  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (isLoading && !prevIsLoading) {
      setLoadStart(performance.now());
      trackActivity('tax home load start');
    }
    if (!isLoading && prevIsLoading) {
      trackActivity('home load time', { loadTime: performance.now() - loadStart, uiState: uiStateSlug, year });
    }
  }, [isLoading, prevIsLoading, loadStart, uiStateSlug, year]);

  const flattenedReviewPills = _.chain(reviewPills).map('pills').flatten().value();
  const bulkUploadCount = _.size(bulkUploadPills);
  const warningBulkUploadCount = _.chain(bulkUploadPills)
    .filter(({ validationFailed, parseFailed }) => validationFailed || parseFailed)
    .size()
    .value();
  const warningBulkUploadCollectionTypes = _.chain(bulkUploadPills)
    .filter(({ validationFailed, parseFailed }) => validationFailed || parseFailed)
    .map('collectionType')
    .uniq()
    .value();

  const bulkUploadSuggestionCount = _.size(bulkUploadSuggestionPills);
  const bulkUploadSuggestionCollectionTypes = _.chain(bulkUploadSuggestionPills).map('collectionType').uniq().value();
  const bulkUploadAlertError = _.get(bulkUploadAlert, 'error');
  const warningReviewPillCount = _.chain(flattenedReviewPills).filter({ warning: true }).size().value();
  const warningReviewPillCollectionTypes = _.chain(flattenedReviewPills)
    .filter({ warning: true })
    .map('collectionType')
    .uniq()
    .value();
  const submitWarningsCount = _.size(submitWarnings);
  const submitWarningsSlugs = _.map(submitWarnings, 'slug');
  const escalationErrorsCount = _.size(submitIssues);

  // Record navigation: file taxes upon any change in the home ui  - for example, we want to re-fire if a user removes a pill
  // We want insight on the user's home state after every ui change (helps us answer questions like "What % of users have resolved all warning UI?")
  useDeepCompareEffect(() => {
    if (_.isNil(uiStateSlug) || uiStateLoading || isLoading || (isReactNative() && !isWebviewFocused)) {
      return;
    }

    trackActivity('navigation: file taxes', {
      uiState: uiStateSlug,
      uiStage,
      year,
      hasTransactions,
      bulkUploadCount,
      warningBulkUploadCount,
      warningBulkUploadCollectionTypes,
      bulkUploadSuggestionCount,
      bulkUploadSuggestionCollectionTypes,
      bulkUploadAlertError,
      warningReviewPillCount,
      warningReviewPillCollectionTypes,
      submitWarningsCount,
      submitWarningsSlugs,
      escalationErrorsCount
    });
  }, [
    uiStateSlug,
    uiStage,
    uiStateLoading,
    year,
    hasTransactions,
    bulkUploadCount,
    warningBulkUploadCount,
    warningBulkUploadCollectionTypes,
    bulkUploadSuggestionCount,
    bulkUploadSuggestionCollectionTypes,
    bulkUploadAlertError,
    warningReviewPillCount,
    warningReviewPillCollectionTypes,
    submitWarningsCount,
    submitWarningsSlugs,
    escalationErrorsCount,
    isLoading,
    isWebviewFocused
  ]);
};
