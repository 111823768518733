import React from 'react';
import { connect } from 'react-redux';
import InformationBanner from '@app/src/Components/Common/InformationBanner/InformationBanner';
import { setExpenseReviewModalType } from '@app/src/actions/expenseReviewActions';
import useExpenseUploadFiles from '@app/src/hooks/useExpenseUploadFiles';

const ExpenseUploadBanner = ({ title, origin, setExpenseReviewModalType }) => {
  const { handleUploadClick } = useExpenseUploadFiles({ origin, setExpenseReviewModalType });

  return <InformationBanner text={title} buttonText='Upload expenses' onButtonClick={handleUploadClick} />;
};

const mapDispatchToProps = {
  setExpenseReviewModalType
};

const ConnectedExpenseUploadBanner = connect(null, mapDispatchToProps)(ExpenseUploadBanner);

export default ConnectedExpenseUploadBanner;
