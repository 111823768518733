import React, { useCallback, useMemo, useState } from 'react';
import url from 'url';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { connect, useSelector } from 'react-redux';
import SearchList from '@app/src/Components/Common/SearchList/SearchList';
import { useUpdateTaxDataMutation } from '@app/src/api/taxDataApi';
import { ICON_URLS } from '@app/src/constants/constants';
import history from '@app/src/keeperHistory';
import { trackActivity } from '@app/src/services/analyticsService';
import { requestInfoResponseMessage } from '@app/src/services/assistantService';
import { addMoreItemsSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { getPathComponentBySlug } from '@app/src/taxflow/main/utils/mainUtils';
import { setBlockingModalProps } from '@app/src/taxflow/navigation/actions/navigationActions';
import { DEFAULT_COLLECTION_ID, TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { colorFilterSecondary } from '@app/src/theme';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';

const TaxFlowAddMoreItem = ({ addMoreItems, requestInfoResponseMessage, setBlockingModalProps, currentQuestion }) => {
  const year = useSelector(yearSelector);
  const [searchQuery, setSearchQuery] = useState('');

  const [updateTaxData] = useUpdateTaxDataMutation();

  const options = useMemo(
    () =>
      addMoreItems.map((item) => {
        return {
          ...item,
          ignoreIconFilter: item.isPremium === true
        };
      }),
    [addMoreItems]
  );

  const handleClick = useCallback(
    async ({ startSlug, collectionType, collectionId, name, message, response, blockingModal, isPremium }) => {
      if (!_.isNil(message) && !_.isNil(response)) {
        // If the selected pill has a message and response, have the assistant send the canned response
        requestInfoResponseMessage({
          message,
          response,
          origin: 'taxflow add more item',
          slug: currentQuestion.slug
        });
        return;
      } else if (!_.isNil(blockingModal)) {
        // Else if there should be a blocking modal showing, open it
        setBlockingModalProps({ ...blockingModal, open: true });
        return;
      } else if (isPremium) {
        trackActivity('review: add collection item', {
          collectionType,
          collectionId,
          pillName: name,
          isPremium
        });
        await updateTaxData({
          taxData: [
            {
              coll_id: collectionId,
              coll_type: collectionType,
              slug: `${collectionType}-started`,
              value: '1'
            },
            {
              coll_id: DEFAULT_COLLECTION_ID,
              coll_type: collectionType,
              slug: `${collectionType}-collection-type-started`,
              value: '1'
            }
          ],
          generateSharedCollectionId: false,
          year
        });
        history.push(
          url.format({
            pathname: `/${TAXFLOW_BASE_URL}`
          })
        );
        return;
      }

      // Else, navigate to the linked question
      trackActivity('review: add collection item', { collectionType, collectionId, pillName: name, isPremium: false });
      const nextPathComponent = getPathComponentBySlug(startSlug);
      if (_.isNil(nextPathComponent)) {
        defaultCaptureMessage('review: could not advance to next item', {
          coll_type: collectionType,
          coll_id: collectionId,
          slug: startSlug
        });
        return;
      }
      history.push(
        url.format({
          pathname: `/${TAXFLOW_BASE_URL}/${nextPathComponent}`,
          query: {
            collectionId: collectionId === '0' ? undefined : collectionId
          }
        })
      );
    },
    [setBlockingModalProps, requestInfoResponseMessage, currentQuestion.slug, year, updateTaxData]
  );

  const formatListItem = useCallback(
    ({
      text,
      iconUrl,
      collectionId,
      collectionType,
      startSlug,
      message,
      response,
      blockingModal,
      isPremium,
      ignoreIconFilter,
      synonyms = []
    }) => {
      return {
        id: text,
        name: text,
        iconUrl,
        showInList: true,
        collectionId,
        collectionType,
        startSlug,
        message,
        response,
        blockingModal,
        isPremium,
        ignoreIconFilter,
        synonyms
      };
    },
    []
  );

  const fuse = useMemo(
    () =>
      new Fuse(options, {
        keys: ['text', 'synonyms'],
        threshold: 0.5
      }),
    [options]
  );

  const listItems = useMemo(() => {
    const searchMatches = (
      searchQuery.trim().length ? fuse.search(searchQuery).map(({ item }) => item) : options
    ).concat({
      text: 'Something Else',
      message: 'I need help adding something else to my return',
      response: "Tell us more about your tax situation and we'll take it from there.",
      iconUrl: ICON_URLS.HELP_SUPPORT
    });
    return searchMatches.map(formatListItem);
  }, [options, formatListItem, fuse, searchQuery]);

  return (
    <SearchList
      iconFilter={colorFilterSecondary}
      items={listItems}
      onAddItem={handleClick}
      onSearch={(search) => setSearchQuery(search)}
      query={searchQuery}
      searchPlaceholderText='Search'
      style={{ margin: 'auto' }}
    />
  );
};

const mapStateToProps = (state) => ({
  addMoreItems: addMoreItemsSelector(state),
  currentQuestion: currentQuestionSelector(state)
});

const mapDispatchToProps = {
  requestInfoResponseMessage,
  setBlockingModalProps
};

const ConnectedTaxFlowAddMoreItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowAddMoreItem);

export default ConnectedTaxFlowAddMoreItem;
