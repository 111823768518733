import React, { useMemo } from 'react';
import _ from 'lodash';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';
import { colorFontDark } from '@app/src/theme';

const DependentsInput = ({ InputLabelProps = {}, label = 'Number of dependents', FormControlProps = {} }) => {
  const { handleSubmit } = useFormContext();

  const { onSubmit, isLoading } = useTaxProfileFormContext();

  const filingStatus = useWatch({ name: 'filingStatus' });

  const eligibleForDependents = useMemo(
    () => filingStatus === 'married' || filingStatus === 'head' || filingStatus === 'married_separate',
    [filingStatus]
  );

  if (!eligibleForDependents) {
    return null;
  }

  return (
    <Controller
      name='numberOfDependents'
      defaultValue={''}
      rules={{
        min: {
          value: 0,
          message: 'Number of dependents cannot be negative'
        },
        max: {
          value: 10,
          message: 'Number of dependents cannot exceed 10'
        }
      }}
      render={({ field, fieldState: { error } }) => (
        <FormControl {...FormControlProps}>
          <InputLabel
            style={{
              fontSize: 16,
              color: colorFontDark
            }}
            htmlFor={field.name}
            id={`${field.name}Label`}
            {...InputLabelProps}
          >
            {label}
          </InputLabel>
          <TextField
            {...field}
            value={_.isNil(field.value) ? '' : field.value}
            error={!!error}
            helperText={error?.message}
            disabled={isLoading}
            onChange={(e) => {
              const value = parseInt(e.target.value);

              if (isNaN(value)) {
                field.onChange(null);
                return;
              }

              field.onChange(value);
            }}
            onBlur={handleSubmit(onSubmit(field.name))}
            id={field.name}
            type='number'
            InputProps={{
              inputProps: {
                min: 0,
                max: 10
              }
            }}
            placeholder='e.g. 1'
            style={{
              width: '100%'
            }}
          />
        </FormControl>
      )}
    />
  );
};

export default DependentsInput;
