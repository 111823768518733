import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';
import TaxProfileSlider from '@app/src/Components/TaxProfile/TaxProfileSlider';

const ExpenseSlider = ({ name, label, enabledField }) => {
  const { onSubmit, isLoading } = useTaxProfileFormContext();

  const { handleSubmit, setValue, watch } = useFormContext();

  const enabled = enabledField ? watch(enabledField) : true;

  return (
    <Controller
      name={name}
      rules={{
        min: 0,
        max: 100
      }}
      render={({ field: { value, onChange, name } }) => {
        return (
          <TaxProfileSlider
            label={label}
            id={name}
            value={(() => {
              if (!enabled) {
                return 0;
              }

              return value;
            })()}
            disabled={isLoading}
            onChange={(value) => {
              const parsedValue = (() => {
                const result = parseInt(value);

                if (isNaN(result)) {
                  return 0;
                }

                return result;
              })();

              if (enabledField) {
                setValue(enabledField, parsedValue > 0 ? 1 : 0);

                onChange(parsedValue);
                return;
              }

              onChange(parsedValue);
            }}
            onBlur={handleSubmit(onSubmit(name))}
          />
        );
      }}
    />
  );
};

export default ExpenseSlider;
