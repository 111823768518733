import React from 'react';
import TaxEstimateDetails from '@app/src/Components/TaxProfile/TaxEstimateDetails';
import TaxProfileForm from '@app/src/Components/TaxProfile/TaxProfileForm';
import useTaxProfile from '@app/src/Components/TaxProfile/hooks/useTaxProfile';

const TaxProfile = () => {
  const { showForm, formProps, detailsProps, isLoading } = useTaxProfile();

  if (isLoading) {
    return <TaxEstimateDetails isLoading />;
  }

  if (showForm) {
    return <TaxProfileForm {...formProps} />;
  }

  return <TaxEstimateDetails {...detailsProps} />;
};

export default TaxProfile;
