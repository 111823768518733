import { SET_TAXFILING_CURRENT_COLLECTION_ID, SET_TAXFILING_CURRENT_TAX_STATE } from '@app/src/actions/types';

export const setCurrentCollectionId = (payload) => ({
  type: SET_TAXFILING_CURRENT_COLLECTION_ID,
  payload
});

export const setCurrentTaxState = (payload) => ({
  type: SET_TAXFILING_CURRENT_TAX_STATE,
  payload
});
