import React from 'react';
import moment from 'moment';
import '@app/src/Components/Subscription/SubscriptionContent/RefundCopy.scss';

const RefundCopy = () => (
  <div className='grey-box'>
    <div className='text-base bold'>100% satisfied or your money back</div>
    <div className='base-label'>
      No-questions-asked refund available until {moment().add(30, 'days').format('MMM DD')}.
    </div>
  </div>
);

export default RefundCopy;
