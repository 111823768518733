import React from 'react';
import MortgageInterestPaidInput from '@app/src/Components/TaxProfile/FormInputs/CreditsAndDeductions/MortgageInterestPaidInput';
import StudentTuitionPaidInput from '@app/src/Components/TaxProfile/FormInputs/CreditsAndDeductions/StudentTuitionPaidInput';
import TraditionalIraContributionsInput from '@app/src/Components/TaxProfile/FormInputs/CreditsAndDeductions/TraditionalIraContributionsInput';
import TaxProfileFormSection from '@app/src/Components/TaxProfile/FormSections/TaxProfileFormSection';

/**
 * @typedef CreditsAndDeductionsSectionProps
 * @property {import('react-hook-form').UseFormReturn['control']} control
 * @property {import('react-hook-form').UseFormReturn['handleSubmit']} handleSubmit
 */

/** @type {React.FC<CreditsAndDeductionsSectionProps>} */
const CreditsAndDeductionsSection = () => {
  return (
    <TaxProfileFormSection header='Credits and deductions'>
      <StudentTuitionPaidInput />
      <MortgageInterestPaidInput />
      <TraditionalIraContributionsInput />
    </TaxProfileFormSection>
  );
};

export default CreditsAndDeductionsSection;
