import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import '@app/src/Components/Common/InformationBanner/InformationBanner.scss';

export default function InformationBanner({ className, text, buttonText, onButtonClick, link }) {
  return (
    <div className={classNames('information-banner', className)}>
      <div className='information-banner-text'>{text}</div>
      {link ? (
        <Link to={link}>
          <button className='information-banner-button'>{buttonText}</button>
        </Link>
      ) : (
        <button className='information-banner-button' onClick={onButtonClick}>
          {buttonText}
        </button>
      )}
    </div>
  );
}
