import React from 'react';
import { useSelector } from 'react-redux';
import MagicStar from '@app/src/assets/magic-star.svg?react';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { colorFontLight, colorNeutralEerieBlack } from '@app/src/theme';

export const TaxFlowPriorYearTaxesPremiumUpsell = () => {
  const year = useSelector(yearSelector);
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          fontWeight: 500,
          color: colorNeutralEerieBlack,
          paddingBottom: 16
        }}
      >
        <MagicStar width={24} height={24} />
        <div>Past due tax filing</div>
      </div>
      <div style={{ color: colorFontLight }}>
        {`If you want to file taxes for ${year}, you’ll need to upgrade to a premium subscription. We’ll pair you with a
      dedicated tax pro who will help you prepare your late return.`}
      </div>
    </>
  );
};
