import React from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
import ExpenseReviewModalContent from '@app/src/Components/ExpenseReview/ExpenseReviewModalContent';
import CloseIcon from '@app/src/assets/close.svg?react';
import {
  expenseReviewModalShowSelector,
  expenseReviewModalTypeSelector
} from '@app/src/selectors/expenseReviewSelectors';
import { onModalClose } from '@app/src/services/expenseReviewService';
import '@app/src/Components/ExpenseReview/ExpenseReviewModal.scss';

const ExpenseReviewModal = ({ modalType, onModalClose }) => {
  const closeable = modalType !== 'paywall';

  const handleModalClose = () => {
    if (!closeable) return;

    onModalClose();
  };

  const isExpenseModal = modalType === 'add' || modalType === 'edit';

  return (
    <Dialog
      slotProps={{ root: { sx: { zIndex: 1000 } } }}
      sx={{
        '.MuiPaper-root': {
          padding: '16px',
          width: isExpenseModal ? '600px' : 'fit-content',
          borderRadius: '8px',
          maxHeight: modalType === 'add' ? 'calc(100% - 16px)' : null
        }
      }}
      className='expense-review-modal'
      open={!!modalType}
      onClose={handleModalClose}
    >
      {closeable && (
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            top: 0
          }}
          onClick={onModalClose}
        >
          <CloseIcon color='primary' />
        </IconButton>
      )}
      <ExpenseReviewModalContent />
    </Dialog>
  );
};
const mapStateToProps = (state) => ({
  infoModalShow: expenseReviewModalShowSelector(state),
  modalType: expenseReviewModalTypeSelector(state)
});
const mapDispatchToProps = {
  onModalClose
};
const ConnectedExpenseReviewModal = connect(mapStateToProps, mapDispatchToProps)(ExpenseReviewModal);

export default ConnectedExpenseReviewModal;
