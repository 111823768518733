import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentYear } from '@app/src/global/Helpers';
import { onboardingPricingInfoSelector } from '@app/src/selectors/onboardingSelectors';
import '@app/src/Components/Common/CompanyInfo/CompanyInfo.scss';

const CompanyInfo = () => {
  const pricingInfo = useSelector(onboardingPricingInfoSelector);

  return (
    <section className='company-info'>
      <div className='company-info-privacy'>
        This website uses third party advertising cookies to serve you relevant ads. You may opt-out from these third
        party cookies by navigation to keepertax.com and clicking “Opt out”.
      </div>
      <div className='company-info-container'>
        <div className='company-info-section company-info-section-links'>
          <div>
            <a href='https://www.keepertax.com/terms' target='_blank' rel='noopener noreferrer'>
              Terms of Service
            </a>
          </div>
          <div>
            <a href='https://www.keepertax.com/privacy' target='_blank' rel='noopener noreferrer'>
              Privacy Policy
            </a>
          </div>
          <div>
            <a href='mailto:support@keepertax.com' target='_blank' rel='noopener noreferrer'>
              Customer Support
            </a>
          </div>
        </div>
        <span className='company-info-divider' />
        <div className='company-info-section'>
          <div className='company-info-section-subsection'>
            <div>44 Montgomery Street,</div>
            <div>San Francisco, CA 94104</div>
          </div>
          <div className='company-info-section-subsection'>
            <div>Tel: 314-400-8008</div>
          </div>
          <div className='company-info-section-subsection'>
            <div>Copyright © {getCurrentYear()}</div>
            <div>Keeper Tax Inc.</div>
            <div>All rights reserved.</div>
          </div>
        </div>
      </div>
      <div className='company-info-pricing'>{pricingInfo}</div>
    </section>
  );
};

export default CompanyInfo;
