import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import TaxFlowPillsSection from '@app/src/Components/TaxFlow/Common/TaxFlowPillsSection';
import { useGetQuestionnaireProgressQuery, useGetQuestionnaireSummaryPillsQuery } from '@app/src/api/taxDataApi';
import { useQuestionnaireLoading } from '@app/src/hooks/useQuestionnaireLoading';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { PATH_COMPONENT__BULK_UPLOAD_QUESTIONS } from '@app/src/taxflow/sections/special/constants/specialConstants';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';

const TaxFlowQuestionnaireSummary = ({ setLoading = _.noop }) => {
  const year = useSelector(yearSelector);
  const history = useHistory();

  const { data: questionnaireProgress, isLoading: isQuestionnaireProgressLoading } = useGetQuestionnaireProgressQuery({
    year
  });
  const { data: questionnaireSummaryPills, isLoading: questionnaireSummaryPillsLoading } =
    useGetQuestionnaireSummaryPillsQuery({ year });

  const { questionnaireLoading } = useQuestionnaireLoading();
  const [pills, setPills] = useState([]);

  const isLoading = isQuestionnaireProgressLoading || questionnaireSummaryPillsLoading;

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  const passedQuestions = _.get(questionnaireProgress, 'passedQuestions', 0);

  const navigateToQuestionnaire = useCallback(() => {
    history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__BULK_UPLOAD_QUESTIONS}`);
  }, [history]);

  useEffect(() => {
    setPills(
      _.map(questionnaireSummaryPills, ({ text, disabled }) => ({
        text,
        variant: disabled ? 'disabled' : 'default',
        loading: false
      }))
    );
  }, [questionnaireSummaryPills, setPills]);

  if (isLoading) {
    return null;
  }

  return (
    <>
      {!_.isEmpty(pills) && <TaxFlowPillsSection pills={pills}></TaxFlowPillsSection>}{' '}
      <Button
        fullWidth
        size='large'
        variant='contained'
        disabled={questionnaireLoading}
        onClick={navigateToQuestionnaire}
        style={{ marginTop: 8 }}
      >
        <div>
          {questionnaireLoading
            ? 'Updating...'
            : passedQuestions === 0 || _.isNil(passedQuestions)
              ? 'Start questionnaire'
              : 'Finish questionnaire'}
        </div>
      </Button>
    </>
  );
};

export default TaxFlowQuestionnaireSummary;
