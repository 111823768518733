import React from 'react';
import _ from 'lodash';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import { Controller, useFormContext } from 'react-hook-form';
import { useTaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';
import { colorFontDark } from '@app/src/theme';

const DeductibleMealsEnabledInput = () => {
  const { jobType, onSubmit } = useTaxProfileFormContext();

  const { handleSubmit } = useFormContext();

  const name = 'deductibleMealsEnabled';

  return (
    <Controller
      name={name}
      render={({ field }) => {
        return (
          <FormControl
            fullWidth
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <InputLabel
              style={{
                fontSize: 16,
                fontWeight: 400,
                color: colorFontDark
              }}
              htmlFor={name}
              id={`${name}Label`}
            >
              {_.capitalize(jobType)} meals
            </InputLabel>
            <Switch
              id={name}
              {...field}
              checked={field.value}
              onChange={(e) => {
                field.onChange(e.target.checked);
                handleSubmit(onSubmit(field.name))();
              }}
            />
          </FormControl>
        );
      }}
    />
  );
};

export default DeductibleMealsEnabledInput;
