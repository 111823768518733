import React from 'react';
import ControlledYearlyMoneyInput from '@app/src/Components/TaxProfile/ControlledYearlyMoneyInput';
import { useTaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';

const SpouseFreelanceIncomeInput = () => {
  const { incomeType } = useTaxProfileFormContext();

  return <ControlledYearlyMoneyInput name='spouse1099Income' label={`Spouse ${incomeType} income`} defaultValue={0} />;
};

export default SpouseFreelanceIncomeInput;
