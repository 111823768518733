import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AccountItem } from '@app/src/Components/Bank/AccountItem';
import ConfirmDisconnectModal from '@app/src/Components/Bank/ConfirmDisconnectModal';
import BankLink from '@app/src/Components/BankLink/BankLink';
import ExpenseUploadBanner from '@app/src/Components/Common/ExpenseUpload/ExpenseUploadBanner';
import AddIcon from '@app/src/assets/add.svg?react';
import ArrowRightIcon from '@app/src/assets/arrow-right.svg?react';
import BankIcon from '@app/src/assets/bank-account.svg?react';
import CreditCardIcon from '@app/src/assets/card.svg?react';
import { EXPENSE_UPLOAD_BANK_LIST_TITLE } from '@app/src/constants/constants';
import { viewableAccountsSelector } from '@app/src/selectors/bankSelectors';
import { isInConfirmAmountsSelector } from '@app/src/selectors/taxFlowSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { changeAccountType, getBankSummaryV2, removeSubAccount } from '@app/src/services/bankService';
import { setSelfUnlockModalOpen } from '@app/src/taxflow/navigation/actions/navigationActions';
import getImageUrl from '@app/src/utils/getImageUrl';
import '@app/src/Components/Bank/BankList/BankList.scss';

const BankList = ({
  bankList,
  getBankSummaryV2,
  changeAccountType,
  removeSubAccount,
  setSelfUnlockModalOpen,
  isInConfirmAmounts,
  origin,
  ...props
}) => {
  const [subaccount, setSubaccount] = useState(null);

  const type = 'bank';

  useEffect(() => {
    trackActivity('navigation: add accounts');

    getBankSummaryV2();
  }, [getBankSummaryV2]);

  const handleRemoveAccount = (account) => {
    removeSubAccount(account);
    setSubaccount(null);
  };

  return (
    <div className='bank-list'>
      <div>
        <BankLink icon={<BankIcon />} className='bank-link-button' origin={origin} type={type}>
          <span>Checking accounts</span>
          <AddIcon />
        </BankLink>
        <BankLink icon={<CreditCardIcon />} className='bank-link-button' origin={origin} type={type}>
          <span>Credit card</span>
          <AddIcon />
        </BankLink>
      </div>
      {origin === 'settings' && (
        <ExpenseUploadBanner title={EXPENSE_UPLOAD_BANK_LIST_TITLE} origin={'linked-accounts'} />
      )}
      <div className={bankList.length > 0 ? 'bank-details' : ''}>
        {bankList.map(({ institutionName, linked, subAccounts, itemId, logo, accountType }, bi) => (
          <div className='bank-item' key={bi}>
            <div className='bank-item-logo-container'>
              {logo ? (
                <img className='bank-item-logo' src={`data:image/png;base64,${logo}`} alt='Bank logo' />
              ) : (
                <div className='bank-item-logo-default'>
                  {accountType === 'credit' ? <CreditCardIcon /> : <BankIcon />}
                </div>
              )}
            </div>
            <div className='bank-item-heading'>
              <span className='bank-item-name'>{institutionName}</span>
            </div>
            <div className='bank-item-details'>
              {linked === 0 && origin === 'settings' ? (
                <div className='unlinked-item'>
                  <Link to={`/bank-details/${itemId}`} className='unlinked-item-link'>
                    <span className='unlinked-item-link-text'>This account has unlinked from Keeper. Relink now!</span>
                    <ArrowRightIcon />
                  </Link>
                </div>
              ) : (
                subAccounts.map((subAcc, ai) => (
                  <AccountItem
                    isInConfirmAmounts={isInConfirmAmounts}
                    account={subAcc}
                    key={ai}
                    isSettings={type === 'settings'}
                    setSelfUnlockModalOpen={setSelfUnlockModalOpen}
                    onDisconnect={() => setSubaccount(subAcc)}
                    onChangeAccountType={changeAccountType}
                    {...props}
                  />
                ))
              )}
            </div>
          </div>
        ))}
      </div>
      {bankList?.length > 0 && (
        <div className='bank-link-security-footer'>
          <span className='bank-link-security-text'>Bank-level security, powered by </span>
          <img className='plaid-logo' src={getImageUrl('plaid-link.svg')} alt='Connect your bank' />
        </div>
      )}
      <ConfirmDisconnectModal
        onCancel={() => setSubaccount(null)}
        onConfirm={handleRemoveAccount}
        subaccount={subaccount}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  bankList: viewableAccountsSelector(state),
  expense: state.expense,
  isInConfirmAmounts: isInConfirmAmountsSelector(state)
});

const mapDispatchToProps = {
  getBankSummaryV2,
  changeAccountType,
  removeSubAccount,
  setSelfUnlockModalOpen
};

const ConnectedBankList = connect(mapStateToProps, mapDispatchToProps)(BankList);

export default ConnectedBankList;
