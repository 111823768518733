import React from 'react';
import AskButton from '@app/src/Components/TaxProfile/AskButton';
import ControlledYearlyMoneyInput from '@app/src/Components/TaxProfile/ControlledYearlyMoneyInput';

const MortgageInterestPaidInput = () => {
  return (
    <ControlledYearlyMoneyInput
      name='mortgageInterestPaid'
      label='Mortgage interest'
      textFieldProps={{
        InputProps: {
          endAdornment: (
            <AskButton
              field='mortgageInterestPaid'
              message='What is mortgage interest?'
              response='This is the interest charged on a loan used to purchase your home. Since mortgage interest is tax-deductible, Keeper will subtract this amount from your taxable income to reduce your taxes owed.'
              origin='Tax Profile Input'
            />
          )
        }
      }}
    />
  );
};

export default MortgageInterestPaidInput;
