import React, { useCallback, useEffect, useState } from 'react';
import url from 'url';
import _ from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import TaxFlowConfirmModal from '@app/src/Components/TaxFlow/Common/TaxFlowConfirmModal';
import TaxFlowPill from '@app/src/Components/TaxFlow/Common/TaxFlowPill';
import TaxFlowPremiumSelfUnlockModal from '@app/src/Components/TaxFlow/Common/TaxFlowPremiumSelfUnlockModal';
import { TAGS } from '@app/src/api/baseApi';
import profileApi from '@app/src/api/profileApi';
import { useGetReviewPillsQuery, useGetUIStageQuery } from '@app/src/api/taxDataApi';
import MagicStar from '@app/src/assets/magic-star.svg?react';
import { isMobileBrowser, isReactNative } from '@app/src/global/Helpers';
import { useIsPremiumSubscriber } from '@app/src/hooks/pricingHooks';
import { useShowPremiumBlockingModal } from '@app/src/hooks/useShowPremiumBlockingModal';
import { useUnlockedViaImpersonation } from '@app/src/hooks/useUnlockedViaImpersonation';
import history from '@app/src/keeperHistory';
import { selfUnlockModalOpenSelector } from '@app/src/selectors/navigationListSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { moveToNotStarted } from '@app/src/services/taxFlowService';
import TaxFlowCollectionDeleteModal from '@app/src/taxflow/collection/components/TaxFlowCollectionDeleteModal';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { getPathComponentBySlug } from '@app/src/taxflow/main/utils/mainUtils';
import { setSelfUnlockModalOpen } from '@app/src/taxflow/navigation/actions/navigationActions';
import { PATH_COMPONENT__HOME } from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  PATH_COMPONENT__EXPENSES,
  TAXFLOW_BASE_URL,
  UI_STAGE__CONFIRM_AMOUNTS,
  UI_STAGE_METADATA
} from '@app/src/taxflow/shared/constants/sharedConstants';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';
import '@app/src/Components/TaxFlow/Question/TaxFlowReviewPillsSection.scss';

const TaxFlowReviewPillsSection = ({
  currentQuestion,
  selfUnlockModalOpen,
  setSelfUnlockModalOpen,
  moveToNotStarted,
  setLoading = _.noop
}) => {
  const dispatch = useDispatch();
  const year = useSelector(yearSelector);

  const { data: reviewPills, isLoading: reviewPillsLoading } = useGetReviewPillsQuery({ year });
  const { data: uiStage, isLoading: uiStageLoading } = useGetUIStageQuery({ year });
  const { data: isPremiumSubscriber, isLoading: isPremiumSubscriberLoading } = useIsPremiumSubscriber();

  const showPremiumBlockingModal = useShowPremiumBlockingModal();

  const [showCollectionDeleteModal, setShowCollectionDeleteModal] = useState(false);
  const [collectionDeleteModalTarget, setCollectionDeleteModalTarget] = useState({
    collectionType: undefined,
    collectionId: undefined
  });

  const isLoading = reviewPillsLoading || uiStageLoading || isPremiumSubscriberLoading;

  const pillSectionsLHS = _.take(reviewPills, _.size(reviewPills) / 2);
  const pillSectionsRHS = _.takeRight(reviewPills, _.size(reviewPills) / 2);

  const isUserUnlockedViaImpersonation = useUnlockedViaImpersonation();

  const editEnabled =
    _.isNil(uiStage) ||
    _.isEmpty(uiStage) ||
    _.find(UI_STAGE_METADATA, { stage: uiStage })?.editEnabled ||
    isUserUnlockedViaImpersonation;
  const selfUnlockEnabled = uiStage === UI_STAGE__CONFIRM_AMOUNTS;

  useEffect(() => setLoading(isLoading), [isLoading, setLoading]);

  const navigateToLinkedQuestion = useCallback(
    ({ startSlug, collectionType, collectionId, nextQuestionCollectionId, text, isPremium }) => {
      trackActivity('review: pill clicked', { collectionType, collectionId, pillName: text });
      if (!editEnabled) {
        return;
      }
      if (collectionType === 'deductions') {
        !isReactNative() &&
          !isMobileBrowser() &&
          history.push(
            url.format({
              pathname: `/${PATH_COMPONENT__EXPENSES}`
            })
          );
        return;
      } else if (isPremium) {
        showPremiumBlockingModal({ formName: text });
        return;
      }
      const nextPathComponent = getPathComponentBySlug(startSlug);
      const nextCollectionId = nextQuestionCollectionId || collectionId;
      if (_.isNil(nextPathComponent)) {
        defaultCaptureMessage('Could not advance to review question', {
          coll_type: collectionType,
          coll_id: nextCollectionId,
          slug: startSlug
        });
        return;
      }
      history.push(
        url.format({
          pathname: `/${TAXFLOW_BASE_URL}/${nextPathComponent}`,
          query: {
            collectionId: nextCollectionId === '0' ? undefined : nextCollectionId
          }
        })
      );
    },
    [editEnabled, showPremiumBlockingModal]
  );

  const confirmPillDeletion = useCallback(
    ({ collectionType, collectionId, text }) => {
      trackActivity('review: pill removal requested', { collectionType, collectionId, pillName: text });
      if (!editEnabled) {
        return;
      }
      setCollectionDeleteModalTarget({
        collectionType: collectionType,
        collectionId
      });
      setShowCollectionDeleteModal(true);
    },
    [editEnabled]
  );

  const onEditClick = () => {
    if (!selfUnlockModalOpen) {
      trackActivity('self unlock: modal opened', {
        question: currentQuestion?.slug
      });
      setSelfUnlockModalOpen(true);
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <div className='taxflow-review-pills-section'>
        <div className='taxflow-review-pills-section-pills'>
          <div className='taxflow-review-pills-section-pills-column'>
            {pillSectionsLHS.map(({ section, pills }) => (
              <PillsSubSection
                key={section}
                heading={section}
                pills={pills}
                editEnabled={editEnabled}
                onClickPill={navigateToLinkedQuestion}
                onRemovePill={confirmPillDeletion}
              />
            ))}
          </div>
          <div className='taxflow-review-pills-section-pills-separator'></div>
          <div className='taxflow-review-pills-section-pills-column'>
            {pillSectionsRHS.map(({ section, pills }) => (
              <PillsSubSection
                key={section}
                heading={section}
                pills={pills}
                editEnabled={editEnabled}
                onClickPill={navigateToLinkedQuestion}
                onRemovePill={confirmPillDeletion}
              />
            ))}
          </div>
        </div>
      </div>
      {selfUnlockEnabled ? (
        <div className='taxflow-review-pills-section-edit' onClick={onEditClick}>
          Unlock to make edits
        </div>
      ) : null}
      {showCollectionDeleteModal && (
        <TaxFlowCollectionDeleteModal
          collectionType={collectionDeleteModalTarget.collectionType}
          collectionId={collectionDeleteModalTarget.collectionId}
          hideModal={() => setShowCollectionDeleteModal(false)}
        />
      )}
      {isPremiumSubscriber ? (
        <TaxFlowPremiumSelfUnlockModal />
      ) : (
        <TaxFlowConfirmModal
          open={selfUnlockModalOpen}
          setOpen={setSelfUnlockModalOpen}
          header='Edit your return and re-submit?'
          body="You'll need to re-submit for review after editing your return."
          // non breaking hyphen
          confirmText='Yes, edit and re&#8209;submit'
          onConfirm={async () => {
            trackActivity('self unlock: modal confirmed', {
              question: currentQuestion?.slug
            });
            await moveToNotStarted();
            dispatch(profileApi.util.invalidateTags([{ type: TAGS.UI_STAGE, id: year }]));
            history.replace(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__HOME}`);
          }}
        />
      )}
    </>
  );
};

const PillsSubSection = ({ heading, pills = [], editEnabled, onClickPill, onRemovePill }) => {
  const deriveVariant = (pill) => {
    if (!editEnabled) {
      return 'disabled';
    }
    if (pill.warning) {
      return 'warning';
    }
    if (pill.addable) {
      return 'add';
    }
    return 'default';
  };

  const deriveIcon = (pill) => {
    if (pill.isPremium) {
      return <MagicStar />;
    }
    return pill.icon;
  };

  return (
    <div className='taxflow-review-pills-section-pills-section'>
      <div className='taxflow-review-pills-section-pills-section-heading'>{heading}</div>
      <div className='taxflow-review-pills-section-pills-section-pills'>
        {pills
          .filter((pill) => !pill.addable || editEnabled)
          .map((pill) => {
            const key = (() => {
              if (pill.startSlug) {
                return `${pill.startSlug}-${pill.collectionId}`;
              }

              return `${pill.collectionType}-${pill.collectionId}`;
            })();

            return (
              <TaxFlowPill
                key={key}
                text={pill.text}
                icon={deriveIcon(pill)}
                removable={editEnabled && pill.removable}
                variant={pill.variant || deriveVariant(pill)}
                onClick={() => onClickPill(pill)}
                onRemove={() => onRemovePill(pill)}
              />
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentQuestion: currentQuestionSelector(state),
  selfUnlockModalOpen: selfUnlockModalOpenSelector(state)
});

const mapDispatchToProps = {
  setSelfUnlockModalOpen,
  moveToNotStarted
};

const ConnectedTaxFlowReviewPillsSection = connect(mapStateToProps, mapDispatchToProps)(TaxFlowReviewPillsSection);

export default ConnectedTaxFlowReviewPillsSection;
