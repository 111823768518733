import _ from 'lodash';
import { TAXFLOW__FORM_UPLOAD_VALID_STATES } from '@app/src/taxflow/collection/constants/formUploadConstants';
import { INCOME_PATH_COMPONENTS, INCOME_SLUGS } from '@app/src/taxflow/sections/income/incomeConstants';

export const getNextPathComponentMap = () => {
  return {
    [INCOME_SLUGS.INVEST_MORE_INFO]: {
      nextPathComponent: INCOME_PATH_COMPONENTS.INVEST_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [INCOME_SLUGS.DIV_INFO]: {
      nextPathComponent: INCOME_PATH_COMPONENTS.DIV_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },

    [INCOME_SLUGS.INTEREST_INFO]: {
      nextPathComponent: INCOME_PATH_COMPONENTS.INTEREST_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [INCOME_SLUGS.UNEMPLOYMENT_INFO]: {
      nextPathComponent: INCOME_PATH_COMPONENTS.UNEMPLOYMENT_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [INCOME_SLUGS.W2_INFO]: { nextPathComponent: INCOME_PATH_COMPONENTS.W2_EXIT_BOUNCE, questionnaireEarlyExit: false },
    [INCOME_SLUGS.W2G_INFO]: {
      nextPathComponent: INCOME_PATH_COMPONENTS.W2G_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [INCOME_SLUGS.FREELANCE_1099_MISC]: {
      nextPathComponent: INCOME_PATH_COMPONENTS.FREELANCE_JOB,
      questionnaireEarlyExit: false
    },
    [INCOME_SLUGS.FREELANCE_1099_NEC]: {
      nextPathComponent: INCOME_PATH_COMPONENTS.FREELANCE_JOB,
      questionnaireEarlyExit: false
    },
    [INCOME_SLUGS.RETIREMENT_PENSION_INFO]: {
      nextPathComponent: INCOME_PATH_COMPONENTS.RETIREMENT_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [INCOME_SLUGS.RETIREMENT_SSA_INFO]: {
      nextPathComponent: INCOME_PATH_COMPONENTS.RETIREMENT_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [INCOME_SLUGS.FREELANCE_AGI]: {
      nextPathComponent: INCOME_PATH_COMPONENTS.FREELANCE_JOB,
      questionnaireEarlyExit: false
    },
    [INCOME_SLUGS.FREELANCE_1099K_INFO]: {
      nextPathComponent: INCOME_PATH_COMPONENTS.FREELANCE_1099K_EXPENSES_INFO,
      questionnaireEarlyExit: false
    },
    [INCOME_SLUGS.FREELANCE_1099K_EXPENSES_INFO]: {
      nextPathComponent: INCOME_PATH_COMPONENTS.FREELANCE_JOB,
      questionnaireEarlyExit: false
    },
    [INCOME_SLUGS.PERSONAL_ITEMS]: {
      nextPathComponent: INCOME_PATH_COMPONENTS.PERSONAL_ITEMS_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    },
    [INCOME_SLUGS.FREELANCE_BUSINESS_CODE_INFO]: {
      nextPathComponent: INCOME_PATH_COMPONENTS.FREELANCE_EXIT_BOUNCE,
      questionnaireEarlyExit: false
    }
  };
};

export const getNextPathComponent = ({ question, businessCode, currentCollectionId, uploadAttempts }) => {
  if (question.slug === INCOME_SLUGS.FREELANCE_JOB) {
    if (businessCode) {
      return { nextPathComponent: INCOME_PATH_COMPONENTS.FREELANCE_EXIT_BOUNCE };
    } else {
      return { nextPathComponent: INCOME_PATH_COMPONENTS.FREELANCE_BUSINESS_CODE_INFO };
    }
  } else if (question.slug === INCOME_SLUGS.INVEST_INFO) {
    const uploadAttempt = _.get(uploadAttempts, [question.collectionType, currentCollectionId]);
    if (
      TAXFLOW__FORM_UPLOAD_VALID_STATES.includes(_.get(uploadAttempt, 'status')) ||
      (_.get(uploadAttempt, 'status') === 'in_cloud_storage' && _.get(uploadAttempt, 'isDocOnly'))
    ) {
      return { nextPathComponent: INCOME_PATH_COMPONENTS.INVEST_EXIT_BOUNCE };
    }
    return { nextPathComponent: INCOME_PATH_COMPONENTS.INVEST_MORE_INFO };
  } else if (question.slug === INCOME_SLUGS.INVEST_MORE_INFO) {
    return { nextPathComponent: INCOME_PATH_COMPONENTS.INVEST_EXIT_BOUNCE };
  } else {
    return { nextPathComponent: null };
  }
};

export const getOptionPathComponentMap = () => {
  return {
    [INCOME_SLUGS.RETIREMENT_TYPE]: {
      pension: {
        nextPathComponent: INCOME_PATH_COMPONENTS.RETIREMENT_PENSION_INFO,
        questionnaireEarlyExit: false
      },
      ssa: {
        nextPathComponent: INCOME_PATH_COMPONENTS.RETIREMENT_SSA_INFO,
        questionnaireEarlyExit: false
      }
    },
    [INCOME_SLUGS.FREELANCE_1099_TYPE]: {
      nec: {
        nextPathComponent: INCOME_PATH_COMPONENTS.FREELANCE_1099_NEC,
        questionnaireEarlyExit: false
      },
      misc: {
        nextPathComponent: INCOME_PATH_COMPONENTS.FREELANCE_1099_MISC,
        questionnaireEarlyExit: false
      },
      k: {
        nextPathComponent: INCOME_PATH_COMPONENTS.FREELANCE_1099K_INFO,
        questionnaireEarlyExit: false
      },
      none: {
        nextPathComponent: INCOME_PATH_COMPONENTS.FREELANCE_AGI,
        questionnaireEarlyExit: false
      }
    }
  };
};

export const getSlugMap = () => {
  return {
    [INCOME_PATH_COMPONENTS.DIV_EXIT_BOUNCE]: INCOME_SLUGS.DIV_EXIT_BOUNCE,
    [INCOME_PATH_COMPONENTS.DIV_INFO]: INCOME_SLUGS.DIV_INFO,
    [INCOME_PATH_COMPONENTS.FREELANCE_1099_MISC]: INCOME_SLUGS.FREELANCE_1099_MISC,
    [INCOME_PATH_COMPONENTS.FREELANCE_1099_NEC]: INCOME_SLUGS.FREELANCE_1099_NEC,
    [INCOME_PATH_COMPONENTS.FREELANCE_1099_TYPE]: INCOME_SLUGS.FREELANCE_1099_TYPE,
    [INCOME_PATH_COMPONENTS.FREELANCE_1099K_EXPENSES_INFO]: INCOME_SLUGS.FREELANCE_1099K_EXPENSES_INFO,
    [INCOME_PATH_COMPONENTS.FREELANCE_1099K_INFO]: INCOME_SLUGS.FREELANCE_1099K_INFO,
    [INCOME_PATH_COMPONENTS.FREELANCE_AGI]: INCOME_SLUGS.FREELANCE_AGI,
    [INCOME_PATH_COMPONENTS.FREELANCE_BUSINESS_CODE_INFO]: INCOME_SLUGS.FREELANCE_BUSINESS_CODE_INFO,
    [INCOME_PATH_COMPONENTS.FREELANCE_EXIT_BOUNCE]: INCOME_SLUGS.FREELANCE_EXIT_BOUNCE,
    [INCOME_PATH_COMPONENTS.FREELANCE_JOB]: INCOME_SLUGS.FREELANCE_JOB,
    [INCOME_PATH_COMPONENTS.INTEREST_EXIT_BOUNCE]: INCOME_SLUGS.INTEREST_EXIT_BOUNCE,
    [INCOME_PATH_COMPONENTS.INTEREST_INFO]: INCOME_SLUGS.INTEREST_INFO,
    [INCOME_PATH_COMPONENTS.INVEST_EXIT_BOUNCE]: INCOME_SLUGS.INVEST_EXIT_BOUNCE,
    [INCOME_PATH_COMPONENTS.INVEST_INFO]: INCOME_SLUGS.INVEST_INFO,
    [INCOME_PATH_COMPONENTS.INVEST_MORE_INFO]: INCOME_SLUGS.INVEST_MORE_INFO,
    [INCOME_PATH_COMPONENTS.PERSONAL_ITEMS]: INCOME_SLUGS.PERSONAL_ITEMS,
    [INCOME_PATH_COMPONENTS.PERSONAL_ITEMS_EXIT_BOUNCE]: INCOME_SLUGS.PERSONAL_ITEMS_EXIT_BOUNCE,
    [INCOME_PATH_COMPONENTS.RETIREMENT_EXIT_BOUNCE]: INCOME_SLUGS.RETIREMENT_EXIT_BOUNCE,
    [INCOME_PATH_COMPONENTS.RETIREMENT_PENSION_INFO]: INCOME_SLUGS.RETIREMENT_PENSION_INFO,
    [INCOME_PATH_COMPONENTS.RETIREMENT_SSA_INFO]: INCOME_SLUGS.RETIREMENT_SSA_INFO,
    [INCOME_PATH_COMPONENTS.RETIREMENT_TYPE]: INCOME_SLUGS.RETIREMENT_TYPE,
    [INCOME_PATH_COMPONENTS.UNEMPLOYMENT_EXIT_BOUNCE]: INCOME_SLUGS.UNEMPLOYMENT_EXIT_BOUNCE,
    [INCOME_PATH_COMPONENTS.UNEMPLOYMENT_INFO]: INCOME_SLUGS.UNEMPLOYMENT_INFO,
    [INCOME_PATH_COMPONENTS.W2_EXIT_BOUNCE]: INCOME_SLUGS.W2_EXIT_BOUNCE,
    [INCOME_PATH_COMPONENTS.W2_INFO]: INCOME_SLUGS.W2_INFO,
    [INCOME_PATH_COMPONENTS.W2G_EXIT_BOUNCE]: INCOME_SLUGS.W2G_EXIT_BOUNCE,
    [INCOME_PATH_COMPONENTS.W2G_INFO]: INCOME_SLUGS.W2G_INFO
  };
};
